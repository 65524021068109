main {
  position: relative;

}

.share {
  color: $white;
  display: flex;

  @include media-breakpoints {
    gap: vw(20px);
  }

  .icons {
    display: flex;

    @include media-breakpoints {
      gap: vw(10px);
    }

    * {
      transition: all .3s;
    }

    .icon {
      &:hover {
        path {
          fill: $primary-color;
        }
      }
    }
  }
}

.single-product {
  &--title {
    font-weight: 700;

    @include media-breakpoints {
      font-size: vw(24px);
      line-height: vw(32px);
      margin-bottom: vw(25px);
    }
  }

  .share {
    color: $white;
  }

  .sku {
    align-items: center;
    display: flex;

    @include media-breakpoints {
      font-size: vw(16px, 12px);
      gap: vw(10px, 5px);
    }

    svg {
      height: auto;

      @include media-breakpoints {
        width: vw(15px, 10px);
      }
    }
  }

  &--info-box {
    position: sticky;

    @media only screen and (width > 768px) {
      background: $white;
      box-shadow: 0 2px 3px 0 rgba(103, 122, 135, .16);
      border: 1px solid $gray;
    }

    @include media-breakpoints {
      border-radius: vw(15px);
      padding: vw(35px, 15px) vw(50px, 0);
      top: vw(115px);
    }

    .stock {
      align-items: flex-start;
      display: flex;

      @include media-breakpoints {
        gap: vw(10px, 5px);
      }

      span {
        @include media-breakpoints {
          font-size: vw(16px, 12px);
        }
      }

      svg {
        height: auto;

        @include media-breakpoints {
          margin-top: vw(5px, 7px);
          width: vw(15px, 10px);
        }
      }


      &.in-stock {
        color: $instock;

        svg {
          circle {
            fill: $instock;
          }
        }
      }

      &.on-backorder {
        svg {
          circle {
            fill: $onbackordersingle;
          }
        }
      }

      &.out-of-stock {
        color: $outofstock;

        svg {
          circle {
            fill: $outofstock;
          }
        }
      }
    }
  }

  .price-box {
    .price {
      color: $secondary-color;
      font-weight: 700;

      @include media-breakpoints {
        font-size: vw(24px);
        line-height: vw(24px);
      }

      &--sale {
        color: #7e7d7d;
        font-weight: 500;
        position: relative;

        &::before {
          border-top: 2px solid #7e7d7d;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 50%;
          transform: rotate(-10deg);

        }
      }
    }
  }

  .product--images-slider--box {
    max-width: 100%;
    position: relative;
    overflow: hidden;

    @include media-breakpoints {
      border-radius: vw($br);
    }

  }

  .product--images-slider {
    @include media-breakpoints {
      margin-bottom: vw(15px);
    }

    img {
      border-radius: $br;
      height: auto;
      width: 100%;
    }
  }

  .product--thumbnail-slider {
    .slick-list {

      @include media-breakpoints {
        margin: 0 vw(-15px, -7.5px);
      }
    }

    .slick-track {
      margin-left: 0;
    }


    .slick-slide {
      @include media-breakpoints {
        margin: 0 vw(15px, 7.5px);
      }

      img {
        filter: grayscale(1);
      }

      &.slick-current {
        img {
          filter: unset;
        }
      }
    }


    img {
      width: 100%;
      object-fit: cover;

      @include media-breakpoints {
        border-radius: vw($br);
        height: vw(145px, 100px);
      }
    }
  }


  &--details {
    @include media-breakpoints {
      margin-bottom: vw(50px, 25px);
      margin-top: vw(50px, 25px);
    }

    hr {
      border-color: $darkgray;
      border-style: solid;
      border-width: 1px 0 0;

      @include media-breakpoints {
        margin: vw(15px) 0;
      }
    }

    a {
      color: $secondary-color;
      font-weight: 700;
    }


    .slick-arrow {
      background: $gray;
    }
  }

  #variantInfo {
    @include media-breakpoints {
      margin: vw(25px) 0;
    }
  }

  form {
    button {
      &:disabled {
        opacity: .5;
      }
    }
  }


  .quantity-box {
    align-items: center;
    background: $gray;
    display: inline-flex;
    overflow: hidden;

    @include media-breakpoints {
      border-radius: vw($br);
    }

    label {
      margin: 0;
    }

    button, input {
      outline: none;

      @include media-breakpoints {
        font-size: vw(19px);
        height: vw(40px);
        width: vw(45px);
      }
    }

    input {
      background: $white;
      border: 0;
      text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    button {
      background: $gray;
      border: 0;

      @include media-breakpoints {
        height: vw(45px);
      }
    }
  }

  .bottom {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoints {
      gap: vw(15px);
    }
  }

  button[type="submit"] {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoints {
      gap: vw(10px);
      height: vw(45px);
    }

    svg {
      height: auto;

      @include media-breakpoints {
        width: vw(28px);
      }
    }
  }

  &--tabs--buttons {
    background: $darkgray;
    display: inline-flex;
    overflow: hidden;

    @include media-breakpoints {
      border-top-left-radius: vw($br);
      border-top-right-radius: vw($br);
      margin-bottom: vw(-25px);
      padding-bottom: vw(25px);
    }

    button {
      border: 0;
      outline: none;
      transition: all .3s;
      color: $black;

      @include media-breakpoints {
        padding: vw(15px) vw(25px);
      }

      &.tab-button--active {
        font-weight: 700;
      }
    }
  }

  &--tabs {
    background: $white;
    border: 1px solid $beige;
    box-shadow: 0 2px 3px 0 $boxshadowcolor;
    position: relative;
    z-index: 2;

    @include media-breakpoints {
      border-radius: vw($br);
      padding: vw(25px);
    }

    .tab {

    }

    table {
      .item {
        &__label {
          text-align: left;

          @include media-breakpoints {
            min-width: vw(200px);
          }
        }
      }
    }
  }

  &--cta {
    @include media-breakpoints {
      padding-top: vw(40px);
    }

    p {
      display: flex;

      @include media-breakpoints {
        gap: vw(10px);
      }

      svg {
        height: auto;

        @include media-breakpoints {
          width: vw(24px);
        }
      }
    }
  }

  &--related {
    @include media-breakpoints {
      padding-top: vw(25px);
      padding-bottom: vw(50px, 25px);
    }
  }
}

.variations {
  @include media-breakpoints {
    margin: vw(25px) 0 vw(35px) 0;
  }
}


#out-of-stock--box {
  background: $white;
  border: 1px solid $darkgray;
  background: $gray;

  @include media-breakpoints {
    border-radius: vw($br);
    padding: vw(25px);
  }

  h3 {
    @include media-breakpoints {
      font-size: vw(18px);
    }
  }

  label {
    display: block;
  }

  input {
    background: $white;
    border: 1px solid $darkgray;
    width: 100%;
    outline: none;

    @include media-breakpoints {
      padding: vw(15px);
      border-radius: vw($br);
    }
  }

  .error-message {
    color: $outofstock;
    @include media-breakpoints {
     font-size: vw(14px);
    }
  }

  &.success {
    border-color: $instock;

    h3 {
      color: $instock;
    }
  }
}
