#checkout {
  min-height: 45.5vh;

  @include media-breakpoints {
    padding: vw(50px) 0 vw(75px) 0;
  }

  form:not(#paymentForm) {
    input[type="text"], select {
      width: 100%;
      border: 1px solid $darkgray;
      color: rgba($black, .8);

      @include media-breakpoints {
        border-radius: vw(5px);
        padding: vw(15px) vw(10px);
      }
    }

    select {
      background: $white;
      appearance: none;
    }
  }

  &--header {

    @include media-breakpoints {
      padding: vw(25px) 0;
    }

    .core-logo {
      height: auto;

      @include media-breakpoints {
        width: vw(200px);
      }
    }
  }
}

.stepper {
  @include media-breakpoints {
    padding-top: vw(50px);
  }

  nav {
    display: flex;
    justify-content: space-between;

    a, span {
      position: relative;

      .check {
        position: absolute;
        background: $primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoints {
          width: vw(25px, 20px);
          height: vw(25px, 20px);
          top: calc(100% + #{vw(8px, 13px)});
        }

        svg {
          height: auto;

          @include media-breakpoints {
            width: vw(15px);
          }
        }
      }
    }

    span {
      &:first-child {
        a, span {
          .check {
            left: 0;
            transform: unset;
          }
        }
      }

      &:last-child {
        a, span {
          .check {
            left: unset;
            @include media-breakpoints {
              right: vw(-8px);
            }
          }
        }
      }
    }

    a, span {
      font-weight: 600;

      &.active {
        color: $secondary-color;
      }
    }
  }

  .progress-bar {
    position: relative;

    @include media-breakpoints {
      margin-top: vw(15px);
    }

    .bar {
      width: 100%;

      @include media-breakpoints {
        border-radius: vw($br);
        height: vw(10px);
      }

      &--static {
        background: $darkgray;
      }

      &--progress {
        background: $primary-color;
        position: absolute;
        top: 0;
        transition: all .8s;
        left: 0;


        .car {
          height: auto;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 3;

          @include media-breakpoints {
            margin-top: vw(-1px);
            right: vw(-65px, -45px);
            width: vw(80px, 60px);

          }

          path {
            fill: $secondary-color;
          }
        }
      }
    }
  }
}

.option--box {
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  width: 100%;

  label {
    border: 2px solid $gray;
    display: flex;
    height: 100%;
    margin: 0;
    transition: all .3s;
    box-shadow: 0 1px 2px 0 $boxshadowcolor;

    @include media-breakpoints {
      border-radius: vw($br);
      gap: vw(15px);
      padding: vw(15px);
    }

    &:hover {
      background: rgba($gray, .1);
      border-color: $primary-color;
    }
  }

  .info {
    @include media-breakpoints {
      font-size: vw(16px);
      width: calc(100% - #{vw(55px)});
    }
  }

  .checkbox {
    align-items: center;
    background: $gray;
    border: 1px solid $darkgray;
    display: flex;
    justify-content: center;
    border-radius: 50%;

    @include media-breakpoints {
      height: vw(25px);
      width: vw(25px);
    }

    svg {
      transition: all .3s;
      height: auto;
      width: 0;

      path {
        fill: $white;
      }
    }
  }

  input {
    display: none;

    &:checked ~ label {
      background: rgba($primary-color, .1);
      border-color: $primary-color;

      .title {
        font-weight: 700;
      }

      .checkbox {
        background: $primary-color;
        border-color: $primary-color;

        svg {
          @include media-breakpoints {
            width: vw(13px);
          }
        }
      }
    }
  }

  select {

    @media only screen and (width < 600px) {
      max-width: 250px;
    }
  }

  .edit-address {
    color: $secondary-color;
    position: absolute;

    @include media-breakpoints {
      bottom: vw(10px);
      right: vw(20px);
    }
  }

  &.shipping {
    label {
      @include media-breakpoints {
        padding: vw(15px);
      }
    }
  }

  &.payment {
    select {
      background-color: $white;
      border: 1px solid $gray;
      background-image: url('/assets/images/icons/chevron-down.svg');
      background-repeat: no-repeat;


      @include media-breakpoints {
        background-size: vw(15px);
        background-position: center right vw(15px);
        border-radius: vw($br);
        padding: vw(10px) vw(15px);
        width: vw(300px);
        appearance: none;
      }
    }

    .logo {
      svg {
        height: auto;
        @include media-breakpoints {
          width: vw(30px);
        }
      }
    }
  }
}

.addresses {
  .addresses--option {
    .option--box {
      p {
        margin: 0;
      }
    }

    a.option--box {
      align-items: center;
      display: flex;
      justify-content: center;
      font-weight: 600;
      border: 2px solid $gray;
      transition: all .3s;

      @include media-breakpoints {
        border-radius: vw($br);
        padding: vw(15px);
        font-size: vw(18px);
        gap: vw(10px);
      }

      span {
        text-align: center;
      }

      svg {
        height: auto;

        @include media-breakpoints {
          width: vw(20px);
        }

        path {
          fill: $primary-color;
        }
      }

      &:hover {
        border-color: $primary-color;

      }
    }
  }
}

.checkboxes {
  @include media-breakpoints {
    margin-top: vw(25px);
  }

  .checkbox-box {
    label {
      display: flex;
      align-items: center;

      @include media-breakpoints {
        gap: vw(10px);
      }

      .checkbox {
        align-items: center;
        background: $gray;
        border: 1px solid $darkgray;
        display: inline-flex;
        justify-content: center;


        @include media-breakpoints {
          border-radius: vw(5px);
          height: vw(20px);
          width: vw(20px);
        }

        svg {
          transition: all .3s;
          height: auto;
          width: 0;

          path {
            fill: $white;
          }
        }
      }
    }

    input {
      display: none;

      &:checked ~ label {
        .checkbox {
          background: $primary-color;
          border-color: $primary-color;

          svg {
            @include media-breakpoints {
              width: vw(10px);
            }
          }
        }
      }
    }
  }

}

.item-name {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;

  .gift {
    background: $secondary-color;
    color: $white;
    font-weight: 700;
    display: inline-flex;
    align-items: center;


    @include media-breakpoints {
      border-radius: vw(50px);
      gap: vw(5px);
      padding: vw(3px) vw(10px);
      font-size: vw(14px);
    }

    svg {
      height: auto;
      @include media-breakpoints {
        width: vw(18px);
      }

      path {
        fill: $white;
      }
    }
  }
}

.sale-price {
  display: block;
  text-decoration: line-through;

  @include media-breakpoints {
    font-size: vw(14px);
    line-height: vw(18px);
  }
}


form.coupon {
  &.remove {
    display: block;


    button {
      background: none;
      border: 0;
      color: $red;
      display: block;
      padding: 0;

      @include media-breakpoints {
        font-size: vw(12px);
      }

      &:hover {
        text-decoration: underline;
      }

    }
  }
}


.discount-button {
  padding: 0;
  border: 0;
  background: none;
  color: $primary-color;
  font-weight: 700;
  outline: none;

  @include media-breakpoints {
    font-size: vw(14px);
  }

  &:hover {
    text-decoration: underline;
  }

  svg {
    height: auto;
    @include media-breakpoints {
      width: vw(25px);
    }

    path {
      fill: $primary-color;
    }
  }
}


#paymentForm {
  button[type="submit"] {
    @include media-breakpoints {
      padding: vw(15px) vw(45px);
    }
  }
}
