.btn {
  border: 0;
  display: inline-flex;
  font-family: $primary-font;
  font-weight: 600;
  transition: all .3s linear;

  @include media-breakpoints {
    border-radius: vw($br);
    font-size: vw(16px, 14px);
    line-height: vw(22px, 20px);
    padding: vw(10px) vw(25px);
  }


  &--trans-white {
    background: transparent;
    border: 1px solid $white;
    color: $white;

    svg {
      path {
        fill: $white;
      }
    }

    &:hover {
      background: $white;
      color: $secondary-color;

      svg {
        path {
          fill: $secondary-color;
        }
      }
    }
  }

  &--orange, &.has-orange-background-color, &--primary {
    background: $primary-color;
    color: $white;

    svg {
      path {
        fill: $white;
      }
    }

    &:hover {
      background: $secondary-color;
    }
  }

  &--trans-primary {
    background: transparent;
    border: 1px solid $primary-color;
    color: $primary-color;

    svg {
      path {
        fill: $primary-color;
      }
    }

    &:hover {
      background: $primary-color;
      color: $white;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  &--gray {
    background: $gray;
    color: $black;
  }

  &--darkblue, &--secondary {
    background: $secondary-color;
    color: $white;

    &:hover {
      background: $primary-color;
    }
  }

  &--shop {
    align-items: center;
    display: flex;
    justify-content: center;

    @include media-breakpoints {
      height: vw(50px, 40px);
      width: vw(50px, 40px);
      padding: 0;
    }

    svg {
      height: auto;

      @include media-breakpoints {
        width: vw(28px, 22px);
      }
    }




    &:hover {
      background: $secondary-color;

      svg {

      }
    }
  }

  &--icon {
    align-items: center;
    justify-content: center;

    @include media-breakpoints {
      gap: vw(10px);
    }

    svg {
      @include media-breakpoints {
        width: vw(32px, 26px);
      }
    }

    &.download {
      width: 100%;
      justify-content: space-between;

      svg {
        @include media-breakpoints {
          width: vw(20px);
        }
      }
    }
  }

  &--plane {
    background: none;
    padding: 0;

    &--darkblue {
      color: $secondary-color;

      svg {
        @include media-breakpoints {
          width: vw(25px);
        }

        path {
          fill: $secondary-color;
        }
      }
    }

    &.btn--icon {
      @include media-breakpoints {
        gap: vw(5px);
      }
    }
  }
}


.link {
  &.arrow {
    display: inline-flex;
    align-items: center;

    @include media-breakpoints {
      gap: vw(10px);
    }

    svg {
      @include media-breakpoints {
        width: vw(20px);
      }

    }
  }
}
