.border-block {
  background: $white;
  border: 1px solid $gray;
  box-shadow: 0 2px 3px 0 $boxshadowcolor;

  @include media-breakpoints {
    border-radius: vw($br);
    padding: vw(25px, 15px);
  }

  &.sticky {
    position: sticky;

    @include media-breakpoints {
      top: vw(15px);
    }
  }

  .header {
    border-bottom: 1px solid $gray;
    color: $secondary-color;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoints {
      margin-bottom: vw(15px);
      padding-bottom: vw(10px);
    }

    h3 {
      margin: 0;
    }

    svg {
      height: auto;

      @include media-breakpoints {
       width: vw(25px);
      }

      path {
        fill: $secondary-color;
      }
    }

  }

  &.account {
    box-shadow: unset;
    border: none;
    background: #fcfcfc;

    @include media-breakpoints {
      padding: vw(35px, 0);
    }

    @media only screen and (width < 600px) {
      background: transparent;
      margin-top: 35px;
    }

    .btn {
      @include media-breakpoints {
        padding: vw(10px) vw(45px);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    &.icon {
      text-align: center;
      transition: all .3s;
      border: 1px solid $gray;
      box-shadow: 0 2px 3px 0 $boxshadowcolor;
      cursor: pointer;
      display: block;

      .title {
        margin: 0;
        font-weight: 700;

        @include media-breakpoints {
          font-size: vw(20px);
          line-height: vw(28px);
        }
      }

      svg {
        height: auto;

        @include media-breakpoints {
          margin-bottom: vw(10px);
          width: vw(50px);
        }

        path {
          fill: $primary-color;
        }
      }

      &:hover {
        transform: translateY(-5px) scale(1.02);
      }
    }
  }

  &.address {
    height: 100%;
    position: relative;

    @include media-breakpoints {
      padding-bottom: vw(65px);
    }

    &.add {
      background: $primary-color;
      color: $white;
      display: flex;
      font-weight: 700;
      height: unset;
      justify-content: center;

      @include media-breakpoints {
        font-size: vw(20px, 16px);
        gap: vw(10px);
        padding: vw(25px, 15px);
      }

      svg {
        height: auto;

        @include media-breakpoints {
          width: vw(25px);
        }

        path {
          fill: $white;
        }
      }
    }
  }

  .actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoints {
      gap: vw(10px);
      padding: vw(15px);
    }

    &.order {
      position: relative;
      padding: 0;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .3s;

      @include media-breakpoints {
        width: vw(43px);
        height: vw(43px);
        border-radius: vw($br);
      }

      * {
        transition: all .3s;
      }

      svg {
        height: auto;
        @include media-breakpoints {
          width: vw(20px);
        }
      }

      &.edit, &.view {
        background: $secondary-color;

        svg {
          path {
            fill: $white;
          }
        }

        &:hover {
          background: $primary-color;
        }
      }

      &.delete {
        background: $gray;
        border: none;
        outline: none;
        color: $black;

        &:hover {
          background: $red;

          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }

  }
}


.overview-block {
  border-bottom: 1px dashed #b9b9b9;

  @include media-breakpoints {
    padding-bottom: vw(15px);
    padding-top: vw(15px);
  }

  &.normal-border {
    border-bottom: 1px solid $gray;
  }

  .coupon {
    display: flex;

    input {
      border: 1px solid $gray;
      outline: none;
      width: 100%;

      @include media-breakpoints {
        border-radius: vw(5px) 0 0 vw(5px);
        padding: vw(8px) vw(10px);
      }
    }

    button {
      @include media-breakpoints {
        border-radius: 0 vw(5px) vw(5px) 0;
      }
    }
  }

  .small {
    color: $secondary-color;
    font-weight: 500;

    @include media-breakpoints {
      font-size: vw(14px);
      line-height: vw(22px);
    }
  }
}


@media only screen and (width < 768px) {
  .border-block {
    background: none;
    border: 0;
    box-shadow: none;
    padding: 0;

    &.account.icon, &.address {
      background: $white;
      padding: 25px;
      border: 1px solid $gray;

      &.address-block {
        margin-top: 15px;
      }
    }

    &.order {
      background: $white;
      padding: 15px;
      border: 1px solid $gray;
    }
  }
}
