@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}

$primary-font: "Inter", sans-serif;
$secondary-font: "Inter", sans-serif;
$tertiary-font: 'Inter', sans-serif;
$quaternary-font: 'Inter', sans-serif;

$fontawesome: "Font Awesome 6 Pro", sans-serif;
