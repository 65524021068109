table {
  #tuning, #tuningprijs {
    border-collapse: collapse;

    @include media-breakpoints {
      margin-bottom: vw(15px);
    }

    td, th, {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tr {
      transition: all .2s ease-in-out;

      &:nth-child(2n) {
        background: $gray;
      }

      &:hover {
        background-color: $primary-color;
        color: $white;
      }
    }
  }

}

.table-product {
  width: 100%;

  tr {
    &:nth-child(2n) {
      background: $gray;
    }
  }

  td, th {
    @include media-breakpoints {
      padding: vw(8px);
    }
  }

  &--vehicles {
    td, th {
      border: 1px solid #ddd;
    }

    tbody {
      tr {
        &:hover {
          background: $primary-color;
          color: $white;
        }
      }
    }
  }
}
