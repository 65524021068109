.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @include media-breakpoints {
    gap: vw(5px);
    margin-top: vw(0, 15px);
  }

  li {
    button {
      align-items: center;
      border-radius: 50%;
      color: $black;
      display: flex;
      justify-content: center;
      outline: none;
      padding: 0;
      transition: all .3s;

      @include media-breakpoints {
        height: vw(45px);
        width: vw(45px);
      }
    }

    &.active {
      button {
        background: $secondary-color;
        color: $white;
      }
    }

    &.disabled {
      span {
        line-height: unset;
        margin-bottom: 4px;
      }
    }

    //&.next, &.prev {
    //  button {
    //    background: none;
    //    width: auto;
    //    height: auto;
    //  }
    //}

  }

}
