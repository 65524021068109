.home {
  &.new {
    @include media-breakpoints {
      padding-top: vw(100px, 50px);
    }

    h2 {
      @include media-breakpoints {
        margin-bottom: vw(25px);
      }

      &.text-white {
        @media only screen and (width < 768px) {
          color: $secondary-color;
        }
      }
    }
  }

  &.about, &.most-sold {
    @include media-breakpoints {
      padding-top: vw(100px, 50px);
    }
  }

  .slick-slider.bottom {
    @include media-breakpoints {
      margin-bottom: vw(35px, 25px);
    }

    .slick-list {
      margin: 0 -15px;
    }

    .slick-track {
      @include media-breakpoints {
        padding-top: vw(25px, 0);
      }
    }
  }

  &.news {
    @include media-breakpoints {
      padding-bottom: vw(50px, 0);
      padding-top: vw(100px, 50px);
    }

    h2 {
      @include media-breakpoints {
        margin-bottom: vw(50px, 25px);
      }
    }
  }


}
