.core-footer {
  position: relative;
  z-index: 12;
  background: $gray;

  @include media-breakpoints {
    padding-top: vw(50px);
  }

  h4, p, a {
    color: #656057;
  }

  h4 {
    @include media-breakpoints {
      font-size: vw(20px, 18px);
      line-height: vw(24px, 22px);
      margin-bottom: vw(20px, 15px);
    }
  }

  p, a {
    @include media-breakpoints {
      font-size: vw(15px, 14px);
    }
  }

  .underline {
    text-decoration: underline;
  }

  .map {
    height: auto;
    @include media-breakpoints {
      width: vw(22px);
    }

    path {
      fill: #656057;
    }
  }

  ul {
    margin: 0;
    @include media-breakpoints {
      padding: 0 0 0 vw(20px);
    }

    li {
      @include media-breakpoints {
        margin-bottom: vw(5px);
      }
    }

  }

  .bovag {
    height: auto;

    @include media-breakpoints {
      width: vw(100px);
    }
  }

  .price-information {
    @include media-breakpoints {
      margin-top: vw(20px);
    }

    p {
      @include media-breakpoints {
        font-size: vw(10px);
      }
    }
  }

  .bottom-bar {
    background: $white;

    @include media-breakpoints {
      margin-top: vw(40px);
      padding-bottom: vw(15px);
      padding-top: vw(15px);
    }

    p, a, span {
      color: #ACA8A1;
      @include media-breakpoints {
        font-size: vw(12px);
      }
    }

    p {
      margin-bottom: 0;
    }

    .documents {
      @media only screen and (width < 768px) {
        text-align: center;

        @include media-breakpoints {
         margin-top: vw(10px);
        }

        a, span {
          @include media-breakpoints {
            font-size: vw(8px);
          }
        }

        .sep {
          @include media-breakpoints {
            margin: 0 vw(5px);
          }
        }
      }
    }
  }

  .sep {
    @include media-breakpoints {
      margin: 0 vw(10px);
    }
  }
}
