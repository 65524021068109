.core-header {
  background: $white;
  box-shadow: 0 3px 3px 0 rgba($black, 0);
  position: sticky;
  top: 0;
  transition: all .3s;
  z-index: 20;

  @media only screen and (width < 768px) {
    .container {
      max-width: 100vw;
    }
  }

  &.sticky:not(.no-box-shadow) {
    box-shadow: 0 3px 3px 0 rgba($black, .06);
  }

  .header {
    &--box {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
      transition: all .3s;

      &.search-active {
        .header--box--right {
          @include media-breakpoints {
            max-width: vw(600px);
          }
        }
      }

      &--left {
        align-items: center;
        display: flex;
        position: relative;
        transition: all .3s;

        @include media-breakpoints {
          gap: vw(40px);
          max-width: vw(825px);
        }

        &--mobile {
          @media only screen and (width >= 768px) {
            display: none;
          }
        }
      }

      &--right {
        align-items: center;
        display: flex;
        transition: all .3s;

        @media only screen and (width > 768px) {
          width: 100%;
        }

        @include media-breakpoints {
          gap: vw(20px, 10px);
          max-width: vw(450px, unset);
        }

        .icons {
          align-items: center;
          display: flex;
          transition: all .3s;

          @include media-breakpoints {
            gap: vw(20px, 10px);
          }


        }
      }
    }

    nav {
      ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        @include media-breakpoints {
          gap: vw(30px);
        }

        > li {
          position: relative;

          > a {
            display: flex;
            font-weight: 500;

            @include media-breakpoints {
              font-size: vw(16px);
              gap: vw(5px);
              padding: vw(36px, 15px) vw(0, 15px);
            }

            svg {
              height: auto;

              @include media-breakpoints {
                width: vw(15px, 20px);
              }
            }
          }
        }
      }

      @media only screen and (width < 768px) {
        > ul {
          display: block;

          > li {
            a {
              border-bottom: 1px solid $gray;
              display: flex;
              justify-content: space-between;

              svg {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
    }
  }

  .icon {
    position: relative;

    @include media-breakpoints {
      padding: vw(34.2px, 18px) 0;
    }

    &--box {
      position: relative;
    }

    svg {
      height: auto;

      @include media-breakpoints {
        width: vw(28px, 24px);
      }

      path {
        transition: all .3s;
        fill: $black;
      }
    }

    .check {
      position: absolute;
      height: auto;
      background: $white;
      border-radius: 50%;

      @include media-breakpoints {
        width: vw(20px);
        top: vw(-5px);
        right: (-4px);
      }

      path {
        fill: #69a00b;
      }
    }

    &.mini-cart {
      position: relative;
      margin-top: -2px;

      .cart-count {
        background: $primary-color;
        color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;

        @include media-breakpoints {
          width: vw(20px, 15px);
          height: vw(20px, 15px);
          top: vw(-8px, -6px);
          right: vw(-4px, -2px);
          font-size: vw(12px, 8px);
        }
      }
    }

    &:hover {
      .icon--box {
        svg {
          path {
            fill: $primary-color;
          }
        }
      }
    }

    &.search {
      path {
        fill: $black;
      }

      @media only screen and (width > 768px) {
        display: none;
      }

      @media only screen and (width < 768px) {
        .close {
          display: none;
        }

        &.active {
          .close {
            display: block;
          }

          .search {
            display: none;
          }
        }
      }
    }

    &.account {
      @media only screen and (width < 768px) {
        display: none;
      }
    }
  }

  .account {
    &--dropdown {
      position: absolute;
      top: 100%;
      right: -25px;
      background: $white;
      width: max-content;
      visibility: hidden;
      opacity: 0;
      transition: opacity .3s ease, transform .3s ease, visibility 0s linear .3s;
      transform: translateY(-10px);
      z-index: 2;
      overflow: hidden;

      @include media-breakpoints {
        border-bottom-right-radius: vw(5px);
        border-bottom-left-radius: vw(5px);
      }


      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          &:not(:last-child) {
            a {
              border-bottom: 1px solid $gray;
            }
          }
        }
      }

      a {
        display: flex;
        transition: all .3s;

        @include media-breakpoints {
          padding: vw(10px) vw(15px);
          gap: vw(8px);
        }

        &:hover {
          background: $gray;

          svg {
            path {
              fill: $black;
            }
          }
        }

        svg {
          height: auto;

          @include media-breakpoints {
            width: vw(20px);
          }

          path {
            fill: $secondary-color;
          }
        }
      }
    }

    &:hover {
      .account--dropdown {
        opacity: 1;
        transform: translateY(0);
        transition: opacity .3s ease, transform .3s ease, visibility 0s;
        visibility: visible;
      }
    }
  }

  button {
    background: none;
    border: 0;
    outline: none;
    padding: 0;
  }

  .core-logo {
    height: auto;

    @include media-breakpoints {
      width: vw(175px, 150px);
      padding: vw(0, 10px) 0;
    }

    path {
      fill: $green;
    }
  }

  svg.core-logo {
    @include media-breakpoints {
      width: vw(175px, 115px);
      padding: vw(0, 5px) 0;
    }
  }


  .search-form {
    position: relative;
    transition: all .3s;
    width: 100%;
    z-index: 22;

    @include media-breakpoints {
      max-width: vw(650px);
    }

    button {
      padding: 0;
      background: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include media-breakpoints {
        right: vw(20px);
      }

      svg {
        height: auto;
        @include media-breakpoints {
          width: vw(20px);
        }
      }

      &#reset-search {

        @include media-breakpoints {
          right: vw(45px);
          width: vw(30px);
        }

        path {
          fill: $red;
        }
      }

    }

    label {
      width: 100%;
      transition: all .3s;
    }

    input {
      background: $gray;
      border: 0;
      color: $black;
      outline: none;
      transition: all .3s;
      width: 100%;

      @include media-breakpoints {
        border-radius: vw(50px);
        font-size: vw(14px);
        line-height: vw(20px);
        padding: vw(18px) vw(20px, 15px);
      }

      &.active {
        //width: 100%;
        background: $white;
      }
    }

    label {
      position: relative;
      margin: 0;
    }


    &--box {
      @media only screen and (width < 768px) {
        background: $white;
        border-top: 1px solid $gray;
        height: 100%;
        left: 0;
        opacity: 0;
        overflow: visible;
        position: fixed;
        transform: translateX(100%);
        transition: opacity .3s ease .3s, transform .3s ease, visibility 0s linear .3s;
        visibility: hidden;
        width: 100vw;

        @include media-breakpoints {
          top: vw(62px);
          max-height: calc(100dvh - #{vw(62px)});
        }


        &.show {
          opacity: 1;
          transform: translateY(0);
          transition: opacity 0s ease, transform .3s ease, visibility 0s;
          visibility: visible;
        }

        input {
          border-radius: 0;

          &.active {
            background: $gray;
          }
        }
      }

      @media only screen and (width > 768px) {
        width: 100%;
      }
    }
  }

  .search-results {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 13;
    background: $white;
    overflow: auto;

    @include media-breakpoints {
      border-radius: vw($br, 0);
      max-height: vw(600px);
      top: calc(100% + #{vw(5px, 0)});
    }

    @media only screen and (width < 768px) {
      border-top: 1px solid $darkgray;
      max-height: calc(100dvh - #{vw(120px)});
    }

    .header {
      align-items: center;
      background: $gray;
      display: flex;
      justify-content: space-between;

      @include media-breakpoints {
        padding: vw(10px) vw(12px);
      }

      a {
        color: $secondary-color;
        font-weight: 700;

        @include media-breakpoints {
          font-size: vw(12px);
          line-height: vw(20px);
        }
      }
    }

    h3 {
      margin: 0;

      @include media-breakpoints {
        font-size: vw(16px);
        line-height: vw(24px);
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        &:not(:last-child) {
          border-bottom: 1px solid $gray;
        }

        a {
          align-items: center;
          display: flex;
          font-weight: 500;
          justify-content: space-between;
          transition: all .1s;

          @include media-breakpoints {
            font-size: vw(14px);
            gap: vw(10px);
            line-height: vw(20px);
            padding: vw(8px) vw(12px);
          }

          svg {
            height: auto;

            @include media-breakpoints {
              min-width: vw(15px);
              width: vw(15px);
            }
          }

          &:hover {
            background: $darkgray;
          }

          &.all {
            background: $secondary-color;
            color: $white;
            font-weight: 700;

            @include media-breakpoints {
              padding: vw(10px) vw(12px);
            }

            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }

  }
}


.mega-menu {
  background: $white;
  border-top: 1px solid $gray;
  left: 0;
  opacity: 0;
  position: fixed;
  transform: translateY(-10px);
  transition: opacity .3s ease, transform .3s ease, visibility 0s linear .3s;
  visibility: hidden;
  width: 100%;
  z-index: 3;

  @include media-breakpoints {
    padding-bottom: vw(35px);
    padding-top: vw(35px);
    top: vw(188px);
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .3s ease, transform .3s ease, visibility 0s;
    visibility: visible;
  }

  &.sticky {
    @include media-breakpoints {
      top: vw(96px);
    }
  }


  &--backdrop {
    background: rgba(0, 0, 0, .2);
    height: 100dvh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all .3s;
    visibility: hidden;
    width: 100%;
    z-index: 3;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  .main-items {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:last-child) {
        @include media-breakpoints {
          margin-bottom: vw(10px, 0);
        }
      }

      @include media-breakpoints {
        border-radius: vw(5px);
        padding: vw(15px);
      }

      svg {
        height: auto;

        @include media-breakpoints {
          width: vw(25px);
        }

        path {
          fill: $secondary-color;
        }
      }


      @media only screen and (width > 768px) {
        &.active, &:hover {
          background: $gray;
        }
      }
    }

    .title {
      font-weight: 700;
      display: block;
    }

    .text {
      @include media-breakpoints {
        font-size: vw(14px);
      }
    }
  }

  .menu {
    @media only screen and (width > 768px) {
      display: none;

      @include media-breakpoints {
        padding-left: vw(35px);
      }

      &.show {
        display: block;
      }
    }

    .title {
      color: $secondary-color;
      font-weight: 700;


      @include media-breakpoints {
        font-size: vw(20px);
        line-height: vw(28px);
      }
    }

    &.brands-menu {
      ul {
        columns: 4;
        list-style: none;
        margin: 0;
        padding: 0;

        @media only screen and (width < 768px) {
          columns: 1;
        }

        li {
          a {
            font-weight: 500;
            transition: all .3s;

            &:hover {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }

  &--menus {
    border-left: 1px solid $gray;
  }

  @media only screen and (width < 768px) {
    .container {
      max-width: 100%;

      .col-12 {
        padding: 0;
      }
    }

    li {
      border-bottom: 1px solid $gray;

    }
  }
}


.backdrop {
  background-color: rgba(0, 0, 0, .5);
  inset: 0;
  position: fixed;
  z-index: 20;
  visibility: hidden;

  @media only screen and (width < 768px) {
    display: none;

    &.coupon {
      display: block;
    }
  }

  &.show {
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
    visibility: visible;
  }

  &#search-backdrop {
    z-index: 21;
  }
}


.menu-icon {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include media-breakpoints {
    gap: vw(5px);
  }

  span {
    font-weight: 600;

    @include media-breakpoints {
      font-size: vw(14px);
      line-height: vw(20px);
    }
  }
}


#hamburger {
  .hamburger {
    z-index: 18;
    position: relative;
    transition: all .3s;

    @include media-breakpoints {
      height: vw(12px);
      width: vw(20px);
    }

    &--top, &--middle, &--bottom {
      background: $black;
      display: block;
      position: absolute;

      @include media-breakpoints {
        height: vw(2px);
        width: vw(20px);
      }
    }

    &--top, &--bottom {
      transition: transform .4s ease, top .3s ease .4s;
    }

    &--middle {
      top: 50%;
      transition: opacity .2s ease .5s;
    }

    &--bottom {
      top: 100%;
    }
  }

  // Active hamburger
  &.active {


    .hamburger--top, .hamburger--bottom {
      transition: top .3s ease, transform .4s ease .3s;
    }

    .hamburger--top {
      top: 50%;
      transform: rotate(45deg);
    }

    .hamburger--middle {
      opacity: 0;
      transition: none;
    }

    .hamburger--bottom {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
}


@media only screen and (width < 768px) {
  .responsive-menu {
    background: $white;
    border-top: 1px solid $gray;
    height: 100%;
    left: 0;
    max-height: 100dvh;
    opacity: 0;
    overflow: auto;
    position: fixed;
    transform: translateX(100%);
    transition: opacity .3s ease .3s, transform .3s ease, visibility 0s linear .3s;
    visibility: hidden;
    width: 100vw;
    z-index: 22;

    @include media-breakpoints {
      max-height: calc(100dvh - #{vw(62px)});
      top: vw(62px);
    }

    &.mega-menu, &.navigation {
      padding: 0;
    }

    &.menu {
      top: 0;
    }

    &.show {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 0s ease, transform .3s ease, visibility 0s;
      visibility: visible;
    }

    ul, &.menu {
      li.back-to {
        align-items: center;
        background: $gray;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        position: sticky;
        top: 0;

        @include media-breakpoints {
          gap: vw(5px);
          padding: vw(10px) vw(15px);
        }

        svg {
          @include media-breakpoints {
            width: vw(20px);
          }

          path {
            fill: $black;
          }
        }
      }
    }

    .main-items {
      li.back-to {

        @include media-breakpoints {
          padding: vw(15px);
        }
      }
    }

    .mega-menu__content {
      .title {
        border-bottom: 1px solid $gray;
        margin: 0;

        @include media-breakpoints {
          padding: vw(15px);
        }
      }

      li {
        padding: 0;

        a {
          display: block;

          @include media-breakpoints {
            padding: vw(10px) vw(15px);
          }
        }
      }
    }
  }
}
