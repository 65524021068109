@charset "UTF-8";

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;

    &.no-scroll {
        overflow: hidden;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$br: 10px;

// Import bootstrap.
@import './plugins/bootstrap/bootstrap-grid';

@import './global/mixins/mixin';
@import './global/colors';
@import './global/standard';
@import './global/typography';



main {
  @include media-breakpoints {
    min-height: vw(580px);
  }
}

// Elements
@import './global/elements/buttons';

// Global components
@import './global/components/core-header';
@import './global/components/core-footer';
@import './global/components/top-bar';
@import './global/components/alert-bar';
@import './global/elements/blocks';
@import './global/elements/borders';

// Components
@import './components/headers';

@import './components/sliders';
@import './components/form';
@import './components/pagination';
@import './components/table';
@import './components/modal';
@import './components/alert-box';
@import './components/breadcrumb';
@import './components/password-meter';
@import './components/faq';

// Blogs
@import './components/blogs/blog-item';

// Shop
@import './components/shop/products';
@import './components/shop/filters';
@import './components/shop/mini-cart';
@import './components/shop/single-product';

// Pages
@import 'pages/home';
@import 'pages/standard';
@import 'pages/blogs';
@import 'pages/shop';
@import 'pages/cart';
@import 'pages/checkout';
@import 'pages/error';

// Plugins
@import 'plugins/slick';
@import 'plugins/tom-select';


p {
  a {
    color: $primary-color;
    text-decoration: underline;
  }
}
