.top-bar {
  background: $gray;
  position: relative;
  z-index: 4;

  @include media-breakpoints {
    padding: vw(12px) 0;
  }

  @media only screen and (width < 768px) {
    display: none;
  }

  p, a {
    @include media-breakpoints {
      font-size: vw(14px, 12px);
    }
  }

  a:not(.website) {
    align-items: center;
    color: $black;
    display: flex;
    text-decoration: none;

    @include media-breakpoints {
      font-size: vw(14px, 12px);
      gap: vw(5px);
    }
  }

  .website {
    color: inherit;
  }

  .links {
    display: flex;

    @include media-breakpoints {
      gap: vw(20px);
    }
  }
}
