$image-height-desktop: 250px;
$image-height-mobile: 150px;

.product {
  background: $white;
  border: 1px solid $darkgray;
  box-shadow: 0 2px 3px 0 $boxshadowcolor;
  overflow: hidden;
  transition: all .3s;

  @include media-breakpoints {
    border-radius: vw($br);
    margin-bottom: vw(30px, 8px);
    padding: vw(10px, 5px);
  }

  @media only screen and (width > 768px) {
    box-shadow: 0 1px 1.5px 0 $boxshadowcolor;
  }

  .image-box {
    background: $white;
    position: relative;

    @include media-breakpoints {
      height: vw($image-height-desktop, $image-height-mobile);
      margin-bottom: vw(20px, 8px);
    }

    .thumbnail {
      object-fit: cover;
      width: 100%;

      @include media-breakpoints {
        border-radius: vw($br);
        height: vw($image-height-desktop, $image-height-mobile);
      }
    }

    .discount {
      background: $secondary-color;
      color: $white;
      left: 0;
      position: absolute;
      font-weight: 700;

      @include media-breakpoints {
        border-radius: 0 vw(5px) vw(5px) 0;
        padding: vw(5px, 0) vw(10px);
        top: vw(15px, 10px);
        font-size: vw(14px, 10px);
      }
    }
  }

  .body {
    @include media-breakpoints {
      padding: 0 vw(8px, 5px) vw(10px, 5px);
    }
  }

  .title {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-weight: 700;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoints {
      font-size: vw(16px, 12px);
      line-height: vw(24px, 20px);
      margin-bottom: vw(20px, 5px);
      min-height: vw(72px, 60px);
    }

    @media only screen and (width < 768px) {
      -webkit-line-clamp: 3;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;


    @media only screen and (width < 768px) {
      position: relative;
      padding-top: 25px;

      .details {
        display: flex;
        flex-direction: column;

        .price--box {
          order: 2;
        }

        .stock--box {
          position: absolute;
          top: 0;
        }
      }
    }

  }



  .price {
    @include media-breakpoints {
      font-size: vw(18px, 12px);
      line-height: vw(24px, 18px);
      margin-bottom: vw(4px);
    }

    &--title {
      @include media-breakpoints {
        font-size: vw(12px);
        line-height: vw(24px);
        margin-bottom: vw(4px);
      }
    }

    &--box {
      color: $secondary-color;
      font-weight: 700;

      @media only screen and (width < 768px) {
        display: flex;
        flex-direction: column;
      }
    }

    &--sale {
      color: #7e7d7d;
      font-weight: 500;
      position: relative;
      text-decoration: line-through;

      @media only screen and (width < 768px) {
        font-size: 10px;
        line-height: 16px;
        margin: 0;
      }
    }
  }

  .stock--box {
    display: flex;
    align-items: center;

    @include media-breakpoints {
      gap: vw(5px);
    }

    svg {
      height: auto;

      @include media-breakpoints {
       width: vw(10px);
      }
    }

    p {
      margin: 0;
      display: flex;
      align-items: center;

      @include media-breakpoints {
       gap: vw(5px);
      }
    }


    .stock {
      font-weight: 400;

      @include media-breakpoints {
        font-size: vw(12px, 10px);
        line-height: vw(24px, 22px);
      }
    }

    &.in-stock {
      color: $instock;

      svg {
        circle {
          fill: $instock;
        }
      }
    }

    &.on-backorder {
      color: $onbackorder;

      svg {
        circle {
          fill: $onbackorder
        }
      }
    }

    &.out-of-stock {
      color: $outofstock;

      svg {
        circle {
          fill: $outofstock;
        }
      }
    }
  }

  &:hover {
    @media only screen and (width > 768px) {
      transform: scale(1.008) translateY(-5px);
    }
  }
}

.product-assembly {
  label {
    align-items: center;
    background: $gray;
    display: flex;
    margin-top: 0;
    position: relative;
    width: 100%;

    @include media-breakpoints {
      border-radius: vw(10px);
      gap: vw(25px);
      padding: vw(18px) vw(15px);
    }

    .checkbox {
      align-items: center;
      background: $white;
      display: flex;
      justify-content: center;
      transition: all .3s ease-in-out;

      @include media-breakpoints {
        border-radius: vw(10px);
        height: vw(40px);
        width: vw(40px);
      }

      svg {
        height: auto;

        @include media-breakpoints {
          width: vw(20px);
        }
      }
    }
  }

  input {
    display: none;

    &:checked {
      + label {
        .checkbox {
          background: $secondary-color;
        }
      }
    }
  }

  .info {
    position: relative;
  }

  .tooltip {
    position: relative;

    &:hover {
      .tooltip--message {
        bottom: 100%;
        opacity: 1;
      }
    }

    &--message {
      background: $gray;
      bottom: 0;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      color: $black;
      left: 50%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transform: translateX(-50%);
      transition: all .3s cubic-bezier(.68, -.55, .265, 1.55);


      @include media-breakpoints {
        border-radius: vw(10px);
        font-size: vw(14px);
        padding: vw(10px) vw(15px);
        width: vw(350px);
      }

      &::before {
        background: $gray;
        bottom: -3px;
        content: '';
        height: 8px;
        left: 50%;
        position: absolute;
        transform: translate(-50%) rotate(45deg);
        transition: all .3s cubic-bezier(.68, -.55, .265, 1.55);
        width: 8px;
      }
    }
  }
}


@media only screen and (width < 600px) {
  #product-row {
    margin-right: -5px;
    margin-left: -5px;

    .col-6 {
      padding: 0 5px;
    }
  }
}
