.standard-content {


  .block {
    @include media-breakpoints {
      padding-bottom: vw(25px, 15px);
      padding-top: vw(25px, 15px);
    }

    &--inner {
      @include media-breakpoints {
        padding-bottom: vw(15px);
      }
    }
  }
}
