.header {
  &--home {
    position: relative;

    @include media-breakpoints {
      padding-top: vw(90px, 0);
    }


    @media only screen and (width < 768px) {
      .container {
        max-width: 100%;
      }

      form {
        max-width: 95vw;
        margin: 0 auto;
      }
    }

    .shape {
      height: auto;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 100%;

      @include media-breakpoints {
        top: vw(-90px, 0);


        @media only screen and (width > 1900px) {
          top: vw(-300px);
        }

        @media only screen and (width > 2300px) {
          top: vw(-500px);
        }

        @media only screen and (width < 768px) {
          top: vw(150px);
          width: 110%;
        }

        @media only screen and (width < 600px) {
          top: vw(273px);

        }
      }

      &--box {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        overflow: hidden;
      }
    }

    .slider-box {
      @media only screen and (width < 768px) {
        padding: 0;
      }
    }

    form {
      @media only screen and (width < 768px) {
        @include media-breakpoints {
          margin-top: vw(25px);
        }
      }

      p {
        color: $white;
      }

      .title {
        font-weight: 700;

        @include media-breakpoints {
          font-size: vw(24px);
          line-height: vw(24px);
        }
      }
    }

    .btn--orange {
      justify-content: center;
      text-transform: uppercase;
      width: 100%;
      outline: none;

      @include media-breakpoints {
        gap: vw(10px);
        padding: vw(16px);
      }

      &.small {
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoints {
          margin-left: vw(10px);
          width: vw(56px);
          height: vw(65px);
        }
      }
    }

    .kenteken {
      position: relative;
      background: #f3bd00;
      width: 100%;
      border-radius: 5px;
      display: flex;
      align-items: center;

      @include media-breakpoints {
        padding: vw(3px);
      }

      .nl {
        background: #003399;
        border-right: 1px solid #333;

        @include media-breakpoints {
          padding: vw(2px) 0;
        }

        img {
          height: auto;
          object-fit: contain;
          @include media-breakpoints {
            height: vw(58px);
            width: vw(50px);
          }
        }
      }

      input {
        background: 0;
        border: 0;
        font-family: 'Kenteken', sans-serif;
        margin: 0;
        outline: 0;
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoints {
          font-size: vw(38px);
          letter-spacing: vw(3px);
          line-height: vw(38px);
          padding: vw(5px) 0 0;
          width: calc(100% - #{vw(70px)});
        }
      }

      .input {
        text-align: center;
      }
    }

    .select-box {
      position: relative;

      @include media-breakpoints {
        margin-bottom: vw(15px);
      }

      select {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .ts-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;


        .ts-control {
          background-color: transparent;
          border: 0;
          height: 100%;

          @include media-breakpoints {
            border-radius: vw($br);
            padding: 0 vw(10px) 0 vw(50px);
          }

          .item {
            display: flex;
            height: 100%;
            justify-content: space-between;
            width: 100%;

            @include media-breakpoints {
              padding-right: vw(15px) !important;
              font-size: vw(16px);
            }
          }


          input {
            color: $black;
            @include media-breakpoints {
              font-size: vw(16px);
            }
          }
        }

        .ts-dropdown {
          .option {
            @include media-breakpoints {
              font-size: vw(16px);
            }
          }
        }
      }

      label {
        align-items: center;
        background: $white;
        display: flex;
        margin: 0;
        position: relative;

        @include media-breakpoints {
          border-radius: vw($br);
          gap: vw(10px);
          padding: vw(15px) vw(10px);
        }

        .number {
          align-items: center;
          background: $primary-color;
          border-radius: 50%;
          color: $white;
          display: flex;
          justify-content: center;
          position: relative;
          z-index: 2;

          @include media-breakpoints {
            font-size: vw(16px);
            height: vw(30px);
            width: vw(30px);
          }
        }
      }

      &.disabled {
        cursor: not-allowed;

        label {
          .number {
            background: $gray;
            color: $black;
          }
        }

        .ts-control {
          opacity: 1 !important;
        }
      }

      .ts-dropdown {
        margin-top: 0;
      }

      .ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
        background-color: $red;
        background-image: url("../images/icons/close.svg");
        border: 0;
        color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        font-size: 0;
        line-height: 0;
        background-repeat: no-repeat;
        background-position: center;

        @include media-breakpoints {
          width: vw(20px);
          height: vw(20px);
          background-size: vw(10px);

        }

      }

      &:not(.disabled) {

      }

      &.open {
        label {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-color: $white;
        }
      }
    }
  }

  &--content {
    position: relative;

    @include media-breakpoints {
      padding-bottom: vw(50px, 45px);
      padding-top: vw(90px, 25px);
    }

    &--news {
      @include media-breakpoints {
        padding-top: vw(25px);
        padding-bottom: vw(25px, 0);
      }

      img {
        width: 100%;
        object-fit: cover;

        @include media-breakpoints {
          border-radius: vw($br);
          height: vw(500px, 250px);
        }
      }
    }

    h1 {
      color: $white;

      @include media-breakpoints {
        margin-bottom: vw(25px);
      }
    }

    @media only screen and (width < 600px) {
      .top-text {
        min-height: 115px;
      }
    }

    .shape {
      height: auto;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      width: 100%;

      @include media-breakpoints {
        top: vw(-90px, 0);


        @media only screen and (width > 1900px) {
          top: vw(-300px);
        }

        @media only screen and (width > 2300px) {
          top: vw(-400px);
        }

        @media only screen and (width < 600px) {
          width: 145%;
        }
      }
    }
  }

  &--shop {
    position: relative;

    @include media-breakpoints {
      padding-bottom: vw(50px, 15px);
      padding-top: vw(50px, 15px);
    }

    &.account {
      .shape {
        @include media-breakpoints {
          top: vw(-275px);


          @media only screen and (width > 1900px) {
            top: vw(-325px);
          }

          @media only screen and (width > 2300px) {
            top: vw(-450px);
          }


          @media only screen and (width < 600px) {
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            width: 200%;
          }
        }
      }

      h1 {
        @include media-breakpoints {
          font-size: vw(40px);
          line-height: vw(48px);
        }
      }
    }

    .shape {
      height: auto;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: 100%;

      @include media-breakpoints {
        top: vw(-50px);
      }

      @media only screen and (width < 768px) {
        width: 120%;
        top: 0;
      }

      @media only screen and (width < 600px) {
        width: 200%;
      }
    }

    h1 {
      color: $white;
      margin: 0;

      @include media-breakpoints {
        font-size: vw(30px, 24px);
        line-height: vw(38px, 34px);
      }
    }

    .logo {
      object-fit: contain;

      @include media-breakpoints {
        height: vw(150px, 100px);
        width: vw(150px, 100px);
      }

      &--box {
        align-items: center;
        background: $white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        overflow: hidden;

        @include media-breakpoints {
          height: vw(100px, 80px);
          margin: 0 auto vw(15px);
          padding: vw(15px);
          width: vw(100px, 80px);
        }

        @media only screen and (width < 768px) {
          display: none;
        }
      }
    }
  }

  &--checkout {
    @include media-breakpoints {
      padding-top: vw(35px);
    }

    &--account {
      @include media-breakpoints {
        padding-bottom: vw(75px);
      }
    }
  }

  &--standard {
    background: linear-gradient(147deg, #034382 8.88%, #032342 80.34%);
    @include media-breakpoints {
      padding-bottom: vw(25px, 15px);
      padding-top: vw(25px, 15px);
    }

    h1 {
      color: $white;
      margin: 0;
    }
  }


}

.shape {
  &__box {
    max-width: 100vw;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
}
