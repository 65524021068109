:root {
  --primary: #f08509;
  --secondary: #034382;
  --linear-start: #034382;
  --linear-stop: #032342;
}

.maxton:not(.new) {
  --primary: #c32222;
  --secondary: #2b2b29;
  --linear-start: #113B2F;
  --linear-stop: #1d5746;
}

.new-layout:not(.maxton) {
  //--primary: #00966C;
  --secondary: #2b2b29;
  --linear-start: #113B2F;
  --linear-stop: #1d5746;
}

$white: #fff;
$black: #333;
$real-black: #000;

$offwhite: #fcfcfc;

$gray: #f4f1ec;
$darkgray: #e5e5e4;
$beige: #f4f1ec;

$primary-color: var(--primary);
$secondary-color: var(--secondary);

$linear-start-color: var(--linear-start);
$linear-stop-color: var(--linear-stop);

$red: #ff4f4f;

$boxshadowcolor: rgba(103, 122, 135, .16);

$lightgreen: #00966c;
$green: #3e4532;

// Stock
$instock: #236c2a;
$onbackorder: #AEA697;
$outofstock: #ff4f4f;

$onbackordersingle: #F08509;


$colors: 'black' $black,
'white' $white,
'dark-blue' $secondary-color,
'beige' $beige,
'orange' $primary-color,
'secondary' $secondary-color,
'primary' $primary-color;

@each $name, $color in $colors {
  .background-#{$name} {
    background: $color;
  }

  .text-#{$name} {
    color: $color;
  }
}
