.breadcrumb {
  display: flex;
  align-items: center;
  background: $white;
  width: max-content;
  box-shadow: 0 8px 14px -2px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);

  @include media-breakpoints {
    border-radius: vw($br);
    padding: vw(10px, 8px) vw(15px);
    margin-bottom: vw(0, 15px);
  }

  @media only screen and (width < 768px) {
    background: unset;
    box-shadow: unset;
    padding: 0;
  }

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    @include media-breakpoints {
      column-gap: vw(10px, 5px);
    }

    li {
      a {
        color: $secondary-color;

        @media only screen and (width < 768px) {
          color: $white;
        }
      }
    }
  }

  .breadcrumb-box {
    display: flex;
    align-items: center;
  }

  .breadcrumb-text {
    @include media-breakpoints {
      font-size: vw(14px, 12px);
      margin-left: vw(10px, 5px);
    }
  }


  svg {
    height: auto;

    @include media-breakpoints {
      width: vw(20px, 16px);
    }

    path {
      fill: $secondary-color;
      transition: all .3s;

      @media only screen and (width < 768px) {
        fill: $white;
      }
    }

    &:hover:not(.breadcrumb-icon) {
      path {
        fill: $primary-color;

        @media only screen and (width < 768px) {
          fill: $white;
        }
      }
    }
  }

  a {
    color: $white;

    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  span {
    color: $secondary-color;

    @media only screen and (width < 768px) {
      color: $white;
    }

    &.current {
      font-weight: 700;
    }
  }
}
