.shop {
  &--body {
    position: relative;

    @include media-breakpoints {
      padding-bottom: vw(75px, 35px);
    }
  }

  &--details {
    @include media-breakpoints {
      margin-bottom: vw(25px);
    }

    .select-box {
      text-align: right;
    }

    select {
      background: unset url('/assets/images/icons/chevron-down-white.svg');
      border: 0;
      color: $black;
      width: auto;

      @include media-breakpoints {
        font-size: vw(14px);
        padding: vw(10px) vw(45px) vw(10px) vw(15px);
        border-radius: vw($br);
      }

      @media only screen and (width < 768px) {
        background-image: url('/assets/images/icons/chevron-down-black.svg');
        color: $black;
      }
    }

    span {
      color: $white;
    }

    button {
      @include media-breakpoints {
        padding: vw(5px) vw(20px);
      }
    }
  }

  &--filter-button-box {
    background: rgba($offwhite, 0) linear-gradient(to bottom, rgba($offwhite, 0) 0%, rgba($offwhite, 1) 80%);
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
    text-align: center;
    z-index: 8;

    @include media-breakpoints {
      padding: vw(15px) 0;
    }

    button {
      @include media-breakpoints {
        padding: vw(8px) vw(35px);
      }
    }
  }

  button:not(.active-filter) {
    @include media-breakpoints {
      font-size: vw(14px);
      gap: vw(5px);
    }

    svg {
      transform: rotate(90deg);

      @include media-breakpoints {
        width: vw(20px);
      }
    }
  }
}


.no-results {
  @include media-breakpoints {
    margin-top: vw(25px);
  }

  h3, p {
    color: $white;
  }
}

.store-alert {
  text-align: center;
  @include media-breakpoints {
    padding: vw(15px);
  }

  p {
    margin-bottom: 0;
    font-weight: 700;
  }

  &--success {
    background: $instock;
    color: $white;
  }

  &--error {
    background: $outofstock;
    color: $white;
  }
}
