html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
html.no-scroll {
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary: #f08509;
  --secondary: #034382;
  --linear-start: #034382;
  --linear-stop: #032342;
}

.maxton:not(.new) {
  --primary: #c32222;
  --secondary: #2b2b29;
  --linear-start: #113B2F;
  --linear-stop: #1d5746;
}

.new-layout:not(.maxton) {
  --secondary: #2b2b29;
  --linear-start: #113B2F;
  --linear-stop: #1d5746;
}

.background-black {
  background: #333;
}

.text-black {
  color: #333;
}

.background-white {
  background: #fff;
}

.text-white {
  color: #fff;
}

.background-dark-blue {
  background: var(--secondary);
}

.text-dark-blue {
  color: var(--secondary);
}

.background-beige {
  background: #f4f1ec;
}

.text-beige {
  color: #f4f1ec;
}

.background-orange {
  background: var(--primary);
}

.text-orange {
  color: var(--primary);
}

.background-secondary {
  background: var(--secondary);
}

.text-secondary {
  color: var(--secondary);
}

.background-primary {
  background: var(--primary);
}

.text-primary {
  color: var(--primary);
}

:root {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

aside {
  padding: 5px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #333;
  background: #fcfcfc;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.w-max {
  width: max-content;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 600px) {
  .container {
    max-width: 95vw;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) and (min-width: 992px) {
  .container {
    max-width: 100vw;
  }
}
@media (min-width: 1024px) and (max-width: 1799.98px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1024px) and (max-width: 1799.98px) and (max-width: 1439px) and (min-width: 992px) {
  .container {
    max-width: 100vw;
  }
}
@media (min-width: 1024px) and (max-width: 991.98px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1024px) and (max-width: 991.98px) and (max-width: 1439px) and (min-width: 992px) {
  .container {
    max-width: 100vw;
  }
}
@media (min-width: 1024px) and (max-width: 767.98px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1024px) and (max-width: 767.98px) and (max-width: 1439px) and (min-width: 992px) {
  .container {
    max-width: 100vw;
  }
}
@media (min-width: 1024px) and (max-width: 575.98px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1024px) and (max-width: 575.98px) and (max-width: 1439px) and (min-width: 992px) {
  .container {
    max-width: 100vw;
  }
}
@media only screen and (width > 1200px) {
  .container {
    max-width: 1600px;
  }
}
@media only screen and (width > 1200px) and (max-width: 1799.98px) {
  .container {
    max-width: 88.8888888889vw;
  }
}
@media only screen and (width > 1200px) and (max-width: 991.98px) {
  .container {
    max-width: 111.1111111111vw;
  }
}
@media only screen and (width > 1200px) and (max-width: 767.98px) {
  .container {
    max-width: 208.3333333333vw;
  }
}
@media only screen and (width > 1200px) and (max-width: 575.98px) {
  .container {
    max-width: 426.6666666667vw;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.667%;
    flex: 0 0 16.667%;
    max-width: 16.667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1025px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1600px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}
input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #4f0059 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #7C008C !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1026px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto,
.aligncenter {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto,
.aligncenter {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-regular {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

img.wp-post-image,
article img,
figure,
img,
#secondary img {
  max-width: 100%;
  height: auto;
}

.slide {
  list-style: none;
}

/* Slider CSS */
.slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}

.slick-dots > li {
  margin: 0 0.25rem;
}

.slick-dots > button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: blue;
  text-indent: -9999px;
}

.slick-dots > li.slick-active button {
  background-color: #fff;
}

/* END of Slider CSS */
/* Align column */
.atop {
  display: flex;
  align-items: flex-start;
}

.amiddle {
  display: flex;
  align-items: center;
}

.abottom {
  display: flex;
  align-items: flex-end;
}

.z-2 {
  position: relative;
  z-index: 2;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}
body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 1799.98px) {
  body {
    font-size: 0.8888888889vw;
    line-height: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  body {
    font-size: 1.1111111111vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  body {
    font-size: 1.8229166667vw;
    line-height: 2.8645833333vw;
  }
}
@media (max-width: 575.98px) {
  body {
    font-size: 3.7333333333vw;
    line-height: 5.8666666667vw;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

p, li, a, span {
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 1799.98px) {
  p, li, a, span {
    font-size: 0.8888888889vw;
    line-height: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  p, li, a, span {
    font-size: 1.1111111111vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  p, li, a, span {
    font-size: 1.8229166667vw;
    line-height: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  p, li, a, span {
    font-size: 3.7333333333vw;
    line-height: 6.4vw;
  }
}
p.subtitle, li.subtitle, a.subtitle, span.subtitle {
  font-weight: 300;
  font-size: 26px;
  line-height: 39px;
}
@media (max-width: 1799.98px) {
  p.subtitle, li.subtitle, a.subtitle, span.subtitle {
    font-size: 1.4444444444vw;
    line-height: 2.1666666667vw;
  }
}
@media (max-width: 991.98px) {
  p.subtitle, li.subtitle, a.subtitle, span.subtitle {
    font-size: 1.8055555556vw;
    line-height: 2.7083333333vw;
  }
}
@media (max-width: 767.98px) {
  p.subtitle, li.subtitle, a.subtitle, span.subtitle {
    font-size: 3.3854166667vw;
    line-height: 5.078125vw;
  }
}
@media (max-width: 575.98px) {
  p.subtitle, li.subtitle, a.subtitle, span.subtitle {
    font-size: 6.9333333333vw;
    line-height: 10.4vw;
  }
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
}

h1, .h1 {
  font-size: 42px;
  line-height: 48px;
}
@media (max-width: 1799.98px) {
  h1, .h1 {
    font-size: 2.3333333333vw;
    line-height: 2.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  h1, .h1 {
    font-size: 2.9166666667vw;
    line-height: 3.3333333333vw;
  }
}
@media (max-width: 767.98px) {
  h1, .h1 {
    font-size: 2.8645833333vw;
    line-height: 3.6458333333vw;
  }
}
@media (max-width: 575.98px) {
  h1, .h1 {
    font-size: 5.8666666667vw;
    line-height: 7.4666666667vw;
  }
}

h2, .h2, h2 p {
  font-size: 36px;
  line-height: 42px;
}
@media (max-width: 1799.98px) {
  h2, .h2, h2 p {
    font-size: 2vw;
    line-height: 2.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  h2, .h2, h2 p {
    font-size: 2.5vw;
    line-height: 2.9166666667vw;
  }
}
@media (max-width: 767.98px) {
  h2, .h2, h2 p {
    font-size: 2.6041666667vw;
    line-height: 3.3854166667vw;
  }
}
@media (max-width: 575.98px) {
  h2, .h2, h2 p {
    font-size: 5.3333333333vw;
    line-height: 6.9333333333vw;
  }
}

h3, .h3, h3 p {
  font-size: 22px;
  line-height: 30px;
}
@media (max-width: 1799.98px) {
  h3, .h3, h3 p {
    font-size: 1.2222222222vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  h3, .h3, h3 p {
    font-size: 1.5277777778vw;
    line-height: 2.0833333333vw;
  }
}
@media (max-width: 767.98px) {
  h3, .h3, h3 p {
    font-size: 2.6041666667vw;
    line-height: 3.6458333333vw;
  }
}
@media (max-width: 575.98px) {
  h3, .h3, h3 p {
    font-size: 5.3333333333vw;
    line-height: 7.4666666667vw;
  }
}

h4, .h4, h4 p {
  font-size: 22px;
  line-height: 28px;
}
@media (max-width: 1799.98px) {
  h4, .h4, h4 p {
    font-size: 1.2222222222vw;
    line-height: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  h4, .h4, h4 p {
    font-size: 1.5277777778vw;
    line-height: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  h4, .h4, h4 p {
    font-size: 2.34375vw;
    line-height: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  h4, .h4, h4 p {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }
}

main {
  min-height: 580px;
}
@media (max-width: 1799.98px) {
  main {
    min-height: 32.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  main {
    min-height: 40.2777777778vw;
  }
}
@media (max-width: 767.98px) {
  main {
    min-height: 75.5208333333vw;
  }
}
@media (max-width: 575.98px) {
  main {
    min-height: 154.6666666667vw;
  }
}

.btn {
  border: 0;
  display: inline-flex;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  transition: all 0.3s linear;
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 25px;
}
@media (max-width: 1799.98px) {
  .btn {
    border-radius: 0.5555555556vw;
    font-size: 0.8888888889vw;
    line-height: 1.2222222222vw;
    padding: 0.5555555556vw 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .btn {
    border-radius: 0.6944444444vw;
    font-size: 1.1111111111vw;
    line-height: 1.5277777778vw;
    padding: 0.6944444444vw 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .btn {
    border-radius: 1.3020833333vw;
    font-size: 1.8229166667vw;
    line-height: 2.6041666667vw;
    padding: 1.3020833333vw 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .btn {
    border-radius: 2.6666666667vw;
    font-size: 3.7333333333vw;
    line-height: 5.3333333333vw;
    padding: 2.6666666667vw 6.6666666667vw;
  }
}
.btn--trans-white {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.btn--trans-white svg path {
  fill: #fff;
}
.btn--trans-white:hover {
  background: #fff;
  color: var(--secondary);
}
.btn--trans-white:hover svg path {
  fill: var(--secondary);
}
.btn--orange, .btn.has-orange-background-color, .btn--primary {
  background: var(--primary);
  color: #fff;
}
.btn--orange svg path, .btn.has-orange-background-color svg path, .btn--primary svg path {
  fill: #fff;
}
.btn--orange:hover, .btn.has-orange-background-color:hover, .btn--primary:hover {
  background: var(--secondary);
}
.btn--trans-primary {
  background: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
}
.btn--trans-primary svg path {
  fill: var(--primary);
}
.btn--trans-primary:hover {
  background: var(--primary);
  color: #fff;
}
.btn--trans-primary:hover svg path {
  fill: #fff;
}
.btn--gray {
  background: #f4f1ec;
  color: #333;
}
.btn--darkblue, .btn--secondary {
  background: var(--secondary);
  color: #fff;
}
.btn--darkblue:hover, .btn--secondary:hover {
  background: var(--primary);
}
.btn--shop {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 50px;
  width: 50px;
  padding: 0;
}
@media (max-width: 1799.98px) {
  .btn--shop {
    height: 2.7777777778vw;
    width: 2.7777777778vw;
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .btn--shop {
    height: 3.4722222222vw;
    width: 3.4722222222vw;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .btn--shop {
    height: 5.2083333333vw;
    width: 5.2083333333vw;
    padding: 0;
  }
}
@media (max-width: 575.98px) {
  .btn--shop {
    height: 10.6666666667vw;
    width: 10.6666666667vw;
    padding: 0;
  }
}
.btn--shop svg {
  height: auto;
  width: 28px;
}
@media (max-width: 1799.98px) {
  .btn--shop svg {
    width: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .btn--shop svg {
    width: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .btn--shop svg {
    width: 2.8645833333vw;
  }
}
@media (max-width: 575.98px) {
  .btn--shop svg {
    width: 5.8666666667vw;
  }
}
.btn--shop:hover {
  background: var(--secondary);
}
.btn--icon {
  align-items: center;
  justify-content: center;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .btn--icon {
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .btn--icon {
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .btn--icon {
    gap: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .btn--icon {
    gap: 2.6666666667vw;
  }
}
.btn--icon svg {
  width: 32px;
}
@media (max-width: 1799.98px) {
  .btn--icon svg {
    width: 1.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .btn--icon svg {
    width: 2.2222222222vw;
  }
}
@media (max-width: 767.98px) {
  .btn--icon svg {
    width: 3.3854166667vw;
  }
}
@media (max-width: 575.98px) {
  .btn--icon svg {
    width: 6.9333333333vw;
  }
}
.btn--icon.download {
  width: 100%;
  justify-content: space-between;
}
.btn--icon.download svg {
  width: 20px;
}
@media (max-width: 1799.98px) {
  .btn--icon.download svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .btn--icon.download svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .btn--icon.download svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .btn--icon.download svg {
    width: 5.3333333333vw;
  }
}
.btn--plane {
  background: none;
  padding: 0;
}
.btn--plane--darkblue {
  color: var(--secondary);
}
.btn--plane--darkblue svg {
  width: 25px;
}
@media (max-width: 1799.98px) {
  .btn--plane--darkblue svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .btn--plane--darkblue svg {
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .btn--plane--darkblue svg {
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .btn--plane--darkblue svg {
    width: 6.6666666667vw;
  }
}
.btn--plane--darkblue svg path {
  fill: var(--secondary);
}
.btn--plane.btn--icon {
  gap: 5px;
}
@media (max-width: 1799.98px) {
  .btn--plane.btn--icon {
    gap: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .btn--plane.btn--icon {
    gap: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .btn--plane.btn--icon {
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .btn--plane.btn--icon {
    gap: 1.3333333333vw;
  }
}

.link.arrow {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .link.arrow {
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .link.arrow {
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .link.arrow {
    gap: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .link.arrow {
    gap: 2.6666666667vw;
  }
}
.link.arrow svg {
  width: 20px;
}
@media (max-width: 1799.98px) {
  .link.arrow svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .link.arrow svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .link.arrow svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .link.arrow svg {
    width: 5.3333333333vw;
  }
}

.core-header {
  background: #fff;
  box-shadow: 0 3px 3px 0 rgba(51, 51, 51, 0);
  position: sticky;
  top: 0;
  transition: all 0.3s;
  z-index: 20;
}
@media only screen and (width < 768px) {
  .core-header .container {
    max-width: 100vw;
  }
}
.core-header.sticky:not(.no-box-shadow) {
  box-shadow: 0 3px 3px 0 rgba(51, 51, 51, 0.06);
}
.core-header .header--box {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  transition: all 0.3s;
}
.core-header .header--box.search-active .header--box--right {
  max-width: 600px;
}
@media (max-width: 1799.98px) {
  .core-header .header--box.search-active .header--box--right {
    max-width: 33.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .header--box.search-active .header--box--right {
    max-width: 41.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .header--box.search-active .header--box--right {
    max-width: 78.125vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .header--box.search-active .header--box--right {
    max-width: 160vw;
  }
}
.core-header .header--box--left {
  align-items: center;
  display: flex;
  position: relative;
  transition: all 0.3s;
  gap: 40px;
  max-width: 825px;
}
@media (max-width: 1799.98px) {
  .core-header .header--box--left {
    gap: 2.2222222222vw;
    max-width: 45.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .header--box--left {
    gap: 2.7777777778vw;
    max-width: 57.2916666667vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .header--box--left {
    gap: 5.2083333333vw;
    max-width: 107.421875vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .header--box--left {
    gap: 10.6666666667vw;
    max-width: 220vw;
  }
}
@media only screen and (width >= 768px) {
  .core-header .header--box--left--mobile {
    display: none;
  }
}
.core-header .header--box--right {
  align-items: center;
  display: flex;
  transition: all 0.3s;
  gap: 20px;
  max-width: 450px;
}
@media only screen and (width > 768px) {
  .core-header .header--box--right {
    width: 100%;
  }
}
@media (max-width: 1799.98px) {
  .core-header .header--box--right {
    gap: 1.1111111111vw;
    max-width: 25vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .header--box--right {
    gap: 1.3888888889vw;
    max-width: 31.25vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .header--box--right {
    gap: 1.3020833333vw;
    max-width: unset;
  }
}
@media (max-width: 575.98px) {
  .core-header .header--box--right {
    gap: 2.6666666667vw;
    max-width: unset;
  }
}
.core-header .header--box--right .icons {
  align-items: center;
  display: flex;
  transition: all 0.3s;
  gap: 20px;
}
@media (max-width: 1799.98px) {
  .core-header .header--box--right .icons {
    gap: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .header--box--right .icons {
    gap: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .header--box--right .icons {
    gap: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .header--box--right .icons {
    gap: 2.6666666667vw;
  }
}
.core-header .header nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 30px;
}
@media (max-width: 1799.98px) {
  .core-header .header nav ul {
    gap: 1.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .header nav ul {
    gap: 2.0833333333vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .header nav ul {
    gap: 3.90625vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .header nav ul {
    gap: 8vw;
  }
}
.core-header .header nav ul > li {
  position: relative;
}
.core-header .header nav ul > li > a {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  gap: 5px;
  padding: 36px 0;
}
@media (max-width: 1799.98px) {
  .core-header .header nav ul > li > a {
    font-size: 0.8888888889vw;
    gap: 0.2777777778vw;
    padding: 2vw 0;
  }
}
@media (max-width: 991.98px) {
  .core-header .header nav ul > li > a {
    font-size: 1.1111111111vw;
    gap: 0.3472222222vw;
    padding: 2.5vw 0;
  }
}
@media (max-width: 767.98px) {
  .core-header .header nav ul > li > a {
    font-size: 2.0833333333vw;
    gap: 0.6510416667vw;
    padding: 1.953125vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .header nav ul > li > a {
    font-size: 4.2666666667vw;
    gap: 1.3333333333vw;
    padding: 4vw 4vw;
  }
}
.core-header .header nav ul > li > a svg {
  height: auto;
  width: 15px;
}
@media (max-width: 1799.98px) {
  .core-header .header nav ul > li > a svg {
    width: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .header nav ul > li > a svg {
    width: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .header nav ul > li > a svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .header nav ul > li > a svg {
    width: 5.3333333333vw;
  }
}
@media only screen and (width < 768px) {
  .core-header .header nav > ul {
    display: block;
  }
  .core-header .header nav > ul > li a {
    border-bottom: 1px solid #f4f1ec;
    display: flex;
    justify-content: space-between;
  }
  .core-header .header nav > ul > li a svg {
    transform: rotate(-90deg);
  }
}
.core-header .icon {
  position: relative;
  padding: 34.2px 0;
}
@media (max-width: 1799.98px) {
  .core-header .icon {
    padding: 1.9vw 0;
  }
}
@media (max-width: 991.98px) {
  .core-header .icon {
    padding: 2.375vw 0;
  }
}
@media (max-width: 767.98px) {
  .core-header .icon {
    padding: 2.34375vw 0;
  }
}
@media (max-width: 575.98px) {
  .core-header .icon {
    padding: 4.8vw 0;
  }
}
.core-header .icon--box {
  position: relative;
}
.core-header .icon svg {
  height: auto;
  width: 28px;
}
@media (max-width: 1799.98px) {
  .core-header .icon svg {
    width: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .icon svg {
    width: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .icon svg {
    width: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .icon svg {
    width: 6.4vw;
  }
}
.core-header .icon svg path {
  transition: all 0.3s;
  fill: #333;
}
.core-header .icon .check {
  position: absolute;
  height: auto;
  background: #fff;
  border-radius: 50%;
  width: 20px;
  top: -5px;
  right: -4px;
}
@media (max-width: 1799.98px) {
  .core-header .icon .check {
    width: 1.1111111111vw;
    top: -0.2777777778vw;
    right: -4px;
  }
}
@media (max-width: 991.98px) {
  .core-header .icon .check {
    width: 1.3888888889vw;
    top: -0.3472222222vw;
    right: -4px;
  }
}
@media (max-width: 767.98px) {
  .core-header .icon .check {
    width: 2.6041666667vw;
    top: -0.6510416667vw;
    right: -4px;
  }
}
@media (max-width: 575.98px) {
  .core-header .icon .check {
    width: 5.3333333333vw;
    top: -1.3333333333vw;
    right: -4px;
  }
}
.core-header .icon .check path {
  fill: #69a00b;
}
.core-header .icon.mini-cart {
  position: relative;
  margin-top: -2px;
}
.core-header .icon.mini-cart .cart-count {
  background: var(--primary);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -8px;
  right: -4px;
  font-size: 12px;
}
@media (max-width: 1799.98px) {
  .core-header .icon.mini-cart .cart-count {
    width: 1.1111111111vw;
    height: 1.1111111111vw;
    top: -0.4444444444vw;
    right: -0.2222222222vw;
    font-size: 0.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .icon.mini-cart .cart-count {
    width: 1.3888888889vw;
    height: 1.3888888889vw;
    top: -0.5555555556vw;
    right: -0.2777777778vw;
    font-size: 0.8333333333vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .icon.mini-cart .cart-count {
    width: 1.953125vw;
    height: 1.953125vw;
    top: -0.78125vw;
    right: -0.2604166667vw;
    font-size: 1.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .icon.mini-cart .cart-count {
    width: 4vw;
    height: 4vw;
    top: -1.6vw;
    right: -0.5333333333vw;
    font-size: 2.1333333333vw;
  }
}
.core-header .icon:hover .icon--box svg path {
  fill: var(--primary);
}
.core-header .icon.search path {
  fill: #333;
}
@media only screen and (width > 768px) {
  .core-header .icon.search {
    display: none;
  }
}
@media only screen and (width < 768px) {
  .core-header .icon.search .close {
    display: none;
  }
  .core-header .icon.search.active .close {
    display: block;
  }
  .core-header .icon.search.active .search {
    display: none;
  }
}
@media only screen and (width < 768px) {
  .core-header .icon.account {
    display: none;
  }
}
.core-header .account--dropdown {
  position: absolute;
  top: 100%;
  right: -25px;
  background: #fff;
  width: max-content;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear 0.3s;
  transform: translateY(-10px);
  z-index: 2;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
@media (max-width: 1799.98px) {
  .core-header .account--dropdown {
    border-bottom-right-radius: 0.2777777778vw;
    border-bottom-left-radius: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .account--dropdown {
    border-bottom-right-radius: 0.3472222222vw;
    border-bottom-left-radius: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .account--dropdown {
    border-bottom-right-radius: 0.6510416667vw;
    border-bottom-left-radius: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .account--dropdown {
    border-bottom-right-radius: 1.3333333333vw;
    border-bottom-left-radius: 1.3333333333vw;
  }
}
.core-header .account--dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.core-header .account--dropdown ul li:not(:last-child) a {
  border-bottom: 1px solid #f4f1ec;
}
.core-header .account--dropdown a {
  display: flex;
  transition: all 0.3s;
  padding: 10px 15px;
  gap: 8px;
}
@media (max-width: 1799.98px) {
  .core-header .account--dropdown a {
    padding: 0.5555555556vw 0.8333333333vw;
    gap: 0.4444444444vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .account--dropdown a {
    padding: 0.6944444444vw 1.0416666667vw;
    gap: 0.5555555556vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .account--dropdown a {
    padding: 1.3020833333vw 1.953125vw;
    gap: 1.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .account--dropdown a {
    padding: 2.6666666667vw 4vw;
    gap: 2.1333333333vw;
  }
}
.core-header .account--dropdown a:hover {
  background: #f4f1ec;
}
.core-header .account--dropdown a:hover svg path {
  fill: #333;
}
.core-header .account--dropdown a svg {
  height: auto;
  width: 20px;
}
@media (max-width: 1799.98px) {
  .core-header .account--dropdown a svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .account--dropdown a svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .account--dropdown a svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .account--dropdown a svg {
    width: 5.3333333333vw;
  }
}
.core-header .account--dropdown a svg path {
  fill: var(--secondary);
}
.core-header .account:hover .account--dropdown {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s;
  visibility: visible;
}
.core-header button {
  background: none;
  border: 0;
  outline: none;
  padding: 0;
}
.core-header .core-logo {
  height: auto;
  width: 175px;
  padding: 0 0;
}
@media (max-width: 1799.98px) {
  .core-header .core-logo {
    width: 9.7222222222vw;
    padding: 0 0;
  }
}
@media (max-width: 991.98px) {
  .core-header .core-logo {
    width: 12.1527777778vw;
    padding: 0 0;
  }
}
@media (max-width: 767.98px) {
  .core-header .core-logo {
    width: 19.53125vw;
    padding: 1.3020833333vw 0;
  }
}
@media (max-width: 575.98px) {
  .core-header .core-logo {
    width: 40vw;
    padding: 2.6666666667vw 0;
  }
}
.core-header .core-logo path {
  fill: #3e4532;
}
.core-header svg.core-logo {
  width: 175px;
  padding: 0 0;
}
@media (max-width: 1799.98px) {
  .core-header svg.core-logo {
    width: 9.7222222222vw;
    padding: 0 0;
  }
}
@media (max-width: 991.98px) {
  .core-header svg.core-logo {
    width: 12.1527777778vw;
    padding: 0 0;
  }
}
@media (max-width: 767.98px) {
  .core-header svg.core-logo {
    width: 14.9739583333vw;
    padding: 0.6510416667vw 0;
  }
}
@media (max-width: 575.98px) {
  .core-header svg.core-logo {
    width: 30.6666666667vw;
    padding: 1.3333333333vw 0;
  }
}
.core-header .search-form {
  position: relative;
  transition: all 0.3s;
  width: 100%;
  z-index: 22;
  max-width: 650px;
}
@media (max-width: 1799.98px) {
  .core-header .search-form {
    max-width: 36.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-form {
    max-width: 45.1388888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-form {
    max-width: 84.6354166667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-form {
    max-width: 173.3333333333vw;
  }
}
.core-header .search-form button {
  padding: 0;
  background: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
@media (max-width: 1799.98px) {
  .core-header .search-form button {
    right: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-form button {
    right: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-form button {
    right: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-form button {
    right: 5.3333333333vw;
  }
}
.core-header .search-form button svg {
  height: auto;
  width: 20px;
}
@media (max-width: 1799.98px) {
  .core-header .search-form button svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-form button svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-form button svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-form button svg {
    width: 5.3333333333vw;
  }
}
.core-header .search-form button#reset-search {
  right: 45px;
  width: 30px;
}
@media (max-width: 1799.98px) {
  .core-header .search-form button#reset-search {
    right: 2.5vw;
    width: 1.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-form button#reset-search {
    right: 3.125vw;
    width: 2.0833333333vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-form button#reset-search {
    right: 5.859375vw;
    width: 3.90625vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-form button#reset-search {
    right: 12vw;
    width: 8vw;
  }
}
.core-header .search-form button#reset-search path {
  fill: #ff4f4f;
}
.core-header .search-form label {
  width: 100%;
  transition: all 0.3s;
}
.core-header .search-form input {
  background: #f4f1ec;
  border: 0;
  color: #333;
  outline: none;
  transition: all 0.3s;
  width: 100%;
  border-radius: 50px;
  font-size: 14px;
  line-height: 20px;
  padding: 18px 20px;
}
@media (max-width: 1799.98px) {
  .core-header .search-form input {
    border-radius: 2.7777777778vw;
    font-size: 0.7777777778vw;
    line-height: 1.1111111111vw;
    padding: 1vw 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-form input {
    border-radius: 3.4722222222vw;
    font-size: 0.9722222222vw;
    line-height: 1.3888888889vw;
    padding: 1.25vw 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-form input {
    border-radius: 6.5104166667vw;
    font-size: 1.8229166667vw;
    line-height: 2.6041666667vw;
    padding: 2.34375vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-form input {
    border-radius: 13.3333333333vw;
    font-size: 3.7333333333vw;
    line-height: 5.3333333333vw;
    padding: 4.8vw 4vw;
  }
}
.core-header .search-form input.active {
  background: #fff;
}
.core-header .search-form label {
  position: relative;
  margin: 0;
}
@media only screen and (width < 768px) {
  .core-header .search-form--box {
    background: #fff;
    border-top: 1px solid #f4f1ec;
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: visible;
    position: fixed;
    transform: translateX(100%);
    transition: opacity 0.3s ease 0.3s, transform 0.3s ease, visibility 0s linear 0.3s;
    visibility: hidden;
    width: 100vw;
    top: 62px;
    max-height: calc(100dvh - 62px);
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .core-header .search-form--box {
    top: 3.4444444444vw;
    max-height: calc(100dvh - 3.4444444444vw);
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .core-header .search-form--box {
    top: 4.3055555556vw;
    max-height: calc(100dvh - 4.3055555556vw);
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .core-header .search-form--box {
    top: 8.0729166667vw;
    max-height: calc(100dvh - 8.0729166667vw);
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .core-header .search-form--box {
    top: 16.5333333333vw;
    max-height: calc(100dvh - 16.5333333333vw);
  }
}
@media only screen and (width < 768px) {
  .core-header .search-form--box.show {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0s ease, transform 0.3s ease, visibility 0s;
    visibility: visible;
  }
  .core-header .search-form--box input {
    border-radius: 0;
  }
  .core-header .search-form--box input.active {
    background: #f4f1ec;
  }
}
@media only screen and (width > 768px) {
  .core-header .search-form--box {
    width: 100%;
  }
}
.core-header .search-results {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 13;
  background: #fff;
  overflow: auto;
  border-radius: 10px;
  max-height: 600px;
  top: calc(100% + 5px);
}
@media (max-width: 1799.98px) {
  .core-header .search-results {
    border-radius: 0.5555555556vw;
    max-height: 33.3333333333vw;
    top: calc(100% + 0.2777777778vw);
  }
}
@media (max-width: 991.98px) {
  .core-header .search-results {
    border-radius: 0.6944444444vw;
    max-height: 41.6666666667vw;
    top: calc(100% + 0.3472222222vw);
  }
}
@media (max-width: 767.98px) {
  .core-header .search-results {
    border-radius: 0;
    max-height: 78.125vw;
    top: calc(100% + 0);
  }
}
@media (max-width: 575.98px) {
  .core-header .search-results {
    border-radius: 0;
    max-height: 160vw;
    top: calc(100% + 0);
  }
}
@media only screen and (width < 768px) {
  .core-header .search-results {
    border-top: 1px solid #e5e5e4;
    max-height: calc(100dvh - 32vw);
  }
}
.core-header .search-results .header {
  align-items: center;
  background: #f4f1ec;
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
}
@media (max-width: 1799.98px) {
  .core-header .search-results .header {
    padding: 0.5555555556vw 0.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-results .header {
    padding: 0.6944444444vw 0.8333333333vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-results .header {
    padding: 1.3020833333vw 1.5625vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-results .header {
    padding: 2.6666666667vw 3.2vw;
  }
}
.core-header .search-results .header a {
  color: var(--secondary);
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}
@media (max-width: 1799.98px) {
  .core-header .search-results .header a {
    font-size: 0.6666666667vw;
    line-height: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-results .header a {
    font-size: 0.8333333333vw;
    line-height: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-results .header a {
    font-size: 1.5625vw;
    line-height: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-results .header a {
    font-size: 3.2vw;
    line-height: 5.3333333333vw;
  }
}
.core-header .search-results h3 {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 1799.98px) {
  .core-header .search-results h3 {
    font-size: 0.8888888889vw;
    line-height: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-results h3 {
    font-size: 1.1111111111vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-results h3 {
    font-size: 2.0833333333vw;
    line-height: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-results h3 {
    font-size: 4.2666666667vw;
    line-height: 6.4vw;
  }
}
.core-header .search-results ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.core-header .search-results ul li:not(:last-child) {
  border-bottom: 1px solid #f4f1ec;
}
.core-header .search-results ul li a {
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  transition: all 0.1s;
  font-size: 14px;
  gap: 10px;
  line-height: 20px;
  padding: 8px 12px;
}
@media (max-width: 1799.98px) {
  .core-header .search-results ul li a {
    font-size: 0.7777777778vw;
    gap: 0.5555555556vw;
    line-height: 1.1111111111vw;
    padding: 0.4444444444vw 0.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-results ul li a {
    font-size: 0.9722222222vw;
    gap: 0.6944444444vw;
    line-height: 1.3888888889vw;
    padding: 0.5555555556vw 0.8333333333vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-results ul li a {
    font-size: 1.8229166667vw;
    gap: 1.3020833333vw;
    line-height: 2.6041666667vw;
    padding: 1.0416666667vw 1.5625vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-results ul li a {
    font-size: 3.7333333333vw;
    gap: 2.6666666667vw;
    line-height: 5.3333333333vw;
    padding: 2.1333333333vw 3.2vw;
  }
}
.core-header .search-results ul li a svg {
  height: auto;
  min-width: 15px;
  width: 15px;
}
@media (max-width: 1799.98px) {
  .core-header .search-results ul li a svg {
    min-width: 0.8333333333vw;
    width: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-results ul li a svg {
    min-width: 1.0416666667vw;
    width: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-results ul li a svg {
    min-width: 1.953125vw;
    width: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-results ul li a svg {
    min-width: 4vw;
    width: 4vw;
  }
}
.core-header .search-results ul li a:hover {
  background: #e5e5e4;
}
.core-header .search-results ul li a.all {
  background: var(--secondary);
  color: #fff;
  font-weight: 700;
  padding: 10px 12px;
}
@media (max-width: 1799.98px) {
  .core-header .search-results ul li a.all {
    padding: 0.5555555556vw 0.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .core-header .search-results ul li a.all {
    padding: 0.6944444444vw 0.8333333333vw;
  }
}
@media (max-width: 767.98px) {
  .core-header .search-results ul li a.all {
    padding: 1.3020833333vw 1.5625vw;
  }
}
@media (max-width: 575.98px) {
  .core-header .search-results ul li a.all {
    padding: 2.6666666667vw 3.2vw;
  }
}
.core-header .search-results ul li a.all svg path {
  fill: #fff;
}

.mega-menu {
  background: #fff;
  border-top: 1px solid #f4f1ec;
  left: 0;
  opacity: 0;
  position: fixed;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 3;
  padding-bottom: 35px;
  padding-top: 35px;
  top: 188px;
}
@media (max-width: 1799.98px) {
  .mega-menu {
    padding-bottom: 1.9444444444vw;
    padding-top: 1.9444444444vw;
    top: 10.4444444444vw;
  }
}
@media (max-width: 991.98px) {
  .mega-menu {
    padding-bottom: 2.4305555556vw;
    padding-top: 2.4305555556vw;
    top: 13.0555555556vw;
  }
}
@media (max-width: 767.98px) {
  .mega-menu {
    padding-bottom: 4.5572916667vw;
    padding-top: 4.5572916667vw;
    top: 24.4791666667vw;
  }
}
@media (max-width: 575.98px) {
  .mega-menu {
    padding-bottom: 9.3333333333vw;
    padding-top: 9.3333333333vw;
    top: 50.1333333333vw;
  }
}
.mega-menu.show {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s;
  visibility: visible;
}
.mega-menu.sticky {
  top: 96px;
}
@media (max-width: 1799.98px) {
  .mega-menu.sticky {
    top: 5.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .mega-menu.sticky {
    top: 6.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .mega-menu.sticky {
    top: 12.5vw;
  }
}
@media (max-width: 575.98px) {
  .mega-menu.sticky {
    top: 25.6vw;
  }
}
.mega-menu--backdrop {
  background: rgba(0, 0, 0, 0.2);
  height: 100dvh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 3;
}
.mega-menu--backdrop.show {
  visibility: visible;
  opacity: 1;
}
.mega-menu .main-items {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mega-menu .main-items li {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 15px;
}
.mega-menu .main-items li:not(:last-child) {
  margin-bottom: 10px;
}
@media (max-width: 1799.98px) {
  .mega-menu .main-items li:not(:last-child) {
    margin-bottom: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .mega-menu .main-items li:not(:last-child) {
    margin-bottom: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .mega-menu .main-items li:not(:last-child) {
    margin-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .mega-menu .main-items li:not(:last-child) {
    margin-bottom: 0;
  }
}
@media (max-width: 1799.98px) {
  .mega-menu .main-items li {
    border-radius: 0.2777777778vw;
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .mega-menu .main-items li {
    border-radius: 0.3472222222vw;
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .mega-menu .main-items li {
    border-radius: 0.6510416667vw;
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .mega-menu .main-items li {
    border-radius: 1.3333333333vw;
    padding: 4vw;
  }
}
.mega-menu .main-items li svg {
  height: auto;
  width: 25px;
}
@media (max-width: 1799.98px) {
  .mega-menu .main-items li svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .mega-menu .main-items li svg {
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .mega-menu .main-items li svg {
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .mega-menu .main-items li svg {
    width: 6.6666666667vw;
  }
}
.mega-menu .main-items li svg path {
  fill: var(--secondary);
}
@media only screen and (width > 768px) {
  .mega-menu .main-items li.active, .mega-menu .main-items li:hover {
    background: #f4f1ec;
  }
}
.mega-menu .main-items .title {
  font-weight: 700;
  display: block;
}
.mega-menu .main-items .text {
  font-size: 14px;
}
@media (max-width: 1799.98px) {
  .mega-menu .main-items .text {
    font-size: 0.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .mega-menu .main-items .text {
    font-size: 0.9722222222vw;
  }
}
@media (max-width: 767.98px) {
  .mega-menu .main-items .text {
    font-size: 1.8229166667vw;
  }
}
@media (max-width: 575.98px) {
  .mega-menu .main-items .text {
    font-size: 3.7333333333vw;
  }
}
@media only screen and (width > 768px) {
  .mega-menu .menu {
    display: none;
    padding-left: 35px;
  }
}
@media only screen and (width > 768px) and (max-width: 1799.98px) {
  .mega-menu .menu {
    padding-left: 1.9444444444vw;
  }
}
@media only screen and (width > 768px) and (max-width: 991.98px) {
  .mega-menu .menu {
    padding-left: 2.4305555556vw;
  }
}
@media only screen and (width > 768px) and (max-width: 767.98px) {
  .mega-menu .menu {
    padding-left: 4.5572916667vw;
  }
}
@media only screen and (width > 768px) and (max-width: 575.98px) {
  .mega-menu .menu {
    padding-left: 9.3333333333vw;
  }
}
@media only screen and (width > 768px) {
  .mega-menu .menu.show {
    display: block;
  }
}
.mega-menu .menu .title {
  color: var(--secondary);
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 1799.98px) {
  .mega-menu .menu .title {
    font-size: 1.1111111111vw;
    line-height: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .mega-menu .menu .title {
    font-size: 1.3888888889vw;
    line-height: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .mega-menu .menu .title {
    font-size: 2.6041666667vw;
    line-height: 3.6458333333vw;
  }
}
@media (max-width: 575.98px) {
  .mega-menu .menu .title {
    font-size: 5.3333333333vw;
    line-height: 7.4666666667vw;
  }
}
.mega-menu .menu.brands-menu ul {
  columns: 4;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (width < 768px) {
  .mega-menu .menu.brands-menu ul {
    columns: 1;
  }
}
.mega-menu .menu.brands-menu ul li a {
  font-weight: 500;
  transition: all 0.3s;
}
.mega-menu .menu.brands-menu ul li a:hover {
  color: var(--secondary);
}
.mega-menu--menus {
  border-left: 1px solid #f4f1ec;
}
@media only screen and (width < 768px) {
  .mega-menu .container {
    max-width: 100%;
  }
  .mega-menu .container .col-12 {
    padding: 0;
  }
  .mega-menu li {
    border-bottom: 1px solid #f4f1ec;
  }
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
  position: fixed;
  z-index: 20;
  visibility: hidden;
}
@media only screen and (width < 768px) {
  .backdrop {
    display: none;
  }
  .backdrop.coupon {
    display: block;
  }
}
.backdrop.show {
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
  visibility: visible;
}
.backdrop#search-backdrop {
  z-index: 21;
}

.menu-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
}
@media (max-width: 1799.98px) {
  .menu-icon {
    gap: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .menu-icon {
    gap: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .menu-icon {
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .menu-icon {
    gap: 1.3333333333vw;
  }
}
.menu-icon span {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 1799.98px) {
  .menu-icon span {
    font-size: 0.7777777778vw;
    line-height: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .menu-icon span {
    font-size: 0.9722222222vw;
    line-height: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .menu-icon span {
    font-size: 1.8229166667vw;
    line-height: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .menu-icon span {
    font-size: 3.7333333333vw;
    line-height: 5.3333333333vw;
  }
}

#hamburger .hamburger {
  z-index: 18;
  position: relative;
  transition: all 0.3s;
  height: 12px;
  width: 20px;
}
@media (max-width: 1799.98px) {
  #hamburger .hamburger {
    height: 0.6666666667vw;
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  #hamburger .hamburger {
    height: 0.8333333333vw;
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  #hamburger .hamburger {
    height: 1.5625vw;
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  #hamburger .hamburger {
    height: 3.2vw;
    width: 5.3333333333vw;
  }
}
#hamburger .hamburger--top, #hamburger .hamburger--middle, #hamburger .hamburger--bottom {
  background: #333;
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
}
@media (max-width: 1799.98px) {
  #hamburger .hamburger--top, #hamburger .hamburger--middle, #hamburger .hamburger--bottom {
    height: 0.1111111111vw;
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  #hamburger .hamburger--top, #hamburger .hamburger--middle, #hamburger .hamburger--bottom {
    height: 0.1388888889vw;
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  #hamburger .hamburger--top, #hamburger .hamburger--middle, #hamburger .hamburger--bottom {
    height: 0.2604166667vw;
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  #hamburger .hamburger--top, #hamburger .hamburger--middle, #hamburger .hamburger--bottom {
    height: 0.5333333333vw;
    width: 5.3333333333vw;
  }
}
#hamburger .hamburger--top, #hamburger .hamburger--bottom {
  transition: transform 0.4s ease, top 0.3s ease 0.4s;
}
#hamburger .hamburger--middle {
  top: 50%;
  transition: opacity 0.2s ease 0.5s;
}
#hamburger .hamburger--bottom {
  top: 100%;
}
#hamburger.active .hamburger--top, #hamburger.active .hamburger--bottom {
  transition: top 0.3s ease, transform 0.4s ease 0.3s;
}
#hamburger.active .hamburger--top {
  top: 50%;
  transform: rotate(45deg);
}
#hamburger.active .hamburger--middle {
  opacity: 0;
  transition: none;
}
#hamburger.active .hamburger--bottom {
  top: 50%;
  transform: rotate(-45deg);
}

@media only screen and (width < 768px) {
  .responsive-menu {
    background: #fff;
    border-top: 1px solid #f4f1ec;
    height: 100%;
    left: 0;
    max-height: 100dvh;
    opacity: 0;
    overflow: auto;
    position: fixed;
    transform: translateX(100%);
    transition: opacity 0.3s ease 0.3s, transform 0.3s ease, visibility 0s linear 0.3s;
    visibility: hidden;
    width: 100vw;
    z-index: 22;
    max-height: calc(100dvh - 62px);
    top: 62px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .responsive-menu {
    max-height: calc(100dvh - 3.4444444444vw);
    top: 3.4444444444vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .responsive-menu {
    max-height: calc(100dvh - 4.3055555556vw);
    top: 4.3055555556vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .responsive-menu {
    max-height: calc(100dvh - 8.0729166667vw);
    top: 8.0729166667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .responsive-menu {
    max-height: calc(100dvh - 16.5333333333vw);
    top: 16.5333333333vw;
  }
}
@media only screen and (width < 768px) {
  .responsive-menu.mega-menu, .responsive-menu.navigation {
    padding: 0;
  }
  .responsive-menu.menu {
    top: 0;
  }
  .responsive-menu.show {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0s ease, transform 0.3s ease, visibility 0s;
    visibility: visible;
  }
  .responsive-menu ul li.back-to, .responsive-menu.menu li.back-to {
    align-items: center;
    background: #f4f1ec;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    gap: 5px;
    padding: 10px 15px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .responsive-menu ul li.back-to, .responsive-menu.menu li.back-to {
    gap: 0.2777777778vw;
    padding: 0.5555555556vw 0.8333333333vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .responsive-menu ul li.back-to, .responsive-menu.menu li.back-to {
    gap: 0.3472222222vw;
    padding: 0.6944444444vw 1.0416666667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .responsive-menu ul li.back-to, .responsive-menu.menu li.back-to {
    gap: 0.6510416667vw;
    padding: 1.3020833333vw 1.953125vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .responsive-menu ul li.back-to, .responsive-menu.menu li.back-to {
    gap: 1.3333333333vw;
    padding: 2.6666666667vw 4vw;
  }
}
@media only screen and (width < 768px) {
  .responsive-menu ul li.back-to svg, .responsive-menu.menu li.back-to svg {
    width: 20px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .responsive-menu ul li.back-to svg, .responsive-menu.menu li.back-to svg {
    width: 1.1111111111vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .responsive-menu ul li.back-to svg, .responsive-menu.menu li.back-to svg {
    width: 1.3888888889vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .responsive-menu ul li.back-to svg, .responsive-menu.menu li.back-to svg {
    width: 2.6041666667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .responsive-menu ul li.back-to svg, .responsive-menu.menu li.back-to svg {
    width: 5.3333333333vw;
  }
}
@media only screen and (width < 768px) {
  .responsive-menu ul li.back-to svg path, .responsive-menu.menu li.back-to svg path {
    fill: #333;
  }
  .responsive-menu .main-items li.back-to {
    padding: 15px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .responsive-menu .main-items li.back-to {
    padding: 0.8333333333vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .responsive-menu .main-items li.back-to {
    padding: 1.0416666667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .responsive-menu .main-items li.back-to {
    padding: 1.953125vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .responsive-menu .main-items li.back-to {
    padding: 4vw;
  }
}
@media only screen and (width < 768px) {
  .responsive-menu .mega-menu__content .title {
    border-bottom: 1px solid #f4f1ec;
    margin: 0;
    padding: 15px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .responsive-menu .mega-menu__content .title {
    padding: 0.8333333333vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .responsive-menu .mega-menu__content .title {
    padding: 1.0416666667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .responsive-menu .mega-menu__content .title {
    padding: 1.953125vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .responsive-menu .mega-menu__content .title {
    padding: 4vw;
  }
}
@media only screen and (width < 768px) {
  .responsive-menu .mega-menu__content li {
    padding: 0;
  }
  .responsive-menu .mega-menu__content li a {
    display: block;
    padding: 10px 15px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .responsive-menu .mega-menu__content li a {
    padding: 0.5555555556vw 0.8333333333vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .responsive-menu .mega-menu__content li a {
    padding: 0.6944444444vw 1.0416666667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .responsive-menu .mega-menu__content li a {
    padding: 1.3020833333vw 1.953125vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .responsive-menu .mega-menu__content li a {
    padding: 2.6666666667vw 4vw;
  }
}
.core-footer {
  position: relative;
  z-index: 12;
  background: #f4f1ec;
  padding-top: 50px;
}
@media (max-width: 1799.98px) {
  .core-footer {
    padding-top: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer {
    padding-top: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer {
    padding-top: 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer {
    padding-top: 13.3333333333vw;
  }
}
.core-footer h4, .core-footer p, .core-footer a {
  color: #656057;
}
.core-footer h4 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}
@media (max-width: 1799.98px) {
  .core-footer h4 {
    font-size: 1.1111111111vw;
    line-height: 1.3333333333vw;
    margin-bottom: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer h4 {
    font-size: 1.3888888889vw;
    line-height: 1.6666666667vw;
    margin-bottom: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer h4 {
    font-size: 2.34375vw;
    line-height: 2.8645833333vw;
    margin-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer h4 {
    font-size: 4.8vw;
    line-height: 5.8666666667vw;
    margin-bottom: 4vw;
  }
}
.core-footer p, .core-footer a {
  font-size: 15px;
}
@media (max-width: 1799.98px) {
  .core-footer p, .core-footer a {
    font-size: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer p, .core-footer a {
    font-size: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer p, .core-footer a {
    font-size: 1.8229166667vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer p, .core-footer a {
    font-size: 3.7333333333vw;
  }
}
.core-footer .underline {
  text-decoration: underline;
}
.core-footer .map {
  height: auto;
  width: 22px;
}
@media (max-width: 1799.98px) {
  .core-footer .map {
    width: 1.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer .map {
    width: 1.5277777778vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer .map {
    width: 2.8645833333vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer .map {
    width: 5.8666666667vw;
  }
}
.core-footer .map path {
  fill: #656057;
}
.core-footer ul {
  margin: 0;
  padding: 0 0 0 20px;
}
@media (max-width: 1799.98px) {
  .core-footer ul {
    padding: 0 0 0 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer ul {
    padding: 0 0 0 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer ul {
    padding: 0 0 0 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer ul {
    padding: 0 0 0 5.3333333333vw;
  }
}
.core-footer ul li {
  margin-bottom: 5px;
}
@media (max-width: 1799.98px) {
  .core-footer ul li {
    margin-bottom: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer ul li {
    margin-bottom: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer ul li {
    margin-bottom: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer ul li {
    margin-bottom: 1.3333333333vw;
  }
}
.core-footer .bovag {
  height: auto;
  width: 100px;
}
@media (max-width: 1799.98px) {
  .core-footer .bovag {
    width: 5.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer .bovag {
    width: 6.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer .bovag {
    width: 13.0208333333vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer .bovag {
    width: 26.6666666667vw;
  }
}
.core-footer .price-information {
  margin-top: 20px;
}
@media (max-width: 1799.98px) {
  .core-footer .price-information {
    margin-top: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer .price-information {
    margin-top: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer .price-information {
    margin-top: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer .price-information {
    margin-top: 5.3333333333vw;
  }
}
.core-footer .price-information p {
  font-size: 10px;
}
@media (max-width: 1799.98px) {
  .core-footer .price-information p {
    font-size: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer .price-information p {
    font-size: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer .price-information p {
    font-size: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer .price-information p {
    font-size: 2.6666666667vw;
  }
}
.core-footer .bottom-bar {
  background: #fff;
  margin-top: 40px;
  padding-bottom: 15px;
  padding-top: 15px;
}
@media (max-width: 1799.98px) {
  .core-footer .bottom-bar {
    margin-top: 2.2222222222vw;
    padding-bottom: 0.8333333333vw;
    padding-top: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer .bottom-bar {
    margin-top: 2.7777777778vw;
    padding-bottom: 1.0416666667vw;
    padding-top: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer .bottom-bar {
    margin-top: 5.2083333333vw;
    padding-bottom: 1.953125vw;
    padding-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer .bottom-bar {
    margin-top: 10.6666666667vw;
    padding-bottom: 4vw;
    padding-top: 4vw;
  }
}
.core-footer .bottom-bar p, .core-footer .bottom-bar a, .core-footer .bottom-bar span {
  color: #ACA8A1;
  font-size: 12px;
}
@media (max-width: 1799.98px) {
  .core-footer .bottom-bar p, .core-footer .bottom-bar a, .core-footer .bottom-bar span {
    font-size: 0.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer .bottom-bar p, .core-footer .bottom-bar a, .core-footer .bottom-bar span {
    font-size: 0.8333333333vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer .bottom-bar p, .core-footer .bottom-bar a, .core-footer .bottom-bar span {
    font-size: 1.5625vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer .bottom-bar p, .core-footer .bottom-bar a, .core-footer .bottom-bar span {
    font-size: 3.2vw;
  }
}
.core-footer .bottom-bar p {
  margin-bottom: 0;
}
@media only screen and (width < 768px) {
  .core-footer .bottom-bar .documents {
    text-align: center;
    margin-top: 10px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .core-footer .bottom-bar .documents {
    margin-top: 0.5555555556vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .core-footer .bottom-bar .documents {
    margin-top: 0.6944444444vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .core-footer .bottom-bar .documents {
    margin-top: 1.3020833333vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .core-footer .bottom-bar .documents {
    margin-top: 2.6666666667vw;
  }
}
@media only screen and (width < 768px) {
  .core-footer .bottom-bar .documents a, .core-footer .bottom-bar .documents span {
    font-size: 8px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .core-footer .bottom-bar .documents a, .core-footer .bottom-bar .documents span {
    font-size: 0.4444444444vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .core-footer .bottom-bar .documents a, .core-footer .bottom-bar .documents span {
    font-size: 0.5555555556vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .core-footer .bottom-bar .documents a, .core-footer .bottom-bar .documents span {
    font-size: 1.0416666667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .core-footer .bottom-bar .documents a, .core-footer .bottom-bar .documents span {
    font-size: 2.1333333333vw;
  }
}
@media only screen and (width < 768px) {
  .core-footer .bottom-bar .documents .sep {
    margin: 0 5px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .core-footer .bottom-bar .documents .sep {
    margin: 0 0.2777777778vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .core-footer .bottom-bar .documents .sep {
    margin: 0 0.3472222222vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .core-footer .bottom-bar .documents .sep {
    margin: 0 0.6510416667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .core-footer .bottom-bar .documents .sep {
    margin: 0 1.3333333333vw;
  }
}
.core-footer .sep {
  margin: 0 10px;
}
@media (max-width: 1799.98px) {
  .core-footer .sep {
    margin: 0 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .core-footer .sep {
    margin: 0 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .core-footer .sep {
    margin: 0 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .core-footer .sep {
    margin: 0 2.6666666667vw;
  }
}

.top-bar {
  background: #f4f1ec;
  position: relative;
  z-index: 4;
  padding: 12px 0;
}
@media (max-width: 1799.98px) {
  .top-bar {
    padding: 0.6666666667vw 0;
  }
}
@media (max-width: 991.98px) {
  .top-bar {
    padding: 0.8333333333vw 0;
  }
}
@media (max-width: 767.98px) {
  .top-bar {
    padding: 1.5625vw 0;
  }
}
@media (max-width: 575.98px) {
  .top-bar {
    padding: 3.2vw 0;
  }
}
@media only screen and (width < 768px) {
  .top-bar {
    display: none;
  }
}
.top-bar p, .top-bar a {
  font-size: 14px;
}
@media (max-width: 1799.98px) {
  .top-bar p, .top-bar a {
    font-size: 0.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .top-bar p, .top-bar a {
    font-size: 0.9722222222vw;
  }
}
@media (max-width: 767.98px) {
  .top-bar p, .top-bar a {
    font-size: 1.5625vw;
  }
}
@media (max-width: 575.98px) {
  .top-bar p, .top-bar a {
    font-size: 3.2vw;
  }
}
.top-bar a:not(.website) {
  align-items: center;
  color: #333;
  display: flex;
  text-decoration: none;
  font-size: 14px;
  gap: 5px;
}
@media (max-width: 1799.98px) {
  .top-bar a:not(.website) {
    font-size: 0.7777777778vw;
    gap: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .top-bar a:not(.website) {
    font-size: 0.9722222222vw;
    gap: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .top-bar a:not(.website) {
    font-size: 1.5625vw;
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .top-bar a:not(.website) {
    font-size: 3.2vw;
    gap: 1.3333333333vw;
  }
}
.top-bar .website {
  color: inherit;
}
.top-bar .links {
  display: flex;
  gap: 20px;
}
@media (max-width: 1799.98px) {
  .top-bar .links {
    gap: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .top-bar .links {
    gap: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .top-bar .links {
    gap: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .top-bar .links {
    gap: 5.3333333333vw;
  }
}

.alert-bar {
  background: var(--primary);
  color: #fff;
  position: relative;
  z-index: 4;
  padding: 10px 0;
}
@media (max-width: 1799.98px) {
  .alert-bar {
    padding: 0.5555555556vw 0;
  }
}
@media (max-width: 991.98px) {
  .alert-bar {
    padding: 0.6944444444vw 0;
  }
}
@media (max-width: 767.98px) {
  .alert-bar {
    padding: 1.3020833333vw 0;
  }
}
@media (max-width: 575.98px) {
  .alert-bar {
    padding: 2.6666666667vw 0;
  }
}
.alert-bar p {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
}
@media (max-width: 1799.98px) {
  .alert-bar p {
    font-size: 0.8333333333vw;
    line-height: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .alert-bar p {
    font-size: 1.0416666667vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .alert-bar p {
    font-size: 1.3020833333vw;
    line-height: 2.4739583333vw;
  }
}
@media (max-width: 575.98px) {
  .alert-bar p {
    font-size: 2.6666666667vw;
    line-height: 5.0666666667vw;
  }
}
@media only screen and (width < 768px) {
  .alert-bar--header {
    display: none;
  }
}
.alert-bar--footer {
  background: var(--secondary);
  position: relative;
  z-index: 12;
}

.border-block {
  background: #fff;
  border: 1px solid #f4f1ec;
  box-shadow: 0 2px 3px 0 rgba(103, 122, 135, 0.16);
  border-radius: 10px;
  padding: 25px;
}
@media (max-width: 1799.98px) {
  .border-block {
    border-radius: 0.5555555556vw;
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .border-block {
    border-radius: 0.6944444444vw;
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .border-block {
    border-radius: 1.3020833333vw;
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .border-block {
    border-radius: 2.6666666667vw;
    padding: 4vw;
  }
}
.border-block.sticky {
  position: sticky;
  top: 15px;
}
@media (max-width: 1799.98px) {
  .border-block.sticky {
    top: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .border-block.sticky {
    top: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .border-block.sticky {
    top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .border-block.sticky {
    top: 4vw;
  }
}
.border-block .header {
  border-bottom: 1px solid #f4f1ec;
  color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 10px;
}
@media (max-width: 1799.98px) {
  .border-block .header {
    margin-bottom: 0.8333333333vw;
    padding-bottom: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .border-block .header {
    margin-bottom: 1.0416666667vw;
    padding-bottom: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .border-block .header {
    margin-bottom: 1.953125vw;
    padding-bottom: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .border-block .header {
    margin-bottom: 4vw;
    padding-bottom: 2.6666666667vw;
  }
}
.border-block .header h3 {
  margin: 0;
}
.border-block .header svg {
  height: auto;
  width: 25px;
}
@media (max-width: 1799.98px) {
  .border-block .header svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .border-block .header svg {
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .border-block .header svg {
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .border-block .header svg {
    width: 6.6666666667vw;
  }
}
.border-block .header svg path {
  fill: var(--secondary);
}
.border-block.account {
  box-shadow: unset;
  border: none;
  background: #fcfcfc;
  padding: 35px;
}
@media (max-width: 1799.98px) {
  .border-block.account {
    padding: 1.9444444444vw;
  }
}
@media (max-width: 991.98px) {
  .border-block.account {
    padding: 2.4305555556vw;
  }
}
@media (max-width: 767.98px) {
  .border-block.account {
    padding: 0;
  }
}
@media (max-width: 575.98px) {
  .border-block.account {
    padding: 0;
  }
}
@media only screen and (width < 600px) {
  .border-block.account {
    background: transparent;
    margin-top: 35px;
  }
}
.border-block.account .btn {
  padding: 10px 45px;
}
@media (max-width: 1799.98px) {
  .border-block.account .btn {
    padding: 0.5555555556vw 2.5vw;
  }
}
@media (max-width: 991.98px) {
  .border-block.account .btn {
    padding: 0.6944444444vw 3.125vw;
  }
}
@media (max-width: 767.98px) {
  .border-block.account .btn {
    padding: 1.3020833333vw 5.859375vw;
  }
}
@media (max-width: 575.98px) {
  .border-block.account .btn {
    padding: 2.6666666667vw 12vw;
  }
}
.border-block.account .btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.border-block.account.icon {
  text-align: center;
  transition: all 0.3s;
  border: 1px solid #f4f1ec;
  box-shadow: 0 2px 3px 0 rgba(103, 122, 135, 0.16);
  cursor: pointer;
  display: block;
}
.border-block.account.icon .title {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 1799.98px) {
  .border-block.account.icon .title {
    font-size: 1.1111111111vw;
    line-height: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .border-block.account.icon .title {
    font-size: 1.3888888889vw;
    line-height: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .border-block.account.icon .title {
    font-size: 2.6041666667vw;
    line-height: 3.6458333333vw;
  }
}
@media (max-width: 575.98px) {
  .border-block.account.icon .title {
    font-size: 5.3333333333vw;
    line-height: 7.4666666667vw;
  }
}
.border-block.account.icon svg {
  height: auto;
  margin-bottom: 10px;
  width: 50px;
}
@media (max-width: 1799.98px) {
  .border-block.account.icon svg {
    margin-bottom: 0.5555555556vw;
    width: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .border-block.account.icon svg {
    margin-bottom: 0.6944444444vw;
    width: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .border-block.account.icon svg {
    margin-bottom: 1.3020833333vw;
    width: 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .border-block.account.icon svg {
    margin-bottom: 2.6666666667vw;
    width: 13.3333333333vw;
  }
}
.border-block.account.icon svg path {
  fill: var(--primary);
}
.border-block.account.icon:hover {
  transform: translateY(-5px) scale(1.02);
}
.border-block.address {
  height: 100%;
  position: relative;
  padding-bottom: 65px;
}
@media (max-width: 1799.98px) {
  .border-block.address {
    padding-bottom: 3.6111111111vw;
  }
}
@media (max-width: 991.98px) {
  .border-block.address {
    padding-bottom: 4.5138888889vw;
  }
}
@media (max-width: 767.98px) {
  .border-block.address {
    padding-bottom: 8.4635416667vw;
  }
}
@media (max-width: 575.98px) {
  .border-block.address {
    padding-bottom: 17.3333333333vw;
  }
}
.border-block.address.add {
  background: var(--primary);
  color: #fff;
  display: flex;
  font-weight: 700;
  height: unset;
  justify-content: center;
  font-size: 20px;
  gap: 10px;
  padding: 25px;
}
@media (max-width: 1799.98px) {
  .border-block.address.add {
    font-size: 1.1111111111vw;
    gap: 0.5555555556vw;
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .border-block.address.add {
    font-size: 1.3888888889vw;
    gap: 0.6944444444vw;
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .border-block.address.add {
    font-size: 2.0833333333vw;
    gap: 1.3020833333vw;
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .border-block.address.add {
    font-size: 4.2666666667vw;
    gap: 2.6666666667vw;
    padding: 4vw;
  }
}
.border-block.address.add svg {
  height: auto;
  width: 25px;
}
@media (max-width: 1799.98px) {
  .border-block.address.add svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .border-block.address.add svg {
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .border-block.address.add svg {
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .border-block.address.add svg {
    width: 6.6666666667vw;
  }
}
.border-block.address.add svg path {
  fill: #fff;
}
.border-block .actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 15px;
}
@media (max-width: 1799.98px) {
  .border-block .actions {
    gap: 0.5555555556vw;
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .border-block .actions {
    gap: 0.6944444444vw;
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .border-block .actions {
    gap: 1.3020833333vw;
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .border-block .actions {
    gap: 2.6666666667vw;
    padding: 4vw;
  }
}
.border-block .actions.order {
  position: relative;
  padding: 0;
}
.border-block .actions .button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  width: 43px;
  height: 43px;
  border-radius: 10px;
}
@media (max-width: 1799.98px) {
  .border-block .actions .button {
    width: 2.3888888889vw;
    height: 2.3888888889vw;
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .border-block .actions .button {
    width: 2.9861111111vw;
    height: 2.9861111111vw;
    border-radius: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .border-block .actions .button {
    width: 5.5989583333vw;
    height: 5.5989583333vw;
    border-radius: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .border-block .actions .button {
    width: 11.4666666667vw;
    height: 11.4666666667vw;
    border-radius: 2.6666666667vw;
  }
}
.border-block .actions .button * {
  transition: all 0.3s;
}
.border-block .actions .button svg {
  height: auto;
  width: 20px;
}
@media (max-width: 1799.98px) {
  .border-block .actions .button svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .border-block .actions .button svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .border-block .actions .button svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .border-block .actions .button svg {
    width: 5.3333333333vw;
  }
}
.border-block .actions .button.edit, .border-block .actions .button.view {
  background: var(--secondary);
}
.border-block .actions .button.edit svg path, .border-block .actions .button.view svg path {
  fill: #fff;
}
.border-block .actions .button.edit:hover, .border-block .actions .button.view:hover {
  background: var(--primary);
}
.border-block .actions .button.delete {
  background: #f4f1ec;
  border: none;
  outline: none;
  color: #333;
}
.border-block .actions .button.delete:hover {
  background: #ff4f4f;
}
.border-block .actions .button.delete:hover svg path {
  fill: #fff;
}

.overview-block {
  border-bottom: 1px dashed #b9b9b9;
  padding-bottom: 15px;
  padding-top: 15px;
}
@media (max-width: 1799.98px) {
  .overview-block {
    padding-bottom: 0.8333333333vw;
    padding-top: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .overview-block {
    padding-bottom: 1.0416666667vw;
    padding-top: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .overview-block {
    padding-bottom: 1.953125vw;
    padding-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .overview-block {
    padding-bottom: 4vw;
    padding-top: 4vw;
  }
}
.overview-block.normal-border {
  border-bottom: 1px solid #f4f1ec;
}
.overview-block .coupon {
  display: flex;
}
.overview-block .coupon input {
  border: 1px solid #f4f1ec;
  outline: none;
  width: 100%;
  border-radius: 5px 0 0 5px;
  padding: 8px 10px;
}
@media (max-width: 1799.98px) {
  .overview-block .coupon input {
    border-radius: 0.2777777778vw 0 0 0.2777777778vw;
    padding: 0.4444444444vw 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .overview-block .coupon input {
    border-radius: 0.3472222222vw 0 0 0.3472222222vw;
    padding: 0.5555555556vw 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .overview-block .coupon input {
    border-radius: 0.6510416667vw 0 0 0.6510416667vw;
    padding: 1.0416666667vw 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .overview-block .coupon input {
    border-radius: 1.3333333333vw 0 0 1.3333333333vw;
    padding: 2.1333333333vw 2.6666666667vw;
  }
}
.overview-block .coupon button {
  border-radius: 0 5px 5px 0;
}
@media (max-width: 1799.98px) {
  .overview-block .coupon button {
    border-radius: 0 0.2777777778vw 0.2777777778vw 0;
  }
}
@media (max-width: 991.98px) {
  .overview-block .coupon button {
    border-radius: 0 0.3472222222vw 0.3472222222vw 0;
  }
}
@media (max-width: 767.98px) {
  .overview-block .coupon button {
    border-radius: 0 0.6510416667vw 0.6510416667vw 0;
  }
}
@media (max-width: 575.98px) {
  .overview-block .coupon button {
    border-radius: 0 1.3333333333vw 1.3333333333vw 0;
  }
}
.overview-block .small {
  color: var(--secondary);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
@media (max-width: 1799.98px) {
  .overview-block .small {
    font-size: 0.7777777778vw;
    line-height: 1.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .overview-block .small {
    font-size: 0.9722222222vw;
    line-height: 1.5277777778vw;
  }
}
@media (max-width: 767.98px) {
  .overview-block .small {
    font-size: 1.8229166667vw;
    line-height: 2.8645833333vw;
  }
}
@media (max-width: 575.98px) {
  .overview-block .small {
    font-size: 3.7333333333vw;
    line-height: 5.8666666667vw;
  }
}

@media only screen and (width < 768px) {
  .border-block {
    background: none;
    border: 0;
    box-shadow: none;
    padding: 0;
  }
  .border-block.account.icon, .border-block.address {
    background: #fff;
    padding: 25px;
    border: 1px solid #f4f1ec;
  }
  .border-block.account.icon.address-block, .border-block.address.address-block {
    margin-top: 15px;
  }
  .border-block.order {
    background: #fff;
    padding: 15px;
    border: 1px solid #f4f1ec;
  }
}
hr.offwhite {
  border-width: 1px;
  border-style: solid;
  border-color: #f4f1ec;
}

.header--home {
  position: relative;
  padding-top: 90px;
}
@media (max-width: 1799.98px) {
  .header--home {
    padding-top: 5vw;
  }
}
@media (max-width: 991.98px) {
  .header--home {
    padding-top: 6.25vw;
  }
}
@media (max-width: 767.98px) {
  .header--home {
    padding-top: 0;
  }
}
@media (max-width: 575.98px) {
  .header--home {
    padding-top: 0;
  }
}
@media only screen and (width < 768px) {
  .header--home .container {
    max-width: 100%;
  }
  .header--home form {
    max-width: 95vw;
    margin: 0 auto;
  }
}
.header--home .shape {
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  top: -90px;
}
@media only screen and (width > 1900px) {
  .header--home .shape {
    top: -300px;
  }
}
@media only screen and (width > 2300px) {
  .header--home .shape {
    top: -500px;
  }
}
@media only screen and (width < 768px) {
  .header--home .shape {
    top: 150px;
    width: 110%;
  }
}
@media only screen and (width < 600px) {
  .header--home .shape {
    top: 273px;
  }
}
@media (max-width: 1799.98px) {
  .header--home .shape {
    top: -5vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width > 1900px) {
  .header--home .shape {
    top: -16.6666666667vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width > 2300px) {
  .header--home .shape {
    top: -27.7777777778vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width < 768px) {
  .header--home .shape {
    top: 8.3333333333vw;
    width: 110%;
  }
}
@media only screen and (max-width: 1799.98px) and (width < 600px) {
  .header--home .shape {
    top: 15.1666666667vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .shape {
    top: -6.25vw;
  }
}
@media only screen and (max-width: 991.98px) and (width > 1900px) {
  .header--home .shape {
    top: -20.8333333333vw;
  }
}
@media only screen and (max-width: 991.98px) and (width > 2300px) {
  .header--home .shape {
    top: -34.7222222222vw;
  }
}
@media only screen and (max-width: 991.98px) and (width < 768px) {
  .header--home .shape {
    top: 10.4166666667vw;
    width: 110%;
  }
}
@media only screen and (max-width: 991.98px) and (width < 600px) {
  .header--home .shape {
    top: 18.9583333333vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .shape {
    top: 0;
  }
}
@media only screen and (max-width: 767.98px) and (width > 1900px) {
  .header--home .shape {
    top: -39.0625vw;
  }
}
@media only screen and (max-width: 767.98px) and (width > 2300px) {
  .header--home .shape {
    top: -65.1041666667vw;
  }
}
@media only screen and (max-width: 767.98px) and (width < 768px) {
  .header--home .shape {
    top: 19.53125vw;
    width: 110%;
  }
}
@media only screen and (max-width: 767.98px) and (width < 600px) {
  .header--home .shape {
    top: 35.546875vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .shape {
    top: 0;
  }
}
@media only screen and (max-width: 575.98px) and (width > 1900px) {
  .header--home .shape {
    top: -80vw;
  }
}
@media only screen and (max-width: 575.98px) and (width > 2300px) {
  .header--home .shape {
    top: -133.3333333333vw;
  }
}
@media only screen and (max-width: 575.98px) and (width < 768px) {
  .header--home .shape {
    top: 40vw;
    width: 110%;
  }
}
@media only screen and (max-width: 575.98px) and (width < 600px) {
  .header--home .shape {
    top: 72.8vw;
  }
}
.header--home .shape--box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}
@media only screen and (width < 768px) {
  .header--home .slider-box {
    padding: 0;
  }
}
@media only screen and (width < 768px) {
  .header--home form {
    margin-top: 25px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .header--home form {
    margin-top: 1.3888888889vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .header--home form {
    margin-top: 1.7361111111vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .header--home form {
    margin-top: 3.2552083333vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .header--home form {
    margin-top: 6.6666666667vw;
  }
}
.header--home form p {
  color: #fff;
}
.header--home form .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
@media (max-width: 1799.98px) {
  .header--home form .title {
    font-size: 1.3333333333vw;
    line-height: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .header--home form .title {
    font-size: 1.6666666667vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .header--home form .title {
    font-size: 3.125vw;
    line-height: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  .header--home form .title {
    font-size: 6.4vw;
    line-height: 6.4vw;
  }
}
.header--home .btn--orange {
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  outline: none;
  gap: 10px;
  padding: 16px;
}
@media (max-width: 1799.98px) {
  .header--home .btn--orange {
    gap: 0.5555555556vw;
    padding: 0.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .btn--orange {
    gap: 0.6944444444vw;
    padding: 1.1111111111vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .btn--orange {
    gap: 1.3020833333vw;
    padding: 2.0833333333vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .btn--orange {
    gap: 2.6666666667vw;
    padding: 4.2666666667vw;
  }
}
.header--home .btn--orange.small {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 56px;
  height: 65px;
}
@media (max-width: 1799.98px) {
  .header--home .btn--orange.small {
    margin-left: 0.5555555556vw;
    width: 3.1111111111vw;
    height: 3.6111111111vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .btn--orange.small {
    margin-left: 0.6944444444vw;
    width: 3.8888888889vw;
    height: 4.5138888889vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .btn--orange.small {
    margin-left: 1.3020833333vw;
    width: 7.2916666667vw;
    height: 8.4635416667vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .btn--orange.small {
    margin-left: 2.6666666667vw;
    width: 14.9333333333vw;
    height: 17.3333333333vw;
  }
}
.header--home .kenteken {
  position: relative;
  background: #f3bd00;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 3px;
}
@media (max-width: 1799.98px) {
  .header--home .kenteken {
    padding: 0.1666666667vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .kenteken {
    padding: 0.2083333333vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .kenteken {
    padding: 0.390625vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .kenteken {
    padding: 0.8vw;
  }
}
.header--home .kenteken .nl {
  background: #003399;
  border-right: 1px solid #333;
  padding: 2px 0;
}
@media (max-width: 1799.98px) {
  .header--home .kenteken .nl {
    padding: 0.1111111111vw 0;
  }
}
@media (max-width: 991.98px) {
  .header--home .kenteken .nl {
    padding: 0.1388888889vw 0;
  }
}
@media (max-width: 767.98px) {
  .header--home .kenteken .nl {
    padding: 0.2604166667vw 0;
  }
}
@media (max-width: 575.98px) {
  .header--home .kenteken .nl {
    padding: 0.5333333333vw 0;
  }
}
.header--home .kenteken .nl img {
  height: auto;
  object-fit: contain;
  height: 58px;
  width: 50px;
}
@media (max-width: 1799.98px) {
  .header--home .kenteken .nl img {
    height: 3.2222222222vw;
    width: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .kenteken .nl img {
    height: 4.0277777778vw;
    width: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .kenteken .nl img {
    height: 7.5520833333vw;
    width: 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .kenteken .nl img {
    height: 15.4666666667vw;
    width: 13.3333333333vw;
  }
}
.header--home .kenteken input {
  background: 0;
  border: 0;
  font-family: "Kenteken", sans-serif;
  margin: 0;
  outline: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 38px;
  letter-spacing: 3px;
  line-height: 38px;
  padding: 5px 0 0;
  width: calc(100% - 70px);
}
@media (max-width: 1799.98px) {
  .header--home .kenteken input {
    font-size: 2.1111111111vw;
    letter-spacing: 0.1666666667vw;
    line-height: 2.1111111111vw;
    padding: 0.2777777778vw 0 0;
    width: calc(100% - 3.8888888889vw);
  }
}
@media (max-width: 991.98px) {
  .header--home .kenteken input {
    font-size: 2.6388888889vw;
    letter-spacing: 0.2083333333vw;
    line-height: 2.6388888889vw;
    padding: 0.3472222222vw 0 0;
    width: calc(100% - 4.8611111111vw);
  }
}
@media (max-width: 767.98px) {
  .header--home .kenteken input {
    font-size: 4.9479166667vw;
    letter-spacing: 0.390625vw;
    line-height: 4.9479166667vw;
    padding: 0.6510416667vw 0 0;
    width: calc(100% - 9.1145833333vw);
  }
}
@media (max-width: 575.98px) {
  .header--home .kenteken input {
    font-size: 10.1333333333vw;
    letter-spacing: 0.8vw;
    line-height: 10.1333333333vw;
    padding: 1.3333333333vw 0 0;
    width: calc(100% - 18.6666666667vw);
  }
}
.header--home .kenteken .input {
  text-align: center;
}
.header--home .select-box {
  position: relative;
  margin-bottom: 15px;
}
@media (max-width: 1799.98px) {
  .header--home .select-box {
    margin-bottom: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .select-box {
    margin-bottom: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .select-box {
    margin-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .select-box {
    margin-bottom: 4vw;
  }
}
.header--home .select-box select {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header--home .select-box .ts-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header--home .select-box .ts-wrapper .ts-control {
  background-color: transparent;
  border: 0;
  height: 100%;
  border-radius: 10px;
  padding: 0 10px 0 50px;
}
@media (max-width: 1799.98px) {
  .header--home .select-box .ts-wrapper .ts-control {
    border-radius: 0.5555555556vw;
    padding: 0 0.5555555556vw 0 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .select-box .ts-wrapper .ts-control {
    border-radius: 0.6944444444vw;
    padding: 0 0.6944444444vw 0 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .select-box .ts-wrapper .ts-control {
    border-radius: 1.3020833333vw;
    padding: 0 1.3020833333vw 0 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .select-box .ts-wrapper .ts-control {
    border-radius: 2.6666666667vw;
    padding: 0 2.6666666667vw 0 13.3333333333vw;
  }
}
.header--home .select-box .ts-wrapper .ts-control .item {
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
  padding-right: 15px !important;
  font-size: 16px;
}
@media (max-width: 1799.98px) {
  .header--home .select-box .ts-wrapper .ts-control .item {
    padding-right: 0.8333333333vw !important;
    font-size: 0.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .select-box .ts-wrapper .ts-control .item {
    padding-right: 1.0416666667vw !important;
    font-size: 1.1111111111vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .select-box .ts-wrapper .ts-control .item {
    padding-right: 1.953125vw !important;
    font-size: 2.0833333333vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .select-box .ts-wrapper .ts-control .item {
    padding-right: 4vw !important;
    font-size: 4.2666666667vw;
  }
}
.header--home .select-box .ts-wrapper .ts-control input {
  color: #333;
  font-size: 16px;
}
@media (max-width: 1799.98px) {
  .header--home .select-box .ts-wrapper .ts-control input {
    font-size: 0.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .select-box .ts-wrapper .ts-control input {
    font-size: 1.1111111111vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .select-box .ts-wrapper .ts-control input {
    font-size: 2.0833333333vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .select-box .ts-wrapper .ts-control input {
    font-size: 4.2666666667vw;
  }
}
.header--home .select-box .ts-wrapper .ts-dropdown .option {
  font-size: 16px;
}
@media (max-width: 1799.98px) {
  .header--home .select-box .ts-wrapper .ts-dropdown .option {
    font-size: 0.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .select-box .ts-wrapper .ts-dropdown .option {
    font-size: 1.1111111111vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .select-box .ts-wrapper .ts-dropdown .option {
    font-size: 2.0833333333vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .select-box .ts-wrapper .ts-dropdown .option {
    font-size: 4.2666666667vw;
  }
}
.header--home .select-box label {
  align-items: center;
  background: #fff;
  display: flex;
  margin: 0;
  position: relative;
  border-radius: 10px;
  gap: 10px;
  padding: 15px 10px;
}
@media (max-width: 1799.98px) {
  .header--home .select-box label {
    border-radius: 0.5555555556vw;
    gap: 0.5555555556vw;
    padding: 0.8333333333vw 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .select-box label {
    border-radius: 0.6944444444vw;
    gap: 0.6944444444vw;
    padding: 1.0416666667vw 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .select-box label {
    border-radius: 1.3020833333vw;
    gap: 1.3020833333vw;
    padding: 1.953125vw 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .select-box label {
    border-radius: 2.6666666667vw;
    gap: 2.6666666667vw;
    padding: 4vw 2.6666666667vw;
  }
}
.header--home .select-box label .number {
  align-items: center;
  background: var(--primary);
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  font-size: 16px;
  height: 30px;
  width: 30px;
}
@media (max-width: 1799.98px) {
  .header--home .select-box label .number {
    font-size: 0.8888888889vw;
    height: 1.6666666667vw;
    width: 1.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .select-box label .number {
    font-size: 1.1111111111vw;
    height: 2.0833333333vw;
    width: 2.0833333333vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .select-box label .number {
    font-size: 2.0833333333vw;
    height: 3.90625vw;
    width: 3.90625vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .select-box label .number {
    font-size: 4.2666666667vw;
    height: 8vw;
    width: 8vw;
  }
}
.header--home .select-box.disabled {
  cursor: not-allowed;
}
.header--home .select-box.disabled label .number {
  background: #f4f1ec;
  color: #333;
}
.header--home .select-box.disabled .ts-control {
  opacity: 1 !important;
}
.header--home .select-box .ts-dropdown {
  margin-top: 0;
}
.header--home .select-box .ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
  background-color: #ff4f4f;
  background-image: url("../images/icons/close.svg");
  border: 0;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 0;
  line-height: 0;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 10px;
}
@media (max-width: 1799.98px) {
  .header--home .select-box .ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
    width: 1.1111111111vw;
    height: 1.1111111111vw;
    background-size: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .header--home .select-box .ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
    width: 1.3888888889vw;
    height: 1.3888888889vw;
    background-size: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .header--home .select-box .ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
    width: 2.6041666667vw;
    height: 2.6041666667vw;
    background-size: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .header--home .select-box .ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
    width: 5.3333333333vw;
    height: 5.3333333333vw;
    background-size: 2.6666666667vw;
  }
}
.header--home .select-box.open label {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #fff;
}
.header--content {
  position: relative;
  padding-bottom: 50px;
  padding-top: 90px;
}
@media (max-width: 1799.98px) {
  .header--content {
    padding-bottom: 2.7777777778vw;
    padding-top: 5vw;
  }
}
@media (max-width: 991.98px) {
  .header--content {
    padding-bottom: 3.4722222222vw;
    padding-top: 6.25vw;
  }
}
@media (max-width: 767.98px) {
  .header--content {
    padding-bottom: 5.859375vw;
    padding-top: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .header--content {
    padding-bottom: 12vw;
    padding-top: 6.6666666667vw;
  }
}
.header--content--news {
  padding-top: 25px;
  padding-bottom: 25px;
}
@media (max-width: 1799.98px) {
  .header--content--news {
    padding-top: 1.3888888889vw;
    padding-bottom: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .header--content--news {
    padding-top: 1.7361111111vw;
    padding-bottom: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .header--content--news {
    padding-top: 3.2552083333vw;
    padding-bottom: 0;
  }
}
@media (max-width: 575.98px) {
  .header--content--news {
    padding-top: 6.6666666667vw;
    padding-bottom: 0;
  }
}
.header--content--news img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  height: 500px;
}
@media (max-width: 1799.98px) {
  .header--content--news img {
    border-radius: 0.5555555556vw;
    height: 27.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .header--content--news img {
    border-radius: 0.6944444444vw;
    height: 34.7222222222vw;
  }
}
@media (max-width: 767.98px) {
  .header--content--news img {
    border-radius: 1.3020833333vw;
    height: 32.5520833333vw;
  }
}
@media (max-width: 575.98px) {
  .header--content--news img {
    border-radius: 2.6666666667vw;
    height: 66.6666666667vw;
  }
}
.header--content h1 {
  color: #fff;
  margin-bottom: 25px;
}
@media (max-width: 1799.98px) {
  .header--content h1 {
    margin-bottom: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .header--content h1 {
    margin-bottom: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .header--content h1 {
    margin-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .header--content h1 {
    margin-bottom: 6.6666666667vw;
  }
}
@media only screen and (width < 600px) {
  .header--content .top-text {
    min-height: 115px;
  }
}
.header--content .shape {
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  top: -90px;
}
@media only screen and (width > 1900px) {
  .header--content .shape {
    top: -300px;
  }
}
@media only screen and (width > 2300px) {
  .header--content .shape {
    top: -400px;
  }
}
@media only screen and (width < 600px) {
  .header--content .shape {
    width: 145%;
  }
}
@media (max-width: 1799.98px) {
  .header--content .shape {
    top: -5vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width > 1900px) {
  .header--content .shape {
    top: -16.6666666667vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width > 2300px) {
  .header--content .shape {
    top: -22.2222222222vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width < 600px) {
  .header--content .shape {
    width: 145%;
  }
}
@media (max-width: 991.98px) {
  .header--content .shape {
    top: -6.25vw;
  }
}
@media only screen and (max-width: 991.98px) and (width > 1900px) {
  .header--content .shape {
    top: -20.8333333333vw;
  }
}
@media only screen and (max-width: 991.98px) and (width > 2300px) {
  .header--content .shape {
    top: -27.7777777778vw;
  }
}
@media only screen and (max-width: 991.98px) and (width < 600px) {
  .header--content .shape {
    width: 145%;
  }
}
@media (max-width: 767.98px) {
  .header--content .shape {
    top: 0;
  }
}
@media only screen and (max-width: 767.98px) and (width > 1900px) {
  .header--content .shape {
    top: -39.0625vw;
  }
}
@media only screen and (max-width: 767.98px) and (width > 2300px) {
  .header--content .shape {
    top: -52.0833333333vw;
  }
}
@media only screen and (max-width: 767.98px) and (width < 600px) {
  .header--content .shape {
    width: 145%;
  }
}
@media (max-width: 575.98px) {
  .header--content .shape {
    top: 0;
  }
}
@media only screen and (max-width: 575.98px) and (width > 1900px) {
  .header--content .shape {
    top: -80vw;
  }
}
@media only screen and (max-width: 575.98px) and (width > 2300px) {
  .header--content .shape {
    top: -106.6666666667vw;
  }
}
@media only screen and (max-width: 575.98px) and (width < 600px) {
  .header--content .shape {
    width: 145%;
  }
}
.header--shop {
  position: relative;
  padding-bottom: 50px;
  padding-top: 50px;
}
@media (max-width: 1799.98px) {
  .header--shop {
    padding-bottom: 2.7777777778vw;
    padding-top: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .header--shop {
    padding-bottom: 3.4722222222vw;
    padding-top: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .header--shop {
    padding-bottom: 1.953125vw;
    padding-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .header--shop {
    padding-bottom: 4vw;
    padding-top: 4vw;
  }
}
.header--shop.account .shape {
  top: -275px;
}
@media only screen and (width > 1900px) {
  .header--shop.account .shape {
    top: -325px;
  }
}
@media only screen and (width > 2300px) {
  .header--shop.account .shape {
    top: -450px;
  }
}
@media only screen and (width < 600px) {
  .header--shop.account .shape {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 200%;
  }
}
@media (max-width: 1799.98px) {
  .header--shop.account .shape {
    top: -15.2777777778vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width > 1900px) {
  .header--shop.account .shape {
    top: -18.0555555556vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width > 2300px) {
  .header--shop.account .shape {
    top: -25vw;
  }
}
@media only screen and (max-width: 1799.98px) and (width < 600px) {
  .header--shop.account .shape {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 200%;
  }
}
@media (max-width: 991.98px) {
  .header--shop.account .shape {
    top: -19.0972222222vw;
  }
}
@media only screen and (max-width: 991.98px) and (width > 1900px) {
  .header--shop.account .shape {
    top: -22.5694444444vw;
  }
}
@media only screen and (max-width: 991.98px) and (width > 2300px) {
  .header--shop.account .shape {
    top: -31.25vw;
  }
}
@media only screen and (max-width: 991.98px) and (width < 600px) {
  .header--shop.account .shape {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 200%;
  }
}
@media (max-width: 767.98px) {
  .header--shop.account .shape {
    top: -35.8072916667vw;
  }
}
@media only screen and (max-width: 767.98px) and (width > 1900px) {
  .header--shop.account .shape {
    top: -42.3177083333vw;
  }
}
@media only screen and (max-width: 767.98px) and (width > 2300px) {
  .header--shop.account .shape {
    top: -58.59375vw;
  }
}
@media only screen and (max-width: 767.98px) and (width < 600px) {
  .header--shop.account .shape {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 200%;
  }
}
@media (max-width: 575.98px) {
  .header--shop.account .shape {
    top: -73.3333333333vw;
  }
}
@media only screen and (max-width: 575.98px) and (width > 1900px) {
  .header--shop.account .shape {
    top: -86.6666666667vw;
  }
}
@media only screen and (max-width: 575.98px) and (width > 2300px) {
  .header--shop.account .shape {
    top: -120vw;
  }
}
@media only screen and (max-width: 575.98px) and (width < 600px) {
  .header--shop.account .shape {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 200%;
  }
}
.header--shop.account h1 {
  font-size: 40px;
  line-height: 48px;
}
@media (max-width: 1799.98px) {
  .header--shop.account h1 {
    font-size: 2.2222222222vw;
    line-height: 2.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .header--shop.account h1 {
    font-size: 2.7777777778vw;
    line-height: 3.3333333333vw;
  }
}
@media (max-width: 767.98px) {
  .header--shop.account h1 {
    font-size: 5.2083333333vw;
    line-height: 6.25vw;
  }
}
@media (max-width: 575.98px) {
  .header--shop.account h1 {
    font-size: 10.6666666667vw;
    line-height: 12.8vw;
  }
}
.header--shop .shape {
  height: auto;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  top: -50px;
}
@media (max-width: 1799.98px) {
  .header--shop .shape {
    top: -2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .header--shop .shape {
    top: -3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .header--shop .shape {
    top: -6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .header--shop .shape {
    top: -13.3333333333vw;
  }
}
@media only screen and (width < 768px) {
  .header--shop .shape {
    width: 120%;
    top: 0;
  }
}
@media only screen and (width < 600px) {
  .header--shop .shape {
    width: 200%;
  }
}
.header--shop h1 {
  color: #fff;
  margin: 0;
  font-size: 30px;
  line-height: 38px;
}
@media (max-width: 1799.98px) {
  .header--shop h1 {
    font-size: 1.6666666667vw;
    line-height: 2.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .header--shop h1 {
    font-size: 2.0833333333vw;
    line-height: 2.6388888889vw;
  }
}
@media (max-width: 767.98px) {
  .header--shop h1 {
    font-size: 3.125vw;
    line-height: 4.4270833333vw;
  }
}
@media (max-width: 575.98px) {
  .header--shop h1 {
    font-size: 6.4vw;
    line-height: 9.0666666667vw;
  }
}
.header--shop .logo {
  object-fit: contain;
  height: 150px;
  width: 150px;
}
@media (max-width: 1799.98px) {
  .header--shop .logo {
    height: 8.3333333333vw;
    width: 8.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .header--shop .logo {
    height: 10.4166666667vw;
    width: 10.4166666667vw;
  }
}
@media (max-width: 767.98px) {
  .header--shop .logo {
    height: 13.0208333333vw;
    width: 13.0208333333vw;
  }
}
@media (max-width: 575.98px) {
  .header--shop .logo {
    height: 26.6666666667vw;
    width: 26.6666666667vw;
  }
}
.header--shop .logo--box {
  align-items: center;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100px;
  margin: 0 auto 15px;
  padding: 15px;
  width: 100px;
}
@media (max-width: 1799.98px) {
  .header--shop .logo--box {
    height: 5.5555555556vw;
    margin: 0 auto 0.8333333333vw;
    padding: 0.8333333333vw;
    width: 5.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .header--shop .logo--box {
    height: 6.9444444444vw;
    margin: 0 auto 1.0416666667vw;
    padding: 1.0416666667vw;
    width: 6.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .header--shop .logo--box {
    height: 10.4166666667vw;
    margin: 0 auto 1.953125vw;
    padding: 1.953125vw;
    width: 10.4166666667vw;
  }
}
@media (max-width: 575.98px) {
  .header--shop .logo--box {
    height: 21.3333333333vw;
    margin: 0 auto 4vw;
    padding: 4vw;
    width: 21.3333333333vw;
  }
}
@media only screen and (width < 768px) {
  .header--shop .logo--box {
    display: none;
  }
}
.header--checkout {
  padding-top: 35px;
}
@media (max-width: 1799.98px) {
  .header--checkout {
    padding-top: 1.9444444444vw;
  }
}
@media (max-width: 991.98px) {
  .header--checkout {
    padding-top: 2.4305555556vw;
  }
}
@media (max-width: 767.98px) {
  .header--checkout {
    padding-top: 4.5572916667vw;
  }
}
@media (max-width: 575.98px) {
  .header--checkout {
    padding-top: 9.3333333333vw;
  }
}
.header--checkout--account {
  padding-bottom: 75px;
}
@media (max-width: 1799.98px) {
  .header--checkout--account {
    padding-bottom: 4.1666666667vw;
  }
}
@media (max-width: 991.98px) {
  .header--checkout--account {
    padding-bottom: 5.2083333333vw;
  }
}
@media (max-width: 767.98px) {
  .header--checkout--account {
    padding-bottom: 9.765625vw;
  }
}
@media (max-width: 575.98px) {
  .header--checkout--account {
    padding-bottom: 20vw;
  }
}
.header--standard {
  background: linear-gradient(147deg, #034382 8.88%, #032342 80.34%);
  padding-bottom: 25px;
  padding-top: 25px;
}
@media (max-width: 1799.98px) {
  .header--standard {
    padding-bottom: 1.3888888889vw;
    padding-top: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .header--standard {
    padding-bottom: 1.7361111111vw;
    padding-top: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .header--standard {
    padding-bottom: 1.953125vw;
    padding-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .header--standard {
    padding-bottom: 4vw;
    padding-top: 4vw;
  }
}
.header--standard h1 {
  color: #fff;
  margin: 0;
}

.shape__box {
  max-width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.header-slider .slide {
  position: relative;
  border-radius: 25px;
  height: 400px;
  overflow: hidden;
}
@media (max-width: 1799.98px) {
  .header-slider .slide {
    border-radius: 1.3888888889vw;
    height: 22.2222222222vw;
    overflow: hidden;
  }
}
@media (max-width: 991.98px) {
  .header-slider .slide {
    border-radius: 1.7361111111vw;
    height: 27.7777777778vw;
    overflow: hidden;
  }
}
@media (max-width: 767.98px) {
  .header-slider .slide {
    border-radius: 0;
    height: 35.8072916667vw;
    overflow: hidden;
  }
}
@media (max-width: 575.98px) {
  .header-slider .slide {
    border-radius: 0;
    height: 73.3333333333vw;
    overflow: hidden;
  }
}
.header-slider .slide::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.header-slider .slide img {
  object-fit: cover;
  transition: all 0.3s;
  width: 100%;
  height: 400px;
}
@media (max-width: 1799.98px) {
  .header-slider .slide img {
    height: 22.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .header-slider .slide img {
    height: 27.7777777778vw;
  }
}
@media (max-width: 767.98px) {
  .header-slider .slide img {
    height: 35.8072916667vw;
  }
}
@media (max-width: 575.98px) {
  .header-slider .slide img {
    height: 73.3333333333vw;
  }
}
.header-slider .slide .block {
  align-items: center;
  background: rgba(0, 0, 0, 0.34);
  bottom: 0;
  color: #fff;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 3;
  gap: 35px;
  padding: 25px;
}
@media (max-width: 1799.98px) {
  .header-slider .slide .block {
    gap: 1.9444444444vw;
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .header-slider .slide .block {
    gap: 2.4305555556vw;
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .header-slider .slide .block {
    gap: 3.2552083333vw;
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .header-slider .slide .block {
    gap: 6.6666666667vw;
    padding: 4vw;
  }
}
.header-slider .slide .title p {
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 10px;
}
@media (max-width: 1799.98px) {
  .header-slider .slide .title p {
    font-size: 1.5555555556vw;
    line-height: 2vw;
    margin-bottom: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .header-slider .slide .title p {
    font-size: 1.9444444444vw;
    line-height: 2.5vw;
    margin-bottom: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .header-slider .slide .title p {
    font-size: 2.34375vw;
    line-height: 3.3854166667vw;
    margin-bottom: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .header-slider .slide .title p {
    font-size: 4.8vw;
    line-height: 6.9333333333vw;
    margin-bottom: 1.3333333333vw;
  }
}
.header-slider .slide .subtitle {
  font-weight: 300;
  margin: 0;
  font-size: 20px;
  line-height: 24px;
}
@media (max-width: 1799.98px) {
  .header-slider .slide .subtitle {
    font-size: 1.1111111111vw;
    line-height: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .header-slider .slide .subtitle {
    font-size: 1.3888888889vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .header-slider .slide .subtitle {
    font-size: 1.8229166667vw;
    line-height: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .header-slider .slide .subtitle {
    font-size: 3.7333333333vw;
    line-height: 5.3333333333vw;
  }
}
.header-slider .slide .icon {
  height: 48px;
  width: 48px;
}
@media (max-width: 1799.98px) {
  .header-slider .slide .icon {
    height: 2.6666666667vw;
    width: 2.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .header-slider .slide .icon {
    height: 3.3333333333vw;
    width: 3.3333333333vw;
  }
}
@media (max-width: 767.98px) {
  .header-slider .slide .icon {
    height: 4.9479166667vw;
    width: 4.9479166667vw;
  }
}
@media (max-width: 575.98px) {
  .header-slider .slide .icon {
    height: 10.1333333333vw;
    width: 10.1333333333vw;
  }
}
.header-slider .slide:hover img {
  transform: scale(1.1);
}

.logo-slider {
  position: relative;
  padding-bottom: 25px;
  padding-top: 25px;
}
@media (max-width: 1799.98px) {
  .logo-slider {
    padding-bottom: 1.3888888889vw;
    padding-top: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .logo-slider {
    padding-bottom: 1.7361111111vw;
    padding-top: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .logo-slider {
    padding-bottom: 3.2552083333vw;
    padding-top: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .logo-slider {
    padding-bottom: 6.6666666667vw;
    padding-top: 6.6666666667vw;
  }
}
.logo-slider .slick-slide a {
  display: flex;
  justify-content: center;
}
.logo-slider .slick-slide img {
  filter: grayscale(100%);
  object-fit: contain;
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
  height: 160px;
  width: 200px;
}
@media (max-width: 1799.98px) {
  .logo-slider .slick-slide img {
    height: 8.8888888889vw;
    width: 11.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .logo-slider .slick-slide img {
    height: 11.1111111111vw;
    width: 13.8888888889vw;
  }
}
@media (max-width: 767.98px) {
  .logo-slider .slick-slide img {
    height: 10.4166666667vw;
    width: 13.0208333333vw;
  }
}
@media (max-width: 575.98px) {
  .logo-slider .slick-slide img {
    height: 21.3333333333vw;
    width: 26.6666666667vw;
  }
}
.logo-slider .slick-slide img:hover {
  filter: grayscale(0);
  opacity: 1;
}
.logo-slider .slick-arrow {
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  z-index: 2;
  height: 40px;
  width: 40px;
}
@media (max-width: 1799.98px) {
  .logo-slider .slick-arrow {
    height: 2.2222222222vw;
    width: 2.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .logo-slider .slick-arrow {
    height: 2.7777777778vw;
    width: 2.7777777778vw;
  }
}
@media (max-width: 767.98px) {
  .logo-slider .slick-arrow {
    height: 5.2083333333vw;
    width: 5.2083333333vw;
  }
}
@media (max-width: 575.98px) {
  .logo-slider .slick-arrow {
    height: 10.6666666667vw;
    width: 10.6666666667vw;
  }
}
.logo-slider .slick-arrow svg {
  height: auto;
  width: 25px;
}
@media (max-width: 1799.98px) {
  .logo-slider .slick-arrow svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .logo-slider .slick-arrow svg {
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .logo-slider .slick-arrow svg {
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .logo-slider .slick-arrow svg {
    width: 6.6666666667vw;
  }
}
.logo-slider .slick-arrow.prev {
  left: -15px;
}
@media (max-width: 1799.98px) {
  .logo-slider .slick-arrow.prev {
    left: -0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .logo-slider .slick-arrow.prev {
    left: -1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .logo-slider .slick-arrow.prev {
    left: 0;
  }
}
@media (max-width: 575.98px) {
  .logo-slider .slick-arrow.prev {
    left: 0;
  }
}
.logo-slider .slick-arrow.next {
  right: -15px;
}
@media (max-width: 1799.98px) {
  .logo-slider .slick-arrow.next {
    right: -0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .logo-slider .slick-arrow.next {
    right: -1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .logo-slider .slick-arrow.next {
    right: 0;
  }
}
@media (max-width: 575.98px) {
  .logo-slider .slick-arrow.next {
    right: 0;
  }
}
.logo-slider .slick-arrow:hover {
  background: var(--secondary);
}
.logo-slider .slick-arrow:hover svg path {
  fill: #fff;
}
.logo-slider svg path {
  fill: #333;
  transition: all 0.3s;
}

.product-slider {
  position: relative;
}
.product-slider .slick-list {
  width: 100%;
}
@media only screen and (width < 768px) {
  .product-slider .slick-list {
    padding: 0 35% !important;
    margin: 0 -5px;
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .product-slider .slick-list {
    margin: 0 -0.2777777778vw;
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .product-slider .slick-list {
    margin: 0 -0.3472222222vw;
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .product-slider .slick-list {
    margin: 0 -0.6510416667vw;
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .product-slider .slick-list {
    margin: 0 -1.3333333333vw;
  }
}
@media only screen and (width < 600px) {
  .product-slider .slick-list {
    padding: 0 25% !important;
  }
}
@media only screen and (width < 768px) {
  .product-slider .slick-list .slick-slide {
    margin: 0;
    opacity: 0.5;
  }
  .product-slider .slick-list .slick-slide.slick-active {
    opacity: 1;
  }
  .product-slider .slick-list .slick-slide.slick-active > div::before {
    display: none;
  }
}
.product-slider .slick-list .slick-slide .col-12 {
  padding: 0 10px;
}
@media (max-width: 1799.98px) {
  .product-slider .slick-list .slick-slide .col-12 {
    padding: 0 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .product-slider .slick-list .slick-slide .col-12 {
    padding: 0 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .product-slider .slick-list .slick-slide .col-12 {
    padding: 0 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .product-slider .slick-list .slick-slide .col-12 {
    padding: 0 2.6666666667vw;
  }
}
@media only screen and (width < 768px) {
  .product-slider .slick-list .slick-slide > div {
    position: relative;
  }
  .product-slider .slick-list .slick-slide > div::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}
.product-slider .slick-arrow {
  align-items: center;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  filter: drop-shadow(0 2px 3px rgba(103, 122, 135, 0.16));
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  z-index: 2;
  height: 48px;
  width: 48px;
}
@media (max-width: 1799.98px) {
  .product-slider .slick-arrow {
    height: 2.6666666667vw;
    width: 2.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .product-slider .slick-arrow {
    height: 3.3333333333vw;
    width: 3.3333333333vw;
  }
}
@media (max-width: 767.98px) {
  .product-slider .slick-arrow {
    height: 4.9479166667vw;
    width: 4.9479166667vw;
  }
}
@media (max-width: 575.98px) {
  .product-slider .slick-arrow {
    height: 10.1333333333vw;
    width: 10.1333333333vw;
  }
}
.product-slider .slick-arrow:hover {
  background: var(--secondary);
}
.product-slider .slick-arrow:hover svg path {
  fill: #fff;
}
.product-slider .slick-arrow svg {
  height: auto;
  width: 24px;
}
@media (max-width: 1799.98px) {
  .product-slider .slick-arrow svg {
    width: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .product-slider .slick-arrow svg {
    width: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .product-slider .slick-arrow svg {
    width: 2.34375vw;
  }
}
@media (max-width: 575.98px) {
  .product-slider .slick-arrow svg {
    width: 4.8vw;
  }
}
.product-slider .slick-arrow.prev {
  left: -65px;
}
@media (max-width: 1799.98px) {
  .product-slider .slick-arrow.prev {
    left: -3.6111111111vw;
  }
}
@media (max-width: 991.98px) {
  .product-slider .slick-arrow.prev {
    left: -4.5138888889vw;
  }
}
@media (max-width: 767.98px) {
  .product-slider .slick-arrow.prev {
    left: -1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .product-slider .slick-arrow.prev {
    left: -2.6666666667vw;
  }
}
.product-slider .slick-arrow.next {
  right: -65px;
}
@media (max-width: 1799.98px) {
  .product-slider .slick-arrow.next {
    right: -3.6111111111vw;
  }
}
@media (max-width: 991.98px) {
  .product-slider .slick-arrow.next {
    right: -4.5138888889vw;
  }
}
@media (max-width: 767.98px) {
  .product-slider .slick-arrow.next {
    right: -1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .product-slider .slick-arrow.next {
    right: -2.6666666667vw;
  }
}

.select-box label {
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 1799.98px) {
  .select-box label {
    font-size: 1.1111111111vw;
    line-height: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .select-box label {
    font-size: 1.3888888889vw;
    line-height: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .select-box label {
    font-size: 2.6041666667vw;
    line-height: 3.6458333333vw;
  }
}
@media (max-width: 575.98px) {
  .select-box label {
    font-size: 5.3333333333vw;
    line-height: 7.4666666667vw;
  }
}
.select-box select {
  appearance: none;
  background-color: #f4f1ec;
  background-image: url("/assets/images/icons/chevron-down.svg");
  background-repeat: no-repeat;
  border: 1px solid #e5e5e4;
  width: 100%;
  background-position: center right 15px;
  background-size: 25px;
  border-radius: 10px;
  font-size: 17px;
  line-height: 25px;
  padding: 10px 35px 10px 15px;
}
@media (max-width: 1799.98px) {
  .select-box select {
    background-position: center right 0.8333333333vw;
    background-size: 1.3888888889vw;
    border-radius: 0.5555555556vw;
    font-size: 0.9444444444vw;
    line-height: 1.3888888889vw;
    padding: 0.5555555556vw 1.9444444444vw 0.5555555556vw 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .select-box select {
    background-position: center right 1.0416666667vw;
    background-size: 1.7361111111vw;
    border-radius: 0.6944444444vw;
    font-size: 1.1805555556vw;
    line-height: 1.7361111111vw;
    padding: 0.6944444444vw 2.4305555556vw 0.6944444444vw 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .select-box select {
    background-position: center right 1.953125vw;
    background-size: 3.2552083333vw;
    border-radius: 1.3020833333vw;
    font-size: 2.2135416667vw;
    line-height: 3.2552083333vw;
    padding: 1.3020833333vw 4.5572916667vw 1.3020833333vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .select-box select {
    background-position: center right 4vw;
    background-size: 6.6666666667vw;
    border-radius: 2.6666666667vw;
    font-size: 4.5333333333vw;
    line-height: 6.6666666667vw;
    padding: 2.6666666667vw 9.3333333333vw 2.6666666667vw 4vw;
  }
}
.select-box select:disabled {
  background-image: url("/assets/images/icons/chevron-down-disabled.svg");
}

.variations .select-box {
  margin-bottom: 15px;
}
@media (max-width: 1799.98px) {
  .variations .select-box {
    margin-bottom: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .variations .select-box {
    margin-bottom: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .variations .select-box {
    margin-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .variations .select-box {
    margin-bottom: 4vw;
  }
}

form.account label small {
  color: #a8a8a8;
}
form.account input:not([type=checkbox], [type=password]), form.account select {
  background: #fff;
  border: 1px solid #e5e5e4;
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
}
@media (max-width: 1799.98px) {
  form.account input:not([type=checkbox], [type=password]), form.account select {
    padding: 0.5555555556vw 0.8333333333vw;
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  form.account input:not([type=checkbox], [type=password]), form.account select {
    padding: 0.6944444444vw 1.0416666667vw;
    border-radius: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  form.account input:not([type=checkbox], [type=password]), form.account select {
    padding: 1.3020833333vw 1.953125vw;
    border-radius: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  form.account input:not([type=checkbox], [type=password]), form.account select {
    padding: 2.6666666667vw 4vw;
    border-radius: 2.6666666667vw;
  }
}
form.account input:not([type=checkbox], [type=password]) ~ label, form.account select ~ label {
  display: block;
}
form.account input:disabled, form.account input:read-only {
  background: #f4f1ec;
  color: rgba(51, 51, 51, 0.8);
  cursor: not-allowed;
  outline: none;
}
form.account select {
  appearance: none;
  background-image: url("/assets/images/icons/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: center right 15px;
  background-size: 25px;
}
@media (max-width: 1799.98px) {
  form.account select {
    background-position: center right 0.8333333333vw;
    background-size: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  form.account select {
    background-position: center right 1.0416666667vw;
    background-size: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  form.account select {
    background-position: center right 1.953125vw;
    background-size: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  form.account select {
    background-position: center right 4vw;
    background-size: 6.6666666667vw;
  }
}
form.account .option--box label {
  display: flex !important;
}
form.account .address--box {
  background: var(--secondary);
  color: #fff;
  font-weight: 700;
  padding: 15px;
  border-radius: 10px;
  margin: 10px 0 15px 0;
}
@media (max-width: 1799.98px) {
  form.account .address--box {
    padding: 0.8333333333vw;
    border-radius: 0.5555555556vw;
    margin: 0.5555555556vw 0 0.8333333333vw 0;
  }
}
@media (max-width: 991.98px) {
  form.account .address--box {
    padding: 1.0416666667vw;
    border-radius: 0.6944444444vw;
    margin: 0.6944444444vw 0 1.0416666667vw 0;
  }
}
@media (max-width: 767.98px) {
  form.account .address--box {
    padding: 1.953125vw;
    border-radius: 1.3020833333vw;
    margin: 1.3020833333vw 0 1.953125vw 0;
  }
}
@media (max-width: 575.98px) {
  form.account .address--box {
    padding: 4vw;
    border-radius: 2.6666666667vw;
    margin: 2.6666666667vw 0 4vw 0;
  }
}
form.account .address--box.error {
  background: #f4f1ec;
  color: #333;
}

.required {
  color: #ff4f4f;
}

.search-address-field--box {
  position: relative;
}
.search-address-field--box input {
  outline: none;
}
.search-address-field--box .autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 2;
  box-shadow: 0 0.5em 0.75em rgba(0, 0, 0, 0.15);
  background-color: #fff;
  display: none;
  overflow: hidden;
  height: auto;
  max-height: 500px;
}
@media (max-width: 1799.98px) {
  .search-address-field--box .autocomplete-results {
    max-height: 27.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .search-address-field--box .autocomplete-results {
    max-height: 34.7222222222vw;
  }
}
@media (max-width: 767.98px) {
  .search-address-field--box .autocomplete-results {
    max-height: 65.1041666667vw;
  }
}
@media (max-width: 575.98px) {
  .search-address-field--box .autocomplete-results {
    max-height: 133.3333333333vw;
  }
}
.search-address-field--box .autocomplete-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.search-address-field--box .autocomplete-results .autocomplete-item {
  padding: 0.4em 1.2em 0.4em 0.6em;
  margin: 0;
  line-height: 1.1;
  list-style: none;
  cursor: pointer;
}
.search-address-field--box .autocomplete-results .autocomplete-item mark {
  background: transparent;
  color: #333;
  font-weight: 700;
}
.search-address-field--box .autocomplete-results .autocomplete-item__label {
  display: inline-block;
  margin-right: 0.4em;
}
.search-address-field--box .autocomplete-results .autocomplete-item--more {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0ibTUgMTQgNi02LTYtNiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNzc3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K);
  background-position: center right 0.25em;
  background-repeat: no-repeat;
  background-size: 0.8em;
}
.search-address-field--box .autocomplete-results .autocomplete-item--no-results {
  cursor: default;
}
.search-address-field--box .autocomplete-results .autocomplete-item--focus, .search-address-field--box .autocomplete-results .autocomplete-item:hover {
  background-color: #f0f0f0;
}
.search-address-field--box.active .autocomplete-results {
  display: block;
}
.search-address-field--box.active input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.checkbox-box input {
  display: none;
}
.checkbox-box input:checked + label .checkbox {
  background: var(--secondary);
  border-color: var(--secondary);
}
.checkbox-box input:checked + label .checkbox svg path {
  fill: #fff;
}
.checkbox-box label {
  width: 100%;
  position: relative;
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}
@media (max-width: 1799.98px) {
  .checkbox-box label {
    gap: 0.4444444444vw;
  }
}
@media (max-width: 991.98px) {
  .checkbox-box label {
    gap: 0.5555555556vw;
  }
}
@media (max-width: 767.98px) {
  .checkbox-box label {
    gap: 1.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  .checkbox-box label {
    gap: 2.1333333333vw;
  }
}
.checkbox-box label .checkbox {
  background: #f4f1ec;
  border: 2px solid #e8e8e8;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 20px;
  width: 20px;
  margin-top: -2px;
}
@media (max-width: 1799.98px) {
  .checkbox-box label .checkbox {
    border-radius: 0.2777777778vw;
    height: 1.1111111111vw;
    width: 1.1111111111vw;
    margin-top: -0.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .checkbox-box label .checkbox {
    border-radius: 0.3472222222vw;
    height: 1.3888888889vw;
    width: 1.3888888889vw;
    margin-top: -0.1388888889vw;
  }
}
@media (max-width: 767.98px) {
  .checkbox-box label .checkbox {
    border-radius: 0.6510416667vw;
    height: 2.6041666667vw;
    width: 2.6041666667vw;
    margin-top: -0.2604166667vw;
  }
}
@media (max-width: 575.98px) {
  .checkbox-box label .checkbox {
    border-radius: 1.3333333333vw;
    height: 5.3333333333vw;
    width: 5.3333333333vw;
    margin-top: -0.5333333333vw;
  }
}
.checkbox-box label .checkbox svg {
  height: auto;
  width: 10px;
}
@media (max-width: 1799.98px) {
  .checkbox-box label .checkbox svg {
    width: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .checkbox-box label .checkbox svg {
    width: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .checkbox-box label .checkbox svg {
    width: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .checkbox-box label .checkbox svg {
    width: 2.6666666667vw;
  }
}
.checkbox-box label .checkbox svg path {
  fill: #f4f1ec;
}

.password-toggle {
  position: relative;
}
.password-toggle input {
  background: #fff;
  border: 1px solid #e5e5e4;
  width: 100%;
  border-radius: 10px;
  padding: 10px 45px 10px 15px;
}
@media (max-width: 1799.98px) {
  .password-toggle input {
    border-radius: 0.5555555556vw;
    padding: 0.5555555556vw 2.5vw 0.5555555556vw 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .password-toggle input {
    border-radius: 0.6944444444vw;
    padding: 0.6944444444vw 3.125vw 0.6944444444vw 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .password-toggle input {
    border-radius: 1.3020833333vw;
    padding: 1.3020833333vw 5.859375vw 1.3020833333vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .password-toggle input {
    border-radius: 2.6666666667vw;
    padding: 2.6666666667vw 12vw 2.6666666667vw 4vw;
  }
}
.password-toggle input ~ label {
  display: block;
}
.password-toggle button {
  all: unset;
  align-items: center;
  background: #f4f1ec;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  right: 10px;
}
@media (max-width: 1799.98px) {
  .password-toggle button {
    height: 1.6666666667vw;
    width: 1.6666666667vw;
    right: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .password-toggle button {
    height: 2.0833333333vw;
    width: 2.0833333333vw;
    right: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .password-toggle button {
    height: 3.90625vw;
    width: 3.90625vw;
    right: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .password-toggle button {
    height: 8vw;
    width: 8vw;
    right: 2.6666666667vw;
  }
}
.password-toggle button svg {
  width: 20px;
}
@media (max-width: 1799.98px) {
  .password-toggle button svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .password-toggle button svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .password-toggle button svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .password-toggle button svg {
    width: 5.3333333333vw;
  }
}

.form label, .form input, .form select {
  font-family: "Inter", sans-serif;
}
.form input, .form textarea {
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 1799.98px) {
  .form input, .form textarea {
    border-radius: 0.5555555556vw;
    padding-top: 0.5555555556vw;
    padding-bottom: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .form input, .form textarea {
    border-radius: 0.6944444444vw;
    padding-top: 0.6944444444vw;
    padding-bottom: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .form input, .form textarea {
    border-radius: 1.3020833333vw;
    padding-top: 1.3020833333vw;
    padding-bottom: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .form input, .form textarea {
    border-radius: 2.6666666667vw;
    padding-top: 2.6666666667vw;
    padding-bottom: 2.6666666667vw;
  }
}
.form .fui-submit {
  background: var(--primary);
  border-color: var(--primary);
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  padding: 10px 35px;
}
@media (max-width: 1799.98px) {
  .form .fui-submit {
    border-radius: 0.5555555556vw;
    font-size: 0.8888888889vw;
    line-height: 1.2222222222vw;
    padding: 0.5555555556vw 1.9444444444vw;
  }
}
@media (max-width: 991.98px) {
  .form .fui-submit {
    border-radius: 0.6944444444vw;
    font-size: 1.1111111111vw;
    line-height: 1.5277777778vw;
    padding: 0.6944444444vw 2.4305555556vw;
  }
}
@media (max-width: 767.98px) {
  .form .fui-submit {
    border-radius: 1.3020833333vw;
    font-size: 1.8229166667vw;
    line-height: 2.6041666667vw;
    padding: 1.3020833333vw 4.5572916667vw;
  }
}
@media (max-width: 575.98px) {
  .form .fui-submit {
    border-radius: 2.6666666667vw;
    font-size: 3.7333333333vw;
    line-height: 5.3333333333vw;
    padding: 2.6666666667vw 9.3333333333vw;
  }
}
.form .fui-checkbox input:checked + label::before {
  background-color: var(--primary);
  border-color: var(--primary);
}
.form .two-columns .fui-layout-wrap {
  display: flex;
  flex-wrap: wrap;
}
.form .two-columns .fui-layout-wrap .fui-checkbox {
  max-width: 50%;
  flex: 0 0 50%;
}

.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 5px;
  margin-top: 0;
}
@media (max-width: 1799.98px) {
  .pagination {
    gap: 0.2777777778vw;
    margin-top: 0;
  }
}
@media (max-width: 991.98px) {
  .pagination {
    gap: 0.3472222222vw;
    margin-top: 0;
  }
}
@media (max-width: 767.98px) {
  .pagination {
    gap: 0.6510416667vw;
    margin-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .pagination {
    gap: 1.3333333333vw;
    margin-top: 4vw;
  }
}
.pagination li button {
  align-items: center;
  border-radius: 50%;
  color: #333;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 0;
  transition: all 0.3s;
  height: 45px;
  width: 45px;
}
@media (max-width: 1799.98px) {
  .pagination li button {
    height: 2.5vw;
    width: 2.5vw;
  }
}
@media (max-width: 991.98px) {
  .pagination li button {
    height: 3.125vw;
    width: 3.125vw;
  }
}
@media (max-width: 767.98px) {
  .pagination li button {
    height: 5.859375vw;
    width: 5.859375vw;
  }
}
@media (max-width: 575.98px) {
  .pagination li button {
    height: 12vw;
    width: 12vw;
  }
}
.pagination li.active button {
  background: var(--secondary);
  color: #fff;
}
.pagination li.disabled span {
  line-height: unset;
  margin-bottom: 4px;
}

table #tuning, table #tuningprijs {
  border-collapse: collapse;
  margin-bottom: 15px;
}
@media (max-width: 1799.98px) {
  table #tuning, table #tuningprijs {
    margin-bottom: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  table #tuning, table #tuningprijs {
    margin-bottom: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  table #tuning, table #tuningprijs {
    margin-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  table #tuning, table #tuningprijs {
    margin-bottom: 4vw;
  }
}
table #tuning td, table #tuning th, table #tuningprijs td, table #tuningprijs th {
  border: 1px solid #ddd;
  padding: 8px;
}
table #tuning tr, table #tuningprijs tr {
  transition: all 0.2s ease-in-out;
}
table #tuning tr:nth-child(2n), table #tuningprijs tr:nth-child(2n) {
  background: #f4f1ec;
}
table #tuning tr:hover, table #tuningprijs tr:hover {
  background-color: var(--primary);
  color: #fff;
}

.table-product {
  width: 100%;
}
.table-product tr:nth-child(2n) {
  background: #f4f1ec;
}
.table-product td, .table-product th {
  padding: 8px;
}
@media (max-width: 1799.98px) {
  .table-product td, .table-product th {
    padding: 0.4444444444vw;
  }
}
@media (max-width: 991.98px) {
  .table-product td, .table-product th {
    padding: 0.5555555556vw;
  }
}
@media (max-width: 767.98px) {
  .table-product td, .table-product th {
    padding: 1.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  .table-product td, .table-product th {
    padding: 2.1333333333vw;
  }
}
.table-product--vehicles td, .table-product--vehicles th {
  border: 1px solid #ddd;
}
.table-product--vehicles tbody tr:hover {
  background: var(--primary);
  color: #fff;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(103, 122, 135, 0.16);
  border: 1px solid #f4f1ec;
  margin-top: 100px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 22;
  border-radius: 10px;
  max-width: 450px;
}
@media (max-width: 1799.98px) {
  .modal {
    border-radius: 0.5555555556vw;
    max-width: 25vw;
  }
}
@media (max-width: 991.98px) {
  .modal {
    border-radius: 0.6944444444vw;
    max-width: 31.25vw;
  }
}
@media (max-width: 767.98px) {
  .modal {
    border-radius: 1.3020833333vw;
    max-width: 95vw;
  }
}
@media (max-width: 575.98px) {
  .modal {
    border-radius: 2.6666666667vw;
    max-width: 95vw;
  }
}
.modal.show {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}
.modal--header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f4f1ec;
  padding: 15px 25px;
}
@media (max-width: 1799.98px) {
  .modal--header {
    padding: 0.8333333333vw 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .modal--header {
    padding: 1.0416666667vw 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .modal--header {
    padding: 1.953125vw 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .modal--header {
    padding: 4vw 6.6666666667vw;
  }
}
.modal--header h3 {
  margin: 0;
}
.modal--header button {
  background: none;
  border: 0;
  outline: none;
}
.modal--body {
  padding: 25px;
}
@media (max-width: 1799.98px) {
  .modal--body {
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .modal--body {
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .modal--body {
    padding: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .modal--body {
    padding: 6.6666666667vw;
  }
}
.modal--body form label {
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}
@media (max-width: 1799.98px) {
  .modal--body form label {
    margin-bottom: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .modal--body form label {
    margin-bottom: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .modal--body form label {
    margin-bottom: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .modal--body form label {
    margin-bottom: 2.6666666667vw;
  }
}
.modal--body form input {
  border: 1px solid #f4f1ec;
  width: 100%;
  padding: 15px 10px;
  border-radius: 10px;
}
@media (max-width: 1799.98px) {
  .modal--body form input {
    padding: 0.8333333333vw 0.5555555556vw;
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .modal--body form input {
    padding: 1.0416666667vw 0.6944444444vw;
    border-radius: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .modal--body form input {
    padding: 1.953125vw 1.3020833333vw;
    border-radius: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .modal--body form input {
    padding: 4vw 2.6666666667vw;
    border-radius: 2.6666666667vw;
  }
}
.modal--body form button {
  display: block;
  width: 100%;
  transition: all 0.3s;
  margin-top: 10px;
  padding: 15px;
}
@media (max-width: 1799.98px) {
  .modal--body form button {
    margin-top: 0.5555555556vw;
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .modal--body form button {
    margin-top: 0.6944444444vw;
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .modal--body form button {
    margin-top: 1.3020833333vw;
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .modal--body form button {
    margin-top: 2.6666666667vw;
    padding: 4vw;
  }
}
.modal--body form button.added {
  background: #236c2a;
}
.modal--body form .error {
  color: #ff4f4f;
  margin: 2px 0 0 0;
}

.alert-box--body {
  display: flex;
  border-radius: 10px;
  gap: 25px;
  padding: 20px;
  margin-top: 15px;
}
@media (max-width: 1799.98px) {
  .alert-box--body {
    border-radius: 0.5555555556vw;
    gap: 1.3888888889vw;
    padding: 1.1111111111vw;
    margin-top: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .alert-box--body {
    border-radius: 0.6944444444vw;
    gap: 1.7361111111vw;
    padding: 1.3888888889vw;
    margin-top: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .alert-box--body {
    border-radius: 1.3020833333vw;
    gap: 3.2552083333vw;
    padding: 2.6041666667vw;
    margin-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .alert-box--body {
    border-radius: 2.6666666667vw;
    gap: 6.6666666667vw;
    padding: 5.3333333333vw;
    margin-top: 4vw;
  }
}
.alert-box.error .alert-box--body {
  background: #ff4f4f;
  color: #fff;
}
.alert-box.error svg path {
  fill: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  background: #fff;
  width: max-content;
  box-shadow: 0 8px 14px -2px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 0;
}
@media (max-width: 1799.98px) {
  .breadcrumb {
    border-radius: 0.5555555556vw;
    padding: 0.5555555556vw 0.8333333333vw;
    margin-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .breadcrumb {
    border-radius: 0.6944444444vw;
    padding: 0.6944444444vw 1.0416666667vw;
    margin-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb {
    border-radius: 1.3020833333vw;
    padding: 1.0416666667vw 1.953125vw;
    margin-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .breadcrumb {
    border-radius: 2.6666666667vw;
    padding: 2.1333333333vw 4vw;
    margin-bottom: 4vw;
  }
}
@media only screen and (width < 768px) {
  .breadcrumb {
    background: unset;
    box-shadow: unset;
    padding: 0;
  }
}
.breadcrumb ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  column-gap: 10px;
}
@media (max-width: 1799.98px) {
  .breadcrumb ul {
    column-gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .breadcrumb ul {
    column-gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb ul {
    column-gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .breadcrumb ul {
    column-gap: 1.3333333333vw;
  }
}
.breadcrumb ul li a {
  color: var(--secondary);
}
@media only screen and (width < 768px) {
  .breadcrumb ul li a {
    color: #fff;
  }
}
.breadcrumb .breadcrumb-box {
  display: flex;
  align-items: center;
}
.breadcrumb .breadcrumb-text {
  font-size: 14px;
  margin-left: 10px;
}
@media (max-width: 1799.98px) {
  .breadcrumb .breadcrumb-text {
    font-size: 0.7777777778vw;
    margin-left: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .breadcrumb .breadcrumb-text {
    font-size: 0.9722222222vw;
    margin-left: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb .breadcrumb-text {
    font-size: 1.5625vw;
    margin-left: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .breadcrumb .breadcrumb-text {
    font-size: 3.2vw;
    margin-left: 1.3333333333vw;
  }
}
.breadcrumb svg {
  height: auto;
  width: 20px;
}
@media (max-width: 1799.98px) {
  .breadcrumb svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .breadcrumb svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .breadcrumb svg {
    width: 2.0833333333vw;
  }
}
@media (max-width: 575.98px) {
  .breadcrumb svg {
    width: 4.2666666667vw;
  }
}
.breadcrumb svg path {
  fill: var(--secondary);
  transition: all 0.3s;
}
@media only screen and (width < 768px) {
  .breadcrumb svg path {
    fill: #fff;
  }
}
.breadcrumb svg:hover:not(.breadcrumb-icon) path {
  fill: var(--primary);
}
@media only screen and (width < 768px) {
  .breadcrumb svg:hover:not(.breadcrumb-icon) path {
    fill: #fff;
  }
}
.breadcrumb a {
  color: #fff;
}
.breadcrumb a:hover {
  color: var(--primary);
  text-decoration: underline;
}
.breadcrumb span {
  color: var(--secondary);
}
@media only screen and (width < 768px) {
  .breadcrumb span {
    color: #fff;
  }
}
.breadcrumb span.current {
  font-weight: 700;
}

form .password-meter input:not([type=checkbox]) {
  background-image: unset;
}
form .password-meter .password {
  position: relative;
}
form .password-meter .password span {
  background: #f4f1ec;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 30px;
  height: 30px;
  right: 15px;
}
@media (max-width: 1799.98px) {
  form .password-meter .password span {
    font-size: 0.7777777778vw;
    width: 1.6666666667vw;
    height: 1.6666666667vw;
    right: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  form .password-meter .password span {
    font-size: 0.9722222222vw;
    width: 2.0833333333vw;
    height: 2.0833333333vw;
    right: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  form .password-meter .password span {
    font-size: 1.8229166667vw;
    width: 3.90625vw;
    height: 3.90625vw;
    right: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  form .password-meter .password span {
    font-size: 3.7333333333vw;
    width: 8vw;
    height: 8vw;
    right: 4vw;
  }
}

.input-container {
  margin-bottom: 10px;
}

.strength-container {
  background: #fff;
  border: 1px solid #e5e5e4;
  border-radius: 10px;
  padding: 15px 25px;
}
@media (max-width: 1799.98px) {
  .strength-container {
    padding: 0.8333333333vw 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .strength-container {
    padding: 1.0416666667vw 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .strength-container {
    padding: 1.953125vw 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .strength-container {
    padding: 4vw 6.6666666667vw;
  }
}

.meter {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0;
}
.meter > div.good {
  background-color: green;
}
.meter > div.almost {
  background-color: orange;
}

.meter div {
  height: 100%;
  transition: width 0.3s;
}

.weak {
  background-color: #ff4f4f;
}

.strong {
  background-color: #8ab951;
}

.criteria-list {
  list-style-type: none;
  padding: 0;
  columns: 2;
}
@media only screen and (width < 768px) {
  .criteria-list {
    columns: 1;
  }
}
.criteria-list li {
  color: #ff4f4f;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}
.criteria-list li.check {
  color: green;
}
@media (max-width: 1799.98px) {
  .criteria-list li {
    font-size: 0.7777777778vw;
    line-height: 1.1111111111vw;
    margin-bottom: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .criteria-list li {
    font-size: 0.9722222222vw;
    line-height: 1.3888888889vw;
    margin-bottom: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .criteria-list li {
    font-size: 1.3020833333vw;
    line-height: 2.0833333333vw;
    margin-bottom: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .criteria-list li {
    font-size: 2.6666666667vw;
    line-height: 4.2666666667vw;
    margin-bottom: 1.3333333333vw;
  }
}

.content.faq {
  padding-bottom: 75px;
}
@media (max-width: 1799.98px) {
  .content.faq {
    padding-bottom: 4.1666666667vw;
  }
}
@media (max-width: 991.98px) {
  .content.faq {
    padding-bottom: 5.2083333333vw;
  }
}
@media (max-width: 767.98px) {
  .content.faq {
    padding-bottom: 9.765625vw;
  }
}
@media (max-width: 575.98px) {
  .content.faq {
    padding-bottom: 20vw;
  }
}
.content.faq .intro p {
  margin: 0;
}

.accordion-list {
  list-style: none;
  padding: 0;
  margin: 0 0 15px 0;
}
@media (max-width: 1799.98px) {
  .accordion-list {
    margin: 0 0 0.8333333333vw 0;
  }
}
@media (max-width: 991.98px) {
  .accordion-list {
    margin: 0 0 1.0416666667vw 0;
  }
}
@media (max-width: 767.98px) {
  .accordion-list {
    margin: 0 0 1.953125vw 0;
  }
}
@media (max-width: 575.98px) {
  .accordion-list {
    margin: 0 0 4vw 0;
  }
}

#search-input {
  background: #f4f1ec;
  border: 1px solid #e5e5e4;
  outline: none;
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  padding: 15px 15px;
}
@media (max-width: 1799.98px) {
  #search-input {
    border-radius: 0.5555555556vw;
    font-size: 1vw;
    padding: 0.8333333333vw 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  #search-input {
    border-radius: 0.6944444444vw;
    font-size: 1.25vw;
    padding: 1.0416666667vw 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  #search-input {
    border-radius: 1.3020833333vw;
    font-size: 2.0833333333vw;
    padding: 1.3020833333vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  #search-input {
    border-radius: 2.6666666667vw;
    font-size: 4.2666666667vw;
    padding: 2.6666666667vw 4vw;
  }
}

.accordion {
  background: #f4f1ec;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
}
.accordion:hover {
  background: rgba(244, 241, 236, 0.8);
}
@media (max-width: 1799.98px) {
  .accordion {
    border-radius: 0.5555555556vw;
    margin-bottom: 0.5555555556vw;
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .accordion {
    border-radius: 0.6944444444vw;
    margin-bottom: 0.6944444444vw;
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .accordion {
    border-radius: 1.3020833333vw;
    margin-bottom: 1.3020833333vw;
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .accordion {
    border-radius: 2.6666666667vw;
    margin-bottom: 2.6666666667vw;
    padding: 4vw;
  }
}
.accordion__header {
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 1799.98px) {
  .accordion__header {
    font-size: 1.1111111111vw;
    line-height: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .accordion__header {
    font-size: 1.3888888889vw;
    line-height: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .accordion__header {
    font-size: 2.0833333333vw;
    line-height: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  .accordion__header {
    font-size: 4.2666666667vw;
    line-height: 6.4vw;
  }
}
.accordion__header svg {
  height: auto;
  transition: all 0.3s;
  width: 25px;
}
@media (max-width: 1799.98px) {
  .accordion__header svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .accordion__header svg {
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .accordion__header svg {
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .accordion__header svg {
    width: 6.6666666667vw;
  }
}
.accordion__body {
  margin-top: 15px;
}
@media (max-width: 1799.98px) {
  .accordion__body {
    margin-top: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .accordion__body {
    margin-top: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .accordion__body {
    margin-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .accordion__body {
    margin-top: 4vw;
  }
}
.accordion.active .accordion__header svg {
  transform: rotate(90deg);
}

.accordion-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 25px 0 35px 0;
}
@media (max-width: 1799.98px) {
  .accordion-buttons {
    gap: 0.5555555556vw;
    margin: 1.3888888889vw 0 1.9444444444vw 0;
  }
}
@media (max-width: 991.98px) {
  .accordion-buttons {
    gap: 0.6944444444vw;
    margin: 1.7361111111vw 0 2.4305555556vw 0;
  }
}
@media (max-width: 767.98px) {
  .accordion-buttons {
    gap: 0.6510416667vw;
    margin: 1.953125vw 0 3.2552083333vw 0;
  }
}
@media (max-width: 575.98px) {
  .accordion-buttons {
    gap: 1.3333333333vw;
    margin: 4vw 0 6.6666666667vw 0;
  }
}
.accordion-buttons button {
  background: transparent;
  border: 2px solid var(--secondary);
  color: var(--secondary);
  transition: all 0.3s;
  font-weight: 600;
  font-size: 18px;
  border-radius: 10px;
  padding: 10px 15px;
}
@media (max-width: 1799.98px) {
  .accordion-buttons button {
    font-size: 1vw;
    border-radius: 0.5555555556vw;
    padding: 0.5555555556vw 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .accordion-buttons button {
    font-size: 1.25vw;
    border-radius: 0.6944444444vw;
    padding: 0.6944444444vw 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .accordion-buttons button {
    font-size: 1.8229166667vw;
    border-radius: 1.3020833333vw;
    padding: 0.6510416667vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .accordion-buttons button {
    font-size: 3.7333333333vw;
    border-radius: 2.6666666667vw;
    padding: 1.3333333333vw 4vw;
  }
}
.accordion-buttons button.active, .accordion-buttons button:hover {
  background: var(--secondary);
  color: #fff;
}

.blog--item .content {
  background: #fff;
  border: 1px solid #f4f1ec;
  box-shadow: rgba(103, 122, 135, 0.16);
  display: flex;
  border-radius: 10px;
  gap: 25px;
  padding: 10px 10px 10px 10px;
}
@media (max-width: 1799.98px) {
  .blog--item .content {
    border-radius: 0.5555555556vw;
    gap: 1.3888888889vw;
    padding: 0.5555555556vw 0.5555555556vw 0.5555555556vw 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .blog--item .content {
    border-radius: 0.6944444444vw;
    gap: 1.7361111111vw;
    padding: 0.6944444444vw 0.6944444444vw 0.6944444444vw 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .blog--item .content {
    border-radius: 1.3020833333vw;
    gap: 3.2552083333vw;
    padding: 1.3020833333vw 1.3020833333vw 4.5572916667vw 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .blog--item .content {
    border-radius: 2.6666666667vw;
    gap: 6.6666666667vw;
    padding: 2.6666666667vw 2.6666666667vw 9.3333333333vw 2.6666666667vw;
  }
}
@media only screen and (width < 768px) {
  .blog--item .content {
    display: block;
  }
}
.blog--item .image-box {
  height: 225px;
  width: 300px;
}
@media (max-width: 1799.98px) {
  .blog--item .image-box {
    height: 12.5vw;
    width: 16.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .blog--item .image-box {
    height: 15.625vw;
    width: 20.8333333333vw;
  }
}
@media (max-width: 767.98px) {
  .blog--item .image-box {
    height: 29.296875vw;
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .blog--item .image-box {
    height: 60vw;
    width: 100%;
  }
}
.blog--item .image-box img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
}
@media (max-width: 1799.98px) {
  .blog--item .image-box img {
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .blog--item .image-box img {
    border-radius: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .blog--item .image-box img {
    border-radius: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .blog--item .image-box img {
    border-radius: 2.6666666667vw;
  }
}
.blog--item .information {
  padding: 25px 35px 15px 0;
}
@media (max-width: 1799.98px) {
  .blog--item .information {
    padding: 1.3888888889vw 1.9444444444vw 0.8333333333vw 0;
  }
}
@media (max-width: 991.98px) {
  .blog--item .information {
    padding: 1.7361111111vw 2.4305555556vw 1.0416666667vw 0;
  }
}
@media (max-width: 767.98px) {
  .blog--item .information {
    padding: 3.2552083333vw 1.953125vw 1.953125vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .blog--item .information {
    padding: 6.6666666667vw 4vw 4vw 4vw;
  }
}
.blog--item .information p {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog--item .information .btn {
  float: right;
}

.product {
  background: #fff;
  border: 1px solid #e5e5e4;
  box-shadow: 0 2px 3px 0 rgba(103, 122, 135, 0.16);
  overflow: hidden;
  transition: all 0.3s;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 10px;
}
@media (max-width: 1799.98px) {
  .product {
    border-radius: 0.5555555556vw;
    margin-bottom: 1.6666666667vw;
    padding: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .product {
    border-radius: 0.6944444444vw;
    margin-bottom: 2.0833333333vw;
    padding: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .product {
    border-radius: 1.3020833333vw;
    margin-bottom: 1.0416666667vw;
    padding: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .product {
    border-radius: 2.6666666667vw;
    margin-bottom: 2.1333333333vw;
    padding: 1.3333333333vw;
  }
}
@media only screen and (width > 768px) {
  .product {
    box-shadow: 0 1px 1.5px 0 rgba(103, 122, 135, 0.16);
  }
}
.product .image-box {
  background: #fff;
  position: relative;
  height: 250px;
  margin-bottom: 20px;
}
@media (max-width: 1799.98px) {
  .product .image-box {
    height: 13.8888888889vw;
    margin-bottom: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .product .image-box {
    height: 17.3611111111vw;
    margin-bottom: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .product .image-box {
    height: 19.53125vw;
    margin-bottom: 1.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  .product .image-box {
    height: 40vw;
    margin-bottom: 2.1333333333vw;
  }
}
.product .image-box .thumbnail {
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  height: 250px;
}
@media (max-width: 1799.98px) {
  .product .image-box .thumbnail {
    border-radius: 0.5555555556vw;
    height: 13.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .product .image-box .thumbnail {
    border-radius: 0.6944444444vw;
    height: 17.3611111111vw;
  }
}
@media (max-width: 767.98px) {
  .product .image-box .thumbnail {
    border-radius: 1.3020833333vw;
    height: 19.53125vw;
  }
}
@media (max-width: 575.98px) {
  .product .image-box .thumbnail {
    border-radius: 2.6666666667vw;
    height: 40vw;
  }
}
.product .image-box .discount {
  background: var(--secondary);
  color: #fff;
  left: 0;
  position: absolute;
  font-weight: 700;
  border-radius: 0 5px 5px 0;
  padding: 5px 10px;
  top: 15px;
  font-size: 14px;
}
@media (max-width: 1799.98px) {
  .product .image-box .discount {
    border-radius: 0 0.2777777778vw 0.2777777778vw 0;
    padding: 0.2777777778vw 0.5555555556vw;
    top: 0.8333333333vw;
    font-size: 0.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .product .image-box .discount {
    border-radius: 0 0.3472222222vw 0.3472222222vw 0;
    padding: 0.3472222222vw 0.6944444444vw;
    top: 1.0416666667vw;
    font-size: 0.9722222222vw;
  }
}
@media (max-width: 767.98px) {
  .product .image-box .discount {
    border-radius: 0 0.6510416667vw 0.6510416667vw 0;
    padding: 0 1.3020833333vw;
    top: 1.3020833333vw;
    font-size: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .product .image-box .discount {
    border-radius: 0 1.3333333333vw 1.3333333333vw 0;
    padding: 0 2.6666666667vw;
    top: 2.6666666667vw;
    font-size: 2.6666666667vw;
  }
}
.product .body {
  padding: 0 8px 10px;
}
@media (max-width: 1799.98px) {
  .product .body {
    padding: 0 0.4444444444vw 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .product .body {
    padding: 0 0.5555555556vw 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .product .body {
    padding: 0 0.6510416667vw 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .product .body {
    padding: 0 1.3333333333vw 1.3333333333vw;
  }
}
.product .title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  font-weight: 700;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  min-height: 72px;
}
@media (max-width: 1799.98px) {
  .product .title {
    font-size: 0.8888888889vw;
    line-height: 1.3333333333vw;
    margin-bottom: 1.1111111111vw;
    min-height: 4vw;
  }
}
@media (max-width: 991.98px) {
  .product .title {
    font-size: 1.1111111111vw;
    line-height: 1.6666666667vw;
    margin-bottom: 1.3888888889vw;
    min-height: 5vw;
  }
}
@media (max-width: 767.98px) {
  .product .title {
    font-size: 1.5625vw;
    line-height: 2.6041666667vw;
    margin-bottom: 0.6510416667vw;
    min-height: 7.8125vw;
  }
}
@media (max-width: 575.98px) {
  .product .title {
    font-size: 3.2vw;
    line-height: 5.3333333333vw;
    margin-bottom: 1.3333333333vw;
    min-height: 16vw;
  }
}
@media only screen and (width < 768px) {
  .product .title {
    -webkit-line-clamp: 3;
  }
}
.product .footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media only screen and (width < 768px) {
  .product .footer {
    position: relative;
    padding-top: 25px;
  }
  .product .footer .details {
    display: flex;
    flex-direction: column;
  }
  .product .footer .details .price--box {
    order: 2;
  }
  .product .footer .details .stock--box {
    position: absolute;
    top: 0;
  }
}
.product .price {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 4px;
}
@media (max-width: 1799.98px) {
  .product .price {
    font-size: 1vw;
    line-height: 1.3333333333vw;
    margin-bottom: 0.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .product .price {
    font-size: 1.25vw;
    line-height: 1.6666666667vw;
    margin-bottom: 0.2777777778vw;
  }
}
@media (max-width: 767.98px) {
  .product .price {
    font-size: 1.5625vw;
    line-height: 2.34375vw;
    margin-bottom: 0.5208333333vw;
  }
}
@media (max-width: 575.98px) {
  .product .price {
    font-size: 3.2vw;
    line-height: 4.8vw;
    margin-bottom: 1.0666666667vw;
  }
}
.product .price--title {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 4px;
}
@media (max-width: 1799.98px) {
  .product .price--title {
    font-size: 0.6666666667vw;
    line-height: 1.3333333333vw;
    margin-bottom: 0.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .product .price--title {
    font-size: 0.8333333333vw;
    line-height: 1.6666666667vw;
    margin-bottom: 0.2777777778vw;
  }
}
@media (max-width: 767.98px) {
  .product .price--title {
    font-size: 1.5625vw;
    line-height: 3.125vw;
    margin-bottom: 0.5208333333vw;
  }
}
@media (max-width: 575.98px) {
  .product .price--title {
    font-size: 3.2vw;
    line-height: 6.4vw;
    margin-bottom: 1.0666666667vw;
  }
}
.product .price--box {
  color: var(--secondary);
  font-weight: 700;
}
@media only screen and (width < 768px) {
  .product .price--box {
    display: flex;
    flex-direction: column;
  }
}
.product .price--sale {
  color: #7e7d7d;
  font-weight: 500;
  position: relative;
  text-decoration: line-through;
}
@media only screen and (width < 768px) {
  .product .price--sale {
    font-size: 10px;
    line-height: 16px;
    margin: 0;
  }
}
.product .stock--box {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 1799.98px) {
  .product .stock--box {
    gap: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .product .stock--box {
    gap: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .product .stock--box {
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .product .stock--box {
    gap: 1.3333333333vw;
  }
}
.product .stock--box svg {
  height: auto;
  width: 10px;
}
@media (max-width: 1799.98px) {
  .product .stock--box svg {
    width: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .product .stock--box svg {
    width: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .product .stock--box svg {
    width: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .product .stock--box svg {
    width: 2.6666666667vw;
  }
}
.product .stock--box p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 1799.98px) {
  .product .stock--box p {
    gap: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .product .stock--box p {
    gap: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .product .stock--box p {
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .product .stock--box p {
    gap: 1.3333333333vw;
  }
}
.product .stock--box .stock {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
@media (max-width: 1799.98px) {
  .product .stock--box .stock {
    font-size: 0.6666666667vw;
    line-height: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .product .stock--box .stock {
    font-size: 0.8333333333vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .product .stock--box .stock {
    font-size: 1.3020833333vw;
    line-height: 2.8645833333vw;
  }
}
@media (max-width: 575.98px) {
  .product .stock--box .stock {
    font-size: 2.6666666667vw;
    line-height: 5.8666666667vw;
  }
}
.product .stock--box.in-stock {
  color: #236c2a;
}
.product .stock--box.in-stock svg circle {
  fill: #236c2a;
}
.product .stock--box.on-backorder {
  color: #AEA697;
}
.product .stock--box.on-backorder svg circle {
  fill: #AEA697;
}
.product .stock--box.out-of-stock {
  color: #ff4f4f;
}
.product .stock--box.out-of-stock svg circle {
  fill: #ff4f4f;
}
@media only screen and (width > 768px) {
  .product:hover {
    transform: scale(1.008) translateY(-5px);
  }
}

.product-assembly label {
  align-items: center;
  background: #f4f1ec;
  display: flex;
  margin-top: 0;
  position: relative;
  width: 100%;
  border-radius: 10px;
  gap: 25px;
  padding: 18px 15px;
}
@media (max-width: 1799.98px) {
  .product-assembly label {
    border-radius: 0.5555555556vw;
    gap: 1.3888888889vw;
    padding: 1vw 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .product-assembly label {
    border-radius: 0.6944444444vw;
    gap: 1.7361111111vw;
    padding: 1.25vw 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .product-assembly label {
    border-radius: 1.3020833333vw;
    gap: 3.2552083333vw;
    padding: 2.34375vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .product-assembly label {
    border-radius: 2.6666666667vw;
    gap: 6.6666666667vw;
    padding: 4.8vw 4vw;
  }
}
.product-assembly label .checkbox {
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  height: 40px;
  width: 40px;
}
@media (max-width: 1799.98px) {
  .product-assembly label .checkbox {
    border-radius: 0.5555555556vw;
    height: 2.2222222222vw;
    width: 2.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .product-assembly label .checkbox {
    border-radius: 0.6944444444vw;
    height: 2.7777777778vw;
    width: 2.7777777778vw;
  }
}
@media (max-width: 767.98px) {
  .product-assembly label .checkbox {
    border-radius: 1.3020833333vw;
    height: 5.2083333333vw;
    width: 5.2083333333vw;
  }
}
@media (max-width: 575.98px) {
  .product-assembly label .checkbox {
    border-radius: 2.6666666667vw;
    height: 10.6666666667vw;
    width: 10.6666666667vw;
  }
}
.product-assembly label .checkbox svg {
  height: auto;
  width: 20px;
}
@media (max-width: 1799.98px) {
  .product-assembly label .checkbox svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .product-assembly label .checkbox svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .product-assembly label .checkbox svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .product-assembly label .checkbox svg {
    width: 5.3333333333vw;
  }
}
.product-assembly input {
  display: none;
}
.product-assembly input:checked + label .checkbox {
  background: var(--secondary);
}
.product-assembly .info {
  position: relative;
}
.product-assembly .tooltip {
  position: relative;
}
.product-assembly .tooltip:hover .tooltip--message {
  bottom: 100%;
  opacity: 1;
}
.product-assembly .tooltip--message {
  background: #f4f1ec;
  bottom: 0;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  left: 50%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-radius: 10px;
  font-size: 14px;
  padding: 10px 15px;
  width: 350px;
}
@media (max-width: 1799.98px) {
  .product-assembly .tooltip--message {
    border-radius: 0.5555555556vw;
    font-size: 0.7777777778vw;
    padding: 0.5555555556vw 0.8333333333vw;
    width: 19.4444444444vw;
  }
}
@media (max-width: 991.98px) {
  .product-assembly .tooltip--message {
    border-radius: 0.6944444444vw;
    font-size: 0.9722222222vw;
    padding: 0.6944444444vw 1.0416666667vw;
    width: 24.3055555556vw;
  }
}
@media (max-width: 767.98px) {
  .product-assembly .tooltip--message {
    border-radius: 1.3020833333vw;
    font-size: 1.8229166667vw;
    padding: 1.3020833333vw 1.953125vw;
    width: 45.5729166667vw;
  }
}
@media (max-width: 575.98px) {
  .product-assembly .tooltip--message {
    border-radius: 2.6666666667vw;
    font-size: 3.7333333333vw;
    padding: 2.6666666667vw 4vw;
    width: 93.3333333333vw;
  }
}
.product-assembly .tooltip--message::before {
  background: #f4f1ec;
  bottom: -3px;
  content: "";
  height: 8px;
  left: 50%;
  position: absolute;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  width: 8px;
}

@media only screen and (width < 600px) {
  #product-row {
    margin-right: -5px;
    margin-left: -5px;
  }
  #product-row .col-6 {
    padding: 0 5px;
  }
}
.shop-filters--header {
  position: sticky;
  top: 0;
  z-index: 10;
  align-items: center;
  background: var(--secondary);
  border-bottom: 1px solid #f4f1ec;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
@media (max-width: 1799.98px) {
  .shop-filters--header {
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters--header {
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters--header {
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters--header {
    padding: 4vw;
  }
}
@media only screen and (width > 768px) {
  .shop-filters--header {
    display: none;
  }
}
.shop-filters--header h2, .shop-filters--header button {
  color: #fff;
}
.shop-filters--header h2 {
  margin: 0;
}
.shop-filters--header button {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  gap: 5px;
}
@media (max-width: 1799.98px) {
  .shop-filters--header button {
    font-size: 0.7777777778vw;
    gap: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters--header button {
    font-size: 0.9722222222vw;
    gap: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters--header button {
    font-size: 1.8229166667vw;
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters--header button {
    font-size: 3.7333333333vw;
    gap: 1.3333333333vw;
  }
}
.shop-filters--header button svg {
  height: auto;
  width: 25px;
}
@media (max-width: 1799.98px) {
  .shop-filters--header button svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters--header button svg {
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters--header button svg {
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters--header button svg {
    width: 6.6666666667vw;
  }
}
.shop-filters--header button svg path {
  fill: #fff;
}
.shop-filters .car-box {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 0 25px 20px;
}
@media only screen and (width > 768px) {
  .shop-filters .car-box {
    border: 1px solid #f4f1ec;
    box-shadow: rgba(103, 122, 135, 0.16);
  }
}
@media (max-width: 1799.98px) {
  .shop-filters .car-box {
    border-radius: 0.5555555556vw;
    margin-bottom: 0.8333333333vw;
    padding: 0 1.3888888889vw 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .car-box {
    border-radius: 0.6944444444vw;
    margin-bottom: 1.0416666667vw;
    padding: 0 1.7361111111vw 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .car-box {
    border-radius: 0;
    margin-bottom: 0;
    padding: 0 1.953125vw 0;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .car-box {
    border-radius: 0;
    margin-bottom: 0;
    padding: 0 4vw 0;
  }
}
.shop-filters .car-box .filter-box {
  border: 0;
  box-shadow: unset;
  margin-bottom: 0;
  padding: 0;
  margin-top: 20px;
}
@media (max-width: 1799.98px) {
  .shop-filters .car-box .filter-box {
    margin-top: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .car-box .filter-box {
    margin-top: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .car-box .filter-box {
    margin-top: 0;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .car-box .filter-box {
    margin-top: 0;
  }
}
.shop-filters .filter-box {
  background: #fff;
  border: 1px solid #f4f1ec;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 20px 25px;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box {
    border-radius: 0.5555555556vw;
    margin-bottom: 0.8333333333vw;
    padding: 1.1111111111vw 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box {
    border-radius: 0.6944444444vw;
    margin-bottom: 1.0416666667vw;
    padding: 1.3888888889vw 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box {
    border-radius: 0;
    margin-bottom: 0;
    padding: 0 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box {
    border-radius: 0;
    margin-bottom: 0;
    padding: 0 4vw;
  }
}
@media only screen and (width > 768px) {
  .shop-filters .filter-box {
    box-shadow: rgba(103, 122, 135, 0.16);
  }
}
@media only screen and (width < 768px) {
  .shop-filters .filter-box {
    border: 0;
  }
}
.shop-filters .filter-box--head {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0 0;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box--head {
    padding: 0.5555555556vw 0 0 0;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box--head {
    padding: 0.6944444444vw 0 0 0;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box--head {
    padding: 1.3020833333vw 0 1.3020833333vw 0;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box--head {
    padding: 2.6666666667vw 0 2.6666666667vw 0;
  }
}
.shop-filters .filter-box--head span {
  font-weight: 700;
  font-size: 18px;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box--head span {
    font-size: 1vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box--head span {
    font-size: 1.25vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box--head span {
    font-size: 2.34375vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box--head span {
    font-size: 4.8vw;
  }
}
.shop-filters .filter-box--head svg {
  transition: all 0.3s;
}
.shop-filters .filter-box--body {
  border-top: 1px solid #f4f1ec;
  height: 100%;
  padding: 15px 0 0;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box--body {
    padding: 0.8333333333vw 0 0;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box--body {
    padding: 1.0416666667vw 0 0;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box--body {
    padding: 1.953125vw 0 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box--body {
    padding: 4vw 0 4vw;
  }
}
.shop-filters .filter-box .ts-wrapper.single.dropdown-active .ts-control {
  border-radius: 25px 25px 0 0;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box .ts-wrapper.single.dropdown-active .ts-control {
    border-radius: 1.3888888889vw 1.3888888889vw 0 0;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box .ts-wrapper.single.dropdown-active .ts-control {
    border-radius: 1.7361111111vw 1.7361111111vw 0 0;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box .ts-wrapper.single.dropdown-active .ts-control {
    border-radius: 3.2552083333vw 3.2552083333vw 0 0;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box .ts-wrapper.single.dropdown-active .ts-control {
    border-radius: 6.6666666667vw 6.6666666667vw 0 0;
  }
}
.shop-filters .filter-box .ts-wrapper .item, .shop-filters .filter-box .ts-wrapper .option, .shop-filters .filter-box .ts-control .item, .shop-filters .filter-box .ts-control .option, .shop-filters .filter-box .ts-dropdown .item, .shop-filters .filter-box .ts-dropdown .option {
  font-size: 16px;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box .ts-wrapper .item, .shop-filters .filter-box .ts-wrapper .option, .shop-filters .filter-box .ts-control .item, .shop-filters .filter-box .ts-control .option, .shop-filters .filter-box .ts-dropdown .item, .shop-filters .filter-box .ts-dropdown .option {
    font-size: 0.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box .ts-wrapper .item, .shop-filters .filter-box .ts-wrapper .option, .shop-filters .filter-box .ts-control .item, .shop-filters .filter-box .ts-control .option, .shop-filters .filter-box .ts-dropdown .item, .shop-filters .filter-box .ts-dropdown .option {
    font-size: 1.1111111111vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box .ts-wrapper .item, .shop-filters .filter-box .ts-wrapper .option, .shop-filters .filter-box .ts-control .item, .shop-filters .filter-box .ts-control .option, .shop-filters .filter-box .ts-dropdown .item, .shop-filters .filter-box .ts-dropdown .option {
    font-size: 2.0833333333vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box .ts-wrapper .item, .shop-filters .filter-box .ts-wrapper .option, .shop-filters .filter-box .ts-control .item, .shop-filters .filter-box .ts-control .option, .shop-filters .filter-box .ts-dropdown .item, .shop-filters .filter-box .ts-dropdown .option {
    font-size: 4.2666666667vw;
  }
}
.shop-filters .filter-box .ts-control {
  padding: 0;
  background-color: #f4f1ec !important;
  border: 0;
  background-image: url("/assets/images/icons/chevron-down.svg") !important;
  background-repeat: no-repeat !important;
  border-radius: 50px;
  background-position: center right 15px !important;
  background-size: 20px !important;
  padding: 15px 45px 15px 20px !important;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box .ts-control {
    border-radius: 2.7777777778vw;
    background-position: center right 0.8333333333vw !important;
    background-size: 1.1111111111vw !important;
    padding: 0.8333333333vw 2.5vw 0.8333333333vw 1.1111111111vw !important;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box .ts-control {
    border-radius: 3.4722222222vw;
    background-position: center right 1.0416666667vw !important;
    background-size: 1.3888888889vw !important;
    padding: 1.0416666667vw 3.125vw 1.0416666667vw 1.3888888889vw !important;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box .ts-control {
    border-radius: 6.5104166667vw;
    background-position: center right 1.953125vw !important;
    background-size: 2.6041666667vw !important;
    padding: 1.953125vw 5.859375vw 1.953125vw 2.6041666667vw !important;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box .ts-control {
    border-radius: 13.3333333333vw;
    background-position: center right 4vw !important;
    background-size: 5.3333333333vw !important;
    padding: 4vw 12vw 4vw 5.3333333333vw !important;
  }
}
.shop-filters .filter-box .ts-control input {
  color: #333;
  background: #f4f1ec !important;
}
.shop-filters .filter-box .ts-dropdown {
  margin: 0;
}
.shop-filters .filter-box .select-box {
  position: relative;
}
.shop-filters .filter-box .select-box select, .shop-filters .filter-box .select-box input {
  color: #333;
  font-size: 14px;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box .select-box select, .shop-filters .filter-box .select-box input {
    font-size: 0.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box .select-box select, .shop-filters .filter-box .select-box input {
    font-size: 0.9722222222vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box .select-box select, .shop-filters .filter-box .select-box input {
    font-size: 1.8229166667vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box .select-box select, .shop-filters .filter-box .select-box input {
    font-size: 3.7333333333vw;
  }
}
.shop-filters .filter-box .select-box select {
  appearance: none;
  background: #f4f1ec;
  border: 0;
  width: 100%;
  border-radius: 50px;
  padding: 15px 30px 15px 20px;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box .select-box select {
    border-radius: 2.7777777778vw;
    padding: 0.8333333333vw 1.6666666667vw 0.8333333333vw 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box .select-box select {
    border-radius: 3.4722222222vw;
    padding: 1.0416666667vw 2.0833333333vw 1.0416666667vw 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box .select-box select {
    border-radius: 6.5104166667vw;
    padding: 1.953125vw 3.90625vw 1.953125vw 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box .select-box select {
    border-radius: 13.3333333333vw;
    padding: 4vw 8vw 4vw 5.3333333333vw;
  }
}
.shop-filters .filter-box .checkbox-body .filter-box__toggle {
  transition: all 0.3s;
}
.shop-filters .filter-box .checkbox-body .filter-box__toggle button {
  border: 0;
  background: unset;
  padding: 0;
  color: var(--secondary);
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shop-filters .filter-box .checkbox-body .filter-box__toggle button svg {
  height: auto;
  transform: rotate(0deg);
  width: 15px;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box .checkbox-body .filter-box__toggle button svg {
    width: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box .checkbox-body .filter-box__toggle button svg {
    width: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box .checkbox-body .filter-box__toggle button svg {
    width: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box .checkbox-body .filter-box__toggle button svg {
    width: 4vw;
  }
}
.shop-filters .filter-box .checkbox-body .filter-box__toggle button svg path {
  fill: var(--secondary);
}
.shop-filters .filter-box .checkbox-body .filter-box__toggle .less {
  display: none;
}
.shop-filters .filter-box .checkbox-body .filter-box__toggle.active {
  padding-bottom: 10px;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box .checkbox-body .filter-box__toggle.active {
    padding-bottom: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box .checkbox-body .filter-box__toggle.active {
    padding-bottom: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box .checkbox-body .filter-box__toggle.active {
    padding-bottom: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box .checkbox-body .filter-box__toggle.active {
    padding-bottom: 2.6666666667vw;
  }
}
.shop-filters .filter-box .checkbox-body .filter-box__toggle.active .more {
  display: none;
}
.shop-filters .filter-box .checkbox-body .filter-box__toggle.active .less {
  display: flex;
}
.shop-filters .filter-box.select label {
  display: none;
}
.shop-filters .filter-box.close .filter-box--head {
  transition: all 0.3s ease-in-out 0.3s;
  padding-bottom: 0;
}
@media (max-width: 1799.98px) {
  .shop-filters .filter-box.close .filter-box--head {
    padding-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .filter-box.close .filter-box--head {
    padding-bottom: 0;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .filter-box.close .filter-box--head {
    padding-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .filter-box.close .filter-box--head {
    padding-bottom: 4vw;
  }
}
.shop-filters .floating-bar {
  background: #fff;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  padding: 15px;
}
@media (max-width: 1799.98px) {
  .shop-filters .floating-bar {
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .shop-filters .floating-bar {
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .shop-filters .floating-bar {
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .shop-filters .floating-bar {
    padding: 4vw;
  }
}
.shop-filters .floating-bar button {
  justify-content: center;
  width: 100%;
}
.shop-filters .floating-bar button span {
  line-height: unset;
}
@media only screen and (width < 768px) {
  .shop-filters {
    background: #fff;
    height: 100%;
    left: 0;
    max-height: 100dvh;
    position: fixed;
    top: 0;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.3s ease-in-out;
    width: 100vw;
    z-index: 24;
  }
  .shop-filters.show {
    transform: translateZ(0);
  }
  .shop-filters--body {
    height: 100%;
    overflow: auto;
    max-height: calc(100dvh - 50px - 81px);
  }
}
@media only screen and (width < 768px) and (max-width: 1799.98px) {
  .shop-filters--body {
    max-height: calc(100dvh - 2.7777777778vw - 4.5vw);
  }
}
@media only screen and (width < 768px) and (max-width: 991.98px) {
  .shop-filters--body {
    max-height: calc(100dvh - 3.4722222222vw - 5.625vw);
  }
}
@media only screen and (width < 768px) and (max-width: 767.98px) {
  .shop-filters--body {
    max-height: calc(100dvh - 6.5104166667vw - 10.546875vw);
  }
}
@media only screen and (width < 768px) and (max-width: 575.98px) {
  .shop-filters--body {
    max-height: calc(100dvh - 13.3333333333vw - 21.6vw);
  }
}
@media only screen and (width < 768px) {
  .shop-filters .floating-bar {
    display: block;
  }
}

.active-filters {
  display: none;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
@media (max-width: 1799.98px) {
  .active-filters {
    margin-bottom: 0.8333333333vw;
    padding-bottom: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .active-filters {
    margin-bottom: 1.0416666667vw;
    padding-bottom: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .active-filters {
    margin-bottom: 1.953125vw;
    padding-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .active-filters {
    margin-bottom: 4vw;
    padding-bottom: 4vw;
  }
}
.active-filters--box {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .active-filters--box {
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .active-filters--box {
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .active-filters--box {
    gap: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .active-filters--box {
    gap: 2.6666666667vw;
  }
}
.active-filters button {
  align-items: center;
  background: var(--secondary);
  border: 1px solid var(--secondary);
  color: #fff;
  display: flex;
  justify-content: center;
  outline: none;
  border-radius: 10px;
  font-size: 12px;
  gap: 10px;
  line-height: 18px;
  padding: 5px 10px;
}
@media (max-width: 1799.98px) {
  .active-filters button {
    border-radius: 0.5555555556vw;
    font-size: 0.6666666667vw;
    gap: 0.5555555556vw;
    line-height: 1vw;
    padding: 0.2777777778vw 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .active-filters button {
    border-radius: 0.6944444444vw;
    font-size: 0.8333333333vw;
    gap: 0.6944444444vw;
    line-height: 1.25vw;
    padding: 0.3472222222vw 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .active-filters button {
    border-radius: 1.3020833333vw;
    font-size: 1.5625vw;
    gap: 1.3020833333vw;
    line-height: 2.34375vw;
    padding: 0.6510416667vw 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .active-filters button {
    border-radius: 2.6666666667vw;
    font-size: 3.2vw;
    gap: 2.6666666667vw;
    line-height: 4.8vw;
    padding: 1.3333333333vw 2.6666666667vw;
  }
}
.active-filters button svg {
  height: auto;
  transform: rotate(0);
  width: 16px;
}
@media (max-width: 1799.98px) {
  .active-filters button svg {
    width: 0.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .active-filters button svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 767.98px) {
  .active-filters button svg {
    width: 2.0833333333vw;
  }
}
@media (max-width: 575.98px) {
  .active-filters button svg {
    width: 4.2666666667vw;
  }
}
.active-filters button svg path {
  fill: #fff;
}
.active-filters #remove-all-filters {
  background: transparent;
  border: 0;
  color: var(--secondary);
  margin-top: 15px;
  padding: 0;
}
.active-filters #remove-all-filters:hover {
  color: #ff4f4f;
}
.active-filters.show {
  display: block;
}

#loader {
  left: 0;
  position: absolute;
  top: 100%;
  transition: all 0.3s;
  width: 100%;
  z-index: 3;
}
#loader.hide {
  opacity: 0;
}
#loader .progress {
  background: var(--primary);
  position: relative;
  transition: all 1s;
  width: 0;
  height: 5px;
}
@media (max-width: 1799.98px) {
  #loader .progress {
    height: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  #loader .progress {
    height: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  #loader .progress {
    height: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  #loader .progress {
    height: 1.3333333333vw;
  }
}
#loader svg {
  position: absolute;
  left: 0;
  height: auto;
  margin-bottom: 10px;
  transition: all 1s;
  bottom: -12px;
  width: 100px;
}
@media (max-width: 1799.98px) {
  #loader svg {
    bottom: -0.6666666667vw;
    width: 5.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  #loader svg {
    bottom: -0.8333333333vw;
    width: 6.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  #loader svg {
    bottom: -1.5625vw;
    width: 13.0208333333vw;
  }
}
@media (max-width: 575.98px) {
  #loader svg {
    bottom: -3.2vw;
    width: 26.6666666667vw;
  }
}
#loader.animate .progress {
  width: 100%;
}
#loader.animate svg {
  left: 100%;
}

@media only screen and (width > 600px) {
  .backdrop {
    background: rgba(0, 0, 0, 0.5);
    height: 100dvh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all 0.3s;
    visibility: hidden;
    width: 100%;
    z-index: 20;
  }
  .backdrop.show {
    opacity: 1;
    visibility: visible;
  }
}
.side-mini-cart {
  background: #fff;
  height: 100%;
  max-height: 100dvh;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(150%);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 24;
  display: flex;
  flex-direction: column;
  width: 650px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart {
    width: 36.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart {
    width: 45.1388888889vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart {
    width: 100%;
  }
}
.side-mini-cart ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.side-mini-cart ul li {
  border-bottom: 1px solid #f4f1ec;
  transition: all 0.3s;
}
.side-mini-cart ul li.remove {
  transform: translateX(150%);
}
.side-mini-cart ul li:last-child {
  border-bottom: 0;
}
.side-mini-cart--header {
  align-items: center;
  align-self: flex-start;
  background: #fff;
  border-bottom: 1px solid #f4f1ec;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  padding: 20px 25px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--header {
    padding: 1.1111111111vw 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--header {
    padding: 1.3888888889vw 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--header {
    padding: 1.953125vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--header {
    padding: 4vw 4vw;
  }
}
.side-mini-cart--header .title {
  margin: 0;
  font-size: 28px;
  line-height: 36px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--header .title {
    font-size: 1.5555555556vw;
    line-height: 2vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--header .title {
    font-size: 1.9444444444vw;
    line-height: 2.5vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--header .title {
    font-size: 3.125vw;
    line-height: 4.4270833333vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--header .title {
    font-size: 6.4vw;
    line-height: 9.0666666667vw;
  }
}
.side-mini-cart--header button {
  background: none;
  border: 0;
  outline: none;
}
.side-mini-cart--content {
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}
.side-mini-cart--content .gift {
  background: var(--secondary);
  color: #fff;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  border-radius: 50px;
  gap: 8px;
  margin-top: 5px;
  padding: 8px 15px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--content .gift {
    border-radius: 2.7777777778vw;
    gap: 0.4444444444vw;
    margin-top: 0.2777777778vw;
    padding: 0.4444444444vw 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--content .gift {
    border-radius: 3.4722222222vw;
    gap: 0.5555555556vw;
    margin-top: 0.3472222222vw;
    padding: 0.5555555556vw 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--content .gift {
    border-radius: 6.5104166667vw;
    gap: 1.0416666667vw;
    margin-top: 0.6510416667vw;
    padding: 1.0416666667vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--content .gift {
    border-radius: 13.3333333333vw;
    gap: 2.1333333333vw;
    margin-top: 1.3333333333vw;
    padding: 2.1333333333vw 4vw;
  }
}
.side-mini-cart--content .gift svg {
  height: auto;
  width: 20px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--content .gift svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--content .gift svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--content .gift svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--content .gift svg {
    width: 5.3333333333vw;
  }
}
.side-mini-cart--content .gift svg path {
  fill: #fff;
}
.side-mini-cart--body {
  flex: 1 1 auto;
  position: relative;
}
.side-mini-cart--body li {
  padding: 25px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--body li {
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--body li {
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--body li {
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--body li {
    padding: 4vw;
  }
}
.side-mini-cart--footer {
  align-self: flex-end;
  background: #fff;
  border-top: 1px solid #f4f1ec;
  bottom: 0;
  flex: 0 0 auto;
  position: sticky;
  width: 100%;
  padding: 25px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--footer {
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--footer {
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--footer {
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--footer {
    padding: 4vw;
  }
}
.side-mini-cart--footer p {
  font-size: 24px;
  line-height: 34px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--footer p {
    font-size: 1.3333333333vw;
    line-height: 1.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--footer p {
    font-size: 1.6666666667vw;
    line-height: 2.3611111111vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--footer p {
    font-size: 2.6041666667vw;
    line-height: 3.90625vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--footer p {
    font-size: 5.3333333333vw;
    line-height: 8vw;
  }
}
.side-mini-cart--footer p.small {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--footer p.small {
    font-size: 1vw;
    line-height: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--footer p.small {
    font-size: 1.25vw;
    line-height: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--footer p.small {
    font-size: 1.8229166667vw;
    line-height: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--footer p.small {
    font-size: 3.7333333333vw;
    line-height: 6.4vw;
  }
}
.side-mini-cart--empty {
  padding: 25px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--empty {
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--empty {
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--empty {
    padding: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--empty {
    padding: 6.6666666667vw;
  }
}
.side-mini-cart--empty svg {
  height: auto;
  width: 50px;
  margin-bottom: 5px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--empty svg {
    width: 2.7777777778vw;
    margin-bottom: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--empty svg {
    width: 3.4722222222vw;
    margin-bottom: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--empty svg {
    width: 6.5104166667vw;
    margin-bottom: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--empty svg {
    width: 13.3333333333vw;
    margin-bottom: 1.3333333333vw;
  }
}
.side-mini-cart--empty svg path {
  fill: #a8a8a4;
}
.side-mini-cart--empty h2 {
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 25px;
}
@media (max-width: 1799.98px) {
  .side-mini-cart--empty h2 {
    font-size: 1.5555555556vw;
    line-height: 2vw;
    margin-bottom: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .side-mini-cart--empty h2 {
    font-size: 1.9444444444vw;
    line-height: 2.5vw;
    margin-bottom: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .side-mini-cart--empty h2 {
    font-size: 3.6458333333vw;
    line-height: 4.6875vw;
    margin-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .side-mini-cart--empty h2 {
    font-size: 7.4666666667vw;
    line-height: 9.6vw;
    margin-bottom: 6.6666666667vw;
  }
}
.side-mini-cart--empty.fade-in {
  animation: fadeInAnimation ease 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#mini-cart.open .side-mini-cart {
  transform: translateX(0);
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
main {
  position: relative;
}

.share {
  color: #fff;
  display: flex;
  gap: 20px;
}
@media (max-width: 1799.98px) {
  .share {
    gap: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .share {
    gap: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .share {
    gap: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .share {
    gap: 5.3333333333vw;
  }
}
.share .icons {
  display: flex;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .share .icons {
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .share .icons {
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .share .icons {
    gap: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .share .icons {
    gap: 2.6666666667vw;
  }
}
.share .icons * {
  transition: all 0.3s;
}
.share .icons .icon:hover path {
  fill: var(--primary);
}

.single-product--title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 25px;
}
@media (max-width: 1799.98px) {
  .single-product--title {
    font-size: 1.3333333333vw;
    line-height: 1.7777777778vw;
    margin-bottom: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--title {
    font-size: 1.6666666667vw;
    line-height: 2.2222222222vw;
    margin-bottom: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--title {
    font-size: 3.125vw;
    line-height: 4.1666666667vw;
    margin-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--title {
    font-size: 6.4vw;
    line-height: 8.5333333333vw;
    margin-bottom: 6.6666666667vw;
  }
}
.single-product .share {
  color: #fff;
}
.single-product .sku {
  align-items: center;
  display: flex;
  font-size: 16px;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .single-product .sku {
    font-size: 0.8888888889vw;
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .sku {
    font-size: 1.1111111111vw;
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .sku {
    font-size: 1.5625vw;
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .sku {
    font-size: 3.2vw;
    gap: 1.3333333333vw;
  }
}
.single-product .sku svg {
  height: auto;
  width: 15px;
}
@media (max-width: 1799.98px) {
  .single-product .sku svg {
    width: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .sku svg {
    width: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .sku svg {
    width: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .sku svg {
    width: 2.6666666667vw;
  }
}
.single-product--info-box {
  position: sticky;
  border-radius: 15px;
  padding: 35px 50px;
  top: 115px;
}
@media only screen and (width > 768px) {
  .single-product--info-box {
    background: #fff;
    box-shadow: 0 2px 3px 0 rgba(103, 122, 135, 0.16);
    border: 1px solid #f4f1ec;
  }
}
@media (max-width: 1799.98px) {
  .single-product--info-box {
    border-radius: 0.8333333333vw;
    padding: 1.9444444444vw 2.7777777778vw;
    top: 6.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--info-box {
    border-radius: 1.0416666667vw;
    padding: 2.4305555556vw 3.4722222222vw;
    top: 7.9861111111vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--info-box {
    border-radius: 1.953125vw;
    padding: 1.953125vw 0;
    top: 14.9739583333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--info-box {
    border-radius: 4vw;
    padding: 4vw 0;
    top: 30.6666666667vw;
  }
}
.single-product--info-box .stock {
  align-items: flex-start;
  display: flex;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .single-product--info-box .stock {
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--info-box .stock {
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--info-box .stock {
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--info-box .stock {
    gap: 1.3333333333vw;
  }
}
.single-product--info-box .stock span {
  font-size: 16px;
}
@media (max-width: 1799.98px) {
  .single-product--info-box .stock span {
    font-size: 0.8888888889vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--info-box .stock span {
    font-size: 1.1111111111vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--info-box .stock span {
    font-size: 1.5625vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--info-box .stock span {
    font-size: 3.2vw;
  }
}
.single-product--info-box .stock svg {
  height: auto;
  margin-top: 5px;
  width: 15px;
}
@media (max-width: 1799.98px) {
  .single-product--info-box .stock svg {
    margin-top: 0.2777777778vw;
    width: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--info-box .stock svg {
    margin-top: 0.3472222222vw;
    width: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--info-box .stock svg {
    margin-top: 0.9114583333vw;
    width: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--info-box .stock svg {
    margin-top: 1.8666666667vw;
    width: 2.6666666667vw;
  }
}
.single-product--info-box .stock.in-stock {
  color: #236c2a;
}
.single-product--info-box .stock.in-stock svg circle {
  fill: #236c2a;
}
.single-product--info-box .stock.on-backorder svg circle {
  fill: #F08509;
}
.single-product--info-box .stock.out-of-stock {
  color: #ff4f4f;
}
.single-product--info-box .stock.out-of-stock svg circle {
  fill: #ff4f4f;
}
.single-product .price-box .price {
  color: var(--secondary);
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
@media (max-width: 1799.98px) {
  .single-product .price-box .price {
    font-size: 1.3333333333vw;
    line-height: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .price-box .price {
    font-size: 1.6666666667vw;
    line-height: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .price-box .price {
    font-size: 3.125vw;
    line-height: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .price-box .price {
    font-size: 6.4vw;
    line-height: 6.4vw;
  }
}
.single-product .price-box .price--sale {
  color: #7e7d7d;
  font-weight: 500;
  position: relative;
}
.single-product .price-box .price--sale::before {
  border-top: 2px solid #7e7d7d;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: rotate(-10deg);
}
.single-product .product--images-slider--box {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
@media (max-width: 1799.98px) {
  .single-product .product--images-slider--box {
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .product--images-slider--box {
    border-radius: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .product--images-slider--box {
    border-radius: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .product--images-slider--box {
    border-radius: 2.6666666667vw;
  }
}
.single-product .product--images-slider {
  margin-bottom: 15px;
}
@media (max-width: 1799.98px) {
  .single-product .product--images-slider {
    margin-bottom: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .product--images-slider {
    margin-bottom: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .product--images-slider {
    margin-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .product--images-slider {
    margin-bottom: 4vw;
  }
}
.single-product .product--images-slider img {
  border-radius: 10px;
  height: auto;
  width: 100%;
}
.single-product .product--thumbnail-slider .slick-list {
  margin: 0 -15px;
}
@media (max-width: 1799.98px) {
  .single-product .product--thumbnail-slider .slick-list {
    margin: 0 -0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .product--thumbnail-slider .slick-list {
    margin: 0 -1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .product--thumbnail-slider .slick-list {
    margin: 0 -0.9765625vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .product--thumbnail-slider .slick-list {
    margin: 0 -2vw;
  }
}
.single-product .product--thumbnail-slider .slick-track {
  margin-left: 0;
}
.single-product .product--thumbnail-slider .slick-slide {
  margin: 0 15px;
}
@media (max-width: 1799.98px) {
  .single-product .product--thumbnail-slider .slick-slide {
    margin: 0 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .product--thumbnail-slider .slick-slide {
    margin: 0 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .product--thumbnail-slider .slick-slide {
    margin: 0 0.9765625vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .product--thumbnail-slider .slick-slide {
    margin: 0 2vw;
  }
}
.single-product .product--thumbnail-slider .slick-slide img {
  filter: grayscale(1);
}
.single-product .product--thumbnail-slider .slick-slide.slick-current img {
  filter: unset;
}
.single-product .product--thumbnail-slider img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  height: 145px;
}
@media (max-width: 1799.98px) {
  .single-product .product--thumbnail-slider img {
    border-radius: 0.5555555556vw;
    height: 8.0555555556vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .product--thumbnail-slider img {
    border-radius: 0.6944444444vw;
    height: 10.0694444444vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .product--thumbnail-slider img {
    border-radius: 1.3020833333vw;
    height: 13.0208333333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .product--thumbnail-slider img {
    border-radius: 2.6666666667vw;
    height: 26.6666666667vw;
  }
}
.single-product--details {
  margin-bottom: 50px;
  margin-top: 50px;
}
@media (max-width: 1799.98px) {
  .single-product--details {
    margin-bottom: 2.7777777778vw;
    margin-top: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--details {
    margin-bottom: 3.4722222222vw;
    margin-top: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--details {
    margin-bottom: 3.2552083333vw;
    margin-top: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--details {
    margin-bottom: 6.6666666667vw;
    margin-top: 6.6666666667vw;
  }
}
.single-product--details hr {
  border-color: #e5e5e4;
  border-style: solid;
  border-width: 1px 0 0;
  margin: 15px 0;
}
@media (max-width: 1799.98px) {
  .single-product--details hr {
    margin: 0.8333333333vw 0;
  }
}
@media (max-width: 991.98px) {
  .single-product--details hr {
    margin: 1.0416666667vw 0;
  }
}
@media (max-width: 767.98px) {
  .single-product--details hr {
    margin: 1.953125vw 0;
  }
}
@media (max-width: 575.98px) {
  .single-product--details hr {
    margin: 4vw 0;
  }
}
.single-product--details a {
  color: var(--secondary);
  font-weight: 700;
}
.single-product--details .slick-arrow {
  background: #f4f1ec;
}
.single-product #variantInfo {
  margin: 25px 0;
}
@media (max-width: 1799.98px) {
  .single-product #variantInfo {
    margin: 1.3888888889vw 0;
  }
}
@media (max-width: 991.98px) {
  .single-product #variantInfo {
    margin: 1.7361111111vw 0;
  }
}
@media (max-width: 767.98px) {
  .single-product #variantInfo {
    margin: 3.2552083333vw 0;
  }
}
@media (max-width: 575.98px) {
  .single-product #variantInfo {
    margin: 6.6666666667vw 0;
  }
}
.single-product form button:disabled {
  opacity: 0.5;
}
.single-product .quantity-box {
  align-items: center;
  background: #f4f1ec;
  display: inline-flex;
  overflow: hidden;
  border-radius: 10px;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
@media (max-width: 1799.98px) {
  .single-product .quantity-box {
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .quantity-box {
    border-radius: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .quantity-box {
    border-radius: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .quantity-box {
    border-radius: 2.6666666667vw;
  }
}
.single-product .quantity-box label {
  margin: 0;
}
.single-product .quantity-box button, .single-product .quantity-box input {
  outline: none;
  font-size: 19px;
  height: 40px;
  width: 45px;
}
@media (max-width: 1799.98px) {
  .single-product .quantity-box button, .single-product .quantity-box input {
    font-size: 1.0555555556vw;
    height: 2.2222222222vw;
    width: 2.5vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .quantity-box button, .single-product .quantity-box input {
    font-size: 1.3194444444vw;
    height: 2.7777777778vw;
    width: 3.125vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .quantity-box button, .single-product .quantity-box input {
    font-size: 2.4739583333vw;
    height: 5.2083333333vw;
    width: 5.859375vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .quantity-box button, .single-product .quantity-box input {
    font-size: 5.0666666667vw;
    height: 10.6666666667vw;
    width: 12vw;
  }
}
.single-product .quantity-box input {
  background: #fff;
  border: 0;
  text-align: center;
}
.single-product .quantity-box input::-webkit-outer-spin-button,
.single-product .quantity-box input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.single-product .quantity-box input[type=number] {
  -moz-appearance: textfield;
}
.single-product .quantity-box button {
  background: #f4f1ec;
  border: 0;
  height: 45px;
}
@media (max-width: 1799.98px) {
  .single-product .quantity-box button {
    height: 2.5vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .quantity-box button {
    height: 3.125vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .quantity-box button {
    height: 5.859375vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .quantity-box button {
    height: 12vw;
  }
}
.single-product .bottom {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
@media (max-width: 1799.98px) {
  .single-product .bottom {
    gap: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .single-product .bottom {
    gap: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .single-product .bottom {
    gap: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .single-product .bottom {
    gap: 4vw;
  }
}
.single-product button[type=submit] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 45px;
}
@media (max-width: 1799.98px) {
  .single-product button[type=submit] {
    gap: 0.5555555556vw;
    height: 2.5vw;
  }
}
@media (max-width: 991.98px) {
  .single-product button[type=submit] {
    gap: 0.6944444444vw;
    height: 3.125vw;
  }
}
@media (max-width: 767.98px) {
  .single-product button[type=submit] {
    gap: 1.3020833333vw;
    height: 5.859375vw;
  }
}
@media (max-width: 575.98px) {
  .single-product button[type=submit] {
    gap: 2.6666666667vw;
    height: 12vw;
  }
}
.single-product button[type=submit] svg {
  height: auto;
  width: 28px;
}
@media (max-width: 1799.98px) {
  .single-product button[type=submit] svg {
    width: 1.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .single-product button[type=submit] svg {
    width: 1.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .single-product button[type=submit] svg {
    width: 3.6458333333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product button[type=submit] svg {
    width: 7.4666666667vw;
  }
}
.single-product--tabs--buttons {
  background: #e5e5e4;
  display: inline-flex;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: -25px;
  padding-bottom: 25px;
}
@media (max-width: 1799.98px) {
  .single-product--tabs--buttons {
    border-top-left-radius: 0.5555555556vw;
    border-top-right-radius: 0.5555555556vw;
    margin-bottom: -1.3888888889vw;
    padding-bottom: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--tabs--buttons {
    border-top-left-radius: 0.6944444444vw;
    border-top-right-radius: 0.6944444444vw;
    margin-bottom: -1.7361111111vw;
    padding-bottom: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--tabs--buttons {
    border-top-left-radius: 1.3020833333vw;
    border-top-right-radius: 1.3020833333vw;
    margin-bottom: -3.2552083333vw;
    padding-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--tabs--buttons {
    border-top-left-radius: 2.6666666667vw;
    border-top-right-radius: 2.6666666667vw;
    margin-bottom: -6.6666666667vw;
    padding-bottom: 6.6666666667vw;
  }
}
.single-product--tabs--buttons button {
  border: 0;
  outline: none;
  transition: all 0.3s;
  color: #333;
  padding: 15px 25px;
}
@media (max-width: 1799.98px) {
  .single-product--tabs--buttons button {
    padding: 0.8333333333vw 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--tabs--buttons button {
    padding: 1.0416666667vw 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--tabs--buttons button {
    padding: 1.953125vw 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--tabs--buttons button {
    padding: 4vw 6.6666666667vw;
  }
}
.single-product--tabs--buttons button.tab-button--active {
  font-weight: 700;
}
.single-product--tabs {
  background: #fff;
  border: 1px solid #f4f1ec;
  box-shadow: 0 2px 3px 0 rgba(103, 122, 135, 0.16);
  position: relative;
  z-index: 2;
  border-radius: 10px;
  padding: 25px;
}
@media (max-width: 1799.98px) {
  .single-product--tabs {
    border-radius: 0.5555555556vw;
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--tabs {
    border-radius: 0.6944444444vw;
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--tabs {
    border-radius: 1.3020833333vw;
    padding: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--tabs {
    border-radius: 2.6666666667vw;
    padding: 6.6666666667vw;
  }
}
.single-product--tabs table .item__label {
  text-align: left;
  min-width: 200px;
}
@media (max-width: 1799.98px) {
  .single-product--tabs table .item__label {
    min-width: 11.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--tabs table .item__label {
    min-width: 13.8888888889vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--tabs table .item__label {
    min-width: 26.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--tabs table .item__label {
    min-width: 53.3333333333vw;
  }
}
.single-product--cta {
  padding-top: 40px;
}
@media (max-width: 1799.98px) {
  .single-product--cta {
    padding-top: 2.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--cta {
    padding-top: 2.7777777778vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--cta {
    padding-top: 5.2083333333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--cta {
    padding-top: 10.6666666667vw;
  }
}
.single-product--cta p {
  display: flex;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .single-product--cta p {
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--cta p {
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--cta p {
    gap: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--cta p {
    gap: 2.6666666667vw;
  }
}
.single-product--cta p svg {
  height: auto;
  width: 24px;
}
@media (max-width: 1799.98px) {
  .single-product--cta p svg {
    width: 1.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--cta p svg {
    width: 1.6666666667vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--cta p svg {
    width: 3.125vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--cta p svg {
    width: 6.4vw;
  }
}
.single-product--related {
  padding-top: 25px;
  padding-bottom: 50px;
}
@media (max-width: 1799.98px) {
  .single-product--related {
    padding-top: 1.3888888889vw;
    padding-bottom: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .single-product--related {
    padding-top: 1.7361111111vw;
    padding-bottom: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .single-product--related {
    padding-top: 3.2552083333vw;
    padding-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .single-product--related {
    padding-top: 6.6666666667vw;
    padding-bottom: 6.6666666667vw;
  }
}

.variations {
  margin: 25px 0 35px 0;
}
@media (max-width: 1799.98px) {
  .variations {
    margin: 1.3888888889vw 0 1.9444444444vw 0;
  }
}
@media (max-width: 991.98px) {
  .variations {
    margin: 1.7361111111vw 0 2.4305555556vw 0;
  }
}
@media (max-width: 767.98px) {
  .variations {
    margin: 3.2552083333vw 0 4.5572916667vw 0;
  }
}
@media (max-width: 575.98px) {
  .variations {
    margin: 6.6666666667vw 0 9.3333333333vw 0;
  }
}

#out-of-stock--box {
  background: #fff;
  border: 1px solid #e5e5e4;
  background: #f4f1ec;
  border-radius: 10px;
  padding: 25px;
}
@media (max-width: 1799.98px) {
  #out-of-stock--box {
    border-radius: 0.5555555556vw;
    padding: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  #out-of-stock--box {
    border-radius: 0.6944444444vw;
    padding: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  #out-of-stock--box {
    border-radius: 1.3020833333vw;
    padding: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  #out-of-stock--box {
    border-radius: 2.6666666667vw;
    padding: 6.6666666667vw;
  }
}
#out-of-stock--box h3 {
  font-size: 18px;
}
@media (max-width: 1799.98px) {
  #out-of-stock--box h3 {
    font-size: 1vw;
  }
}
@media (max-width: 991.98px) {
  #out-of-stock--box h3 {
    font-size: 1.25vw;
  }
}
@media (max-width: 767.98px) {
  #out-of-stock--box h3 {
    font-size: 2.34375vw;
  }
}
@media (max-width: 575.98px) {
  #out-of-stock--box h3 {
    font-size: 4.8vw;
  }
}
#out-of-stock--box label {
  display: block;
}
#out-of-stock--box input {
  background: #fff;
  border: 1px solid #e5e5e4;
  width: 100%;
  outline: none;
  padding: 15px;
  border-radius: 10px;
}
@media (max-width: 1799.98px) {
  #out-of-stock--box input {
    padding: 0.8333333333vw;
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  #out-of-stock--box input {
    padding: 1.0416666667vw;
    border-radius: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  #out-of-stock--box input {
    padding: 1.953125vw;
    border-radius: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  #out-of-stock--box input {
    padding: 4vw;
    border-radius: 2.6666666667vw;
  }
}
#out-of-stock--box .error-message {
  color: #ff4f4f;
  font-size: 14px;
}
@media (max-width: 1799.98px) {
  #out-of-stock--box .error-message {
    font-size: 0.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  #out-of-stock--box .error-message {
    font-size: 0.9722222222vw;
  }
}
@media (max-width: 767.98px) {
  #out-of-stock--box .error-message {
    font-size: 1.8229166667vw;
  }
}
@media (max-width: 575.98px) {
  #out-of-stock--box .error-message {
    font-size: 3.7333333333vw;
  }
}
#out-of-stock--box.success {
  border-color: #236c2a;
}
#out-of-stock--box.success h3 {
  color: #236c2a;
}

.home.new {
  padding-top: 100px;
}
@media (max-width: 1799.98px) {
  .home.new {
    padding-top: 5.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .home.new {
    padding-top: 6.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .home.new {
    padding-top: 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .home.new {
    padding-top: 13.3333333333vw;
  }
}
.home.new h2 {
  margin-bottom: 25px;
}
@media (max-width: 1799.98px) {
  .home.new h2 {
    margin-bottom: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .home.new h2 {
    margin-bottom: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .home.new h2 {
    margin-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .home.new h2 {
    margin-bottom: 6.6666666667vw;
  }
}
@media only screen and (width < 768px) {
  .home.new h2.text-white {
    color: var(--secondary);
  }
}
.home.about, .home.most-sold {
  padding-top: 100px;
}
@media (max-width: 1799.98px) {
  .home.about, .home.most-sold {
    padding-top: 5.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .home.about, .home.most-sold {
    padding-top: 6.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .home.about, .home.most-sold {
    padding-top: 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .home.about, .home.most-sold {
    padding-top: 13.3333333333vw;
  }
}
.home .slick-slider.bottom {
  margin-bottom: 35px;
}
@media (max-width: 1799.98px) {
  .home .slick-slider.bottom {
    margin-bottom: 1.9444444444vw;
  }
}
@media (max-width: 991.98px) {
  .home .slick-slider.bottom {
    margin-bottom: 2.4305555556vw;
  }
}
@media (max-width: 767.98px) {
  .home .slick-slider.bottom {
    margin-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .home .slick-slider.bottom {
    margin-bottom: 6.6666666667vw;
  }
}
.home .slick-slider.bottom .slick-list {
  margin: 0 -15px;
}
.home .slick-slider.bottom .slick-track {
  padding-top: 25px;
}
@media (max-width: 1799.98px) {
  .home .slick-slider.bottom .slick-track {
    padding-top: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .home .slick-slider.bottom .slick-track {
    padding-top: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .home .slick-slider.bottom .slick-track {
    padding-top: 0;
  }
}
@media (max-width: 575.98px) {
  .home .slick-slider.bottom .slick-track {
    padding-top: 0;
  }
}
.home.news {
  padding-bottom: 50px;
  padding-top: 100px;
}
@media (max-width: 1799.98px) {
  .home.news {
    padding-bottom: 2.7777777778vw;
    padding-top: 5.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .home.news {
    padding-bottom: 3.4722222222vw;
    padding-top: 6.9444444444vw;
  }
}
@media (max-width: 767.98px) {
  .home.news {
    padding-bottom: 0;
    padding-top: 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .home.news {
    padding-bottom: 0;
    padding-top: 13.3333333333vw;
  }
}
.home.news h2 {
  margin-bottom: 50px;
}
@media (max-width: 1799.98px) {
  .home.news h2 {
    margin-bottom: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .home.news h2 {
    margin-bottom: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .home.news h2 {
    margin-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .home.news h2 {
    margin-bottom: 6.6666666667vw;
  }
}

.standard-content .block {
  padding-bottom: 25px;
  padding-top: 25px;
}
@media (max-width: 1799.98px) {
  .standard-content .block {
    padding-bottom: 1.3888888889vw;
    padding-top: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .standard-content .block {
    padding-bottom: 1.7361111111vw;
    padding-top: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .standard-content .block {
    padding-bottom: 1.953125vw;
    padding-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .standard-content .block {
    padding-bottom: 4vw;
    padding-top: 4vw;
  }
}
.standard-content .block--inner {
  padding-bottom: 15px;
}
@media (max-width: 1799.98px) {
  .standard-content .block--inner {
    padding-bottom: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .standard-content .block--inner {
    padding-bottom: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .standard-content .block--inner {
    padding-bottom: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .standard-content .block--inner {
    padding-bottom: 4vw;
  }
}

.blogs {
  padding: 35px 0;
}
@media (max-width: 1799.98px) {
  .blogs {
    padding: 1.9444444444vw 0;
  }
}
@media (max-width: 991.98px) {
  .blogs {
    padding: 2.4305555556vw 0;
  }
}
@media (max-width: 767.98px) {
  .blogs {
    padding: 4.5572916667vw 0;
  }
}
@media (max-width: 575.98px) {
  .blogs {
    padding: 9.3333333333vw 0;
  }
}

.shop--body {
  position: relative;
  padding-bottom: 75px;
}
@media (max-width: 1799.98px) {
  .shop--body {
    padding-bottom: 4.1666666667vw;
  }
}
@media (max-width: 991.98px) {
  .shop--body {
    padding-bottom: 5.2083333333vw;
  }
}
@media (max-width: 767.98px) {
  .shop--body {
    padding-bottom: 4.5572916667vw;
  }
}
@media (max-width: 575.98px) {
  .shop--body {
    padding-bottom: 9.3333333333vw;
  }
}
.shop--details {
  margin-bottom: 25px;
}
@media (max-width: 1799.98px) {
  .shop--details {
    margin-bottom: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .shop--details {
    margin-bottom: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .shop--details {
    margin-bottom: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .shop--details {
    margin-bottom: 6.6666666667vw;
  }
}
.shop--details .select-box {
  text-align: right;
}
.shop--details select {
  background: unset url("/assets/images/icons/chevron-down-white.svg");
  border: 0;
  color: #333;
  width: auto;
  font-size: 14px;
  padding: 10px 45px 10px 15px;
  border-radius: 10px;
}
@media (max-width: 1799.98px) {
  .shop--details select {
    font-size: 0.7777777778vw;
    padding: 0.5555555556vw 2.5vw 0.5555555556vw 0.8333333333vw;
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .shop--details select {
    font-size: 0.9722222222vw;
    padding: 0.6944444444vw 3.125vw 0.6944444444vw 1.0416666667vw;
    border-radius: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .shop--details select {
    font-size: 1.8229166667vw;
    padding: 1.3020833333vw 5.859375vw 1.3020833333vw 1.953125vw;
    border-radius: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .shop--details select {
    font-size: 3.7333333333vw;
    padding: 2.6666666667vw 12vw 2.6666666667vw 4vw;
    border-radius: 2.6666666667vw;
  }
}
@media only screen and (width < 768px) {
  .shop--details select {
    background-image: url("/assets/images/icons/chevron-down-black.svg");
    color: #333;
  }
}
.shop--details span {
  color: #fff;
}
.shop--details button {
  padding: 5px 20px;
}
@media (max-width: 1799.98px) {
  .shop--details button {
    padding: 0.2777777778vw 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .shop--details button {
    padding: 0.3472222222vw 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .shop--details button {
    padding: 0.6510416667vw 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .shop--details button {
    padding: 1.3333333333vw 5.3333333333vw;
  }
}
.shop--filter-button-box {
  background: rgba(252, 252, 252, 0) linear-gradient(to bottom, rgba(252, 252, 252, 0) 0%, #fcfcfc 80%);
  bottom: 0;
  left: 0;
  width: 100%;
  position: fixed;
  text-align: center;
  z-index: 8;
  padding: 15px 0;
}
@media (max-width: 1799.98px) {
  .shop--filter-button-box {
    padding: 0.8333333333vw 0;
  }
}
@media (max-width: 991.98px) {
  .shop--filter-button-box {
    padding: 1.0416666667vw 0;
  }
}
@media (max-width: 767.98px) {
  .shop--filter-button-box {
    padding: 1.953125vw 0;
  }
}
@media (max-width: 575.98px) {
  .shop--filter-button-box {
    padding: 4vw 0;
  }
}
.shop--filter-button-box button {
  padding: 8px 35px;
}
@media (max-width: 1799.98px) {
  .shop--filter-button-box button {
    padding: 0.4444444444vw 1.9444444444vw;
  }
}
@media (max-width: 991.98px) {
  .shop--filter-button-box button {
    padding: 0.5555555556vw 2.4305555556vw;
  }
}
@media (max-width: 767.98px) {
  .shop--filter-button-box button {
    padding: 1.0416666667vw 4.5572916667vw;
  }
}
@media (max-width: 575.98px) {
  .shop--filter-button-box button {
    padding: 2.1333333333vw 9.3333333333vw;
  }
}
.shop button:not(.active-filter) {
  font-size: 14px;
  gap: 5px;
}
@media (max-width: 1799.98px) {
  .shop button:not(.active-filter) {
    font-size: 0.7777777778vw;
    gap: 0.2777777778vw;
  }
}
@media (max-width: 991.98px) {
  .shop button:not(.active-filter) {
    font-size: 0.9722222222vw;
    gap: 0.3472222222vw;
  }
}
@media (max-width: 767.98px) {
  .shop button:not(.active-filter) {
    font-size: 1.8229166667vw;
    gap: 0.6510416667vw;
  }
}
@media (max-width: 575.98px) {
  .shop button:not(.active-filter) {
    font-size: 3.7333333333vw;
    gap: 1.3333333333vw;
  }
}
.shop button:not(.active-filter) svg {
  transform: rotate(90deg);
  width: 20px;
}
@media (max-width: 1799.98px) {
  .shop button:not(.active-filter) svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .shop button:not(.active-filter) svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .shop button:not(.active-filter) svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .shop button:not(.active-filter) svg {
    width: 5.3333333333vw;
  }
}

.no-results {
  margin-top: 25px;
}
@media (max-width: 1799.98px) {
  .no-results {
    margin-top: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .no-results {
    margin-top: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .no-results {
    margin-top: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .no-results {
    margin-top: 6.6666666667vw;
  }
}
.no-results h3, .no-results p {
  color: #fff;
}

.store-alert {
  text-align: center;
  padding: 15px;
}
@media (max-width: 1799.98px) {
  .store-alert {
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .store-alert {
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .store-alert {
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .store-alert {
    padding: 4vw;
  }
}
.store-alert p {
  margin-bottom: 0;
  font-weight: 700;
}
.store-alert--success {
  background: #236c2a;
  color: #fff;
}
.store-alert--error {
  background: #ff4f4f;
  color: #fff;
}

#cart {
  background: #f4f4f4;
  min-height: 47.5vh;
  padding: 50px 0;
}
@media (max-width: 1799.98px) {
  #cart {
    padding: 2.7777777778vw 0;
  }
}
@media (max-width: 991.98px) {
  #cart {
    padding: 3.4722222222vw 0;
  }
}
@media (max-width: 767.98px) {
  #cart {
    padding: 6.5104166667vw 0;
  }
}
@media (max-width: 575.98px) {
  #cart {
    padding: 13.3333333333vw 0;
  }
}
#cart h1 {
  font-size: 30px;
  line-height: 38px;
}
@media (max-width: 1799.98px) {
  #cart h1 {
    font-size: 1.6666666667vw;
    line-height: 2.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  #cart h1 {
    font-size: 2.0833333333vw;
    line-height: 2.6388888889vw;
  }
}
@media (max-width: 767.98px) {
  #cart h1 {
    font-size: 3.90625vw;
    line-height: 4.9479166667vw;
  }
}
@media (max-width: 575.98px) {
  #cart h1 {
    font-size: 8vw;
    line-height: 10.1333333333vw;
  }
}
#cart .cart-items .cart-item {
  padding: 25px 0;
}
@media (max-width: 1799.98px) {
  #cart .cart-items .cart-item {
    padding: 1.3888888889vw 0;
  }
}
@media (max-width: 991.98px) {
  #cart .cart-items .cart-item {
    padding: 1.7361111111vw 0;
  }
}
@media (max-width: 767.98px) {
  #cart .cart-items .cart-item {
    padding: 3.2552083333vw 0;
  }
}
@media (max-width: 575.98px) {
  #cart .cart-items .cart-item {
    padding: 6.6666666667vw 0;
  }
}
#cart .cart-items .cart-item:not(:last-child) {
  border-bottom: 1px solid #f4f1ec;
}
#cart .cart-items .cart-item:first-child {
  padding-top: 0;
}

.cart-item {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 1799.98px) {
  .cart-item {
    gap: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item {
    gap: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item {
    gap: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item {
    gap: 5.3333333333vw;
  }
}
.cart-item .thumbnail {
  border: 1px solid #f4f1ec;
  object-fit: cover;
  border-radius: 10px;
  height: 150px;
  width: 150px;
}
@media (max-width: 1799.98px) {
  .cart-item .thumbnail {
    border-radius: 0.5555555556vw;
    height: 8.3333333333vw;
    width: 8.3333333333vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item .thumbnail {
    border-radius: 0.6944444444vw;
    height: 10.4166666667vw;
    width: 10.4166666667vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item .thumbnail {
    border-radius: 1.3020833333vw;
    height: 10.4166666667vw;
    width: 10.4166666667vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .thumbnail {
    border-radius: 2.6666666667vw;
    height: 21.3333333333vw;
    width: 21.3333333333vw;
  }
}
.cart-item h4 {
  font-size: 16px;
  line-height: 22px;
}
@media (max-width: 1799.98px) {
  .cart-item h4 {
    font-size: 0.8888888889vw;
    line-height: 1.2222222222vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item h4 {
    font-size: 1.1111111111vw;
    line-height: 1.5277777778vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item h4 {
    font-size: 1.8229166667vw;
    line-height: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item h4 {
    font-size: 3.7333333333vw;
    line-height: 5.3333333333vw;
  }
}
.cart-item .info {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.cart-item .actions {
  align-self: flex-end;
  display: flex;
  height: max-content;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
  margin-top: 0;
}
@media (max-width: 1799.98px) {
  .cart-item .actions {
    gap: 0.8333333333vw;
    margin-top: 0;
  }
}
@media (max-width: 991.98px) {
  .cart-item .actions {
    gap: 1.0416666667vw;
    margin-top: 0;
  }
}
@media (max-width: 767.98px) {
  .cart-item .actions {
    gap: 1.953125vw;
    margin-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .actions {
    gap: 4vw;
    margin-top: 4vw;
  }
}
.cart-item .actions p {
  align-self: flex-start;
  justify-self: flex-start;
}
.cart-item .prices {
  width: 375px;
}
@media (max-width: 1799.98px) {
  .cart-item .prices {
    width: 20.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item .prices {
    width: 26.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item .prices {
    width: 48.828125vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .prices {
    width: 100vw;
  }
}
.cart-item .prices p, .cart-item .prices span {
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 1799.98px) {
  .cart-item .prices p, .cart-item .prices span {
    font-size: 0.7777777778vw;
    line-height: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item .prices p, .cart-item .prices span {
    font-size: 0.9722222222vw;
    line-height: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item .prices p, .cart-item .prices span {
    font-size: 1.8229166667vw;
    line-height: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .prices p, .cart-item .prices span {
    font-size: 3.7333333333vw;
    line-height: 5.3333333333vw;
  }
}
.cart-item .prices p.line, .cart-item .prices span.line {
  color: #a8a8a4;
  text-decoration: line-through;
}
.cart-item .remove-btn {
  align-items: center;
  background: none;
  border: 1px solid #f4f1ec;
  display: flex;
  justify-content: center;
  transition: all 0.3s;
  outline: none;
  border-radius: 8px;
  height: 48px;
  width: 48px;
}
@media (max-width: 1799.98px) {
  .cart-item .remove-btn {
    border-radius: 0.4444444444vw;
    height: 2.6666666667vw;
    width: 2.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item .remove-btn {
    border-radius: 0.5555555556vw;
    height: 3.3333333333vw;
    width: 3.3333333333vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item .remove-btn {
    border-radius: 1.0416666667vw;
    height: 4.9479166667vw;
    width: 4.9479166667vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .remove-btn {
    border-radius: 2.1333333333vw;
    height: 10.1333333333vw;
    width: 10.1333333333vw;
  }
}
.cart-item .remove-btn:hover {
  background: #ff4f4f;
  border-color: #ff4f4f;
}
.cart-item .remove-btn:hover svg path {
  fill: #fff;
}
.cart-item .remove-btn * {
  transition: all 0.3s;
}
.cart-item .remove-btn svg {
  height: auto;
  width: 18px;
}
@media (max-width: 1799.98px) {
  .cart-item .remove-btn svg {
    width: 1vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item .remove-btn svg {
    width: 1.25vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item .remove-btn svg {
    width: 2.34375vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .remove-btn svg {
    width: 4.8vw;
  }
}
.cart-item .quantity-box {
  align-items: center;
  border: 1px solid #f4f1ec;
  display: flex;
  overflow: hidden;
  width: max-content;
  border-radius: 8px;
}
@media (max-width: 1799.98px) {
  .cart-item .quantity-box {
    border-radius: 0.4444444444vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item .quantity-box {
    border-radius: 0.5555555556vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item .quantity-box {
    border-radius: 1.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .quantity-box {
    border-radius: 2.1333333333vw;
  }
}
.cart-item .quantity-box label {
  margin: 0;
}
.cart-item .quantity-box input {
  appearance: none;
  border: 0;
  font-weight: 600;
  outline: none;
  text-align: center;
  padding: 4px 15px;
  width: 50px;
}
@media (max-width: 1799.98px) {
  .cart-item .quantity-box input {
    padding: 0.2222222222vw 0.8333333333vw;
    width: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item .quantity-box input {
    padding: 0.2777777778vw 1.0416666667vw;
    width: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item .quantity-box input {
    padding: 0.5208333333vw 1.953125vw;
    width: 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .quantity-box input {
    padding: 1.0666666667vw 4vw;
    width: 13.3333333333vw;
  }
}
.cart-item .quantity-box input::-webkit-outer-spin-button,
.cart-item .quantity-box input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart-item .quantity-box input[type=number] {
  -moz-appearance: textfield;
}
.cart-item .quantity-box button {
  background: none;
  border: 0;
  font-weight: 600;
  outline: none;
  transition: all 0.3s;
  padding: 5px 15px;
}
@media (max-width: 1799.98px) {
  .cart-item .quantity-box button {
    padding: 0.2777777778vw 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .cart-item .quantity-box button {
    padding: 0.3472222222vw 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .cart-item .quantity-box button {
    padding: 0.6510416667vw 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .cart-item .quantity-box button {
    padding: 1.3333333333vw 4vw;
  }
}
.cart-item .content {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 170px);
}
@media (max-width: 1799.98px) {
  .cart-item .content {
    width: calc(100% - 9.4444444444vw);
  }
}
@media (max-width: 991.98px) {
  .cart-item .content {
    width: calc(100% - 11.8055555556vw);
  }
}
@media (max-width: 767.98px) {
  .cart-item .content {
    width: calc(100% - 13.671875vw);
  }
}
@media (max-width: 575.98px) {
  .cart-item .content {
    width: calc(100% - 28vw);
  }
}
.cart-item .content h4 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

#checkout {
  min-height: 45.5vh;
  padding: 50px 0 75px 0;
}
@media (max-width: 1799.98px) {
  #checkout {
    padding: 2.7777777778vw 0 4.1666666667vw 0;
  }
}
@media (max-width: 991.98px) {
  #checkout {
    padding: 3.4722222222vw 0 5.2083333333vw 0;
  }
}
@media (max-width: 767.98px) {
  #checkout {
    padding: 6.5104166667vw 0 9.765625vw 0;
  }
}
@media (max-width: 575.98px) {
  #checkout {
    padding: 13.3333333333vw 0 20vw 0;
  }
}
#checkout form:not(#paymentForm) input[type=text], #checkout form:not(#paymentForm) select {
  width: 100%;
  border: 1px solid #e5e5e4;
  color: rgba(51, 51, 51, 0.8);
  border-radius: 5px;
  padding: 15px 10px;
}
@media (max-width: 1799.98px) {
  #checkout form:not(#paymentForm) input[type=text], #checkout form:not(#paymentForm) select {
    border-radius: 0.2777777778vw;
    padding: 0.8333333333vw 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  #checkout form:not(#paymentForm) input[type=text], #checkout form:not(#paymentForm) select {
    border-radius: 0.3472222222vw;
    padding: 1.0416666667vw 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  #checkout form:not(#paymentForm) input[type=text], #checkout form:not(#paymentForm) select {
    border-radius: 0.6510416667vw;
    padding: 1.953125vw 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  #checkout form:not(#paymentForm) input[type=text], #checkout form:not(#paymentForm) select {
    border-radius: 1.3333333333vw;
    padding: 4vw 2.6666666667vw;
  }
}
#checkout form:not(#paymentForm) select {
  background: #fff;
  appearance: none;
}
#checkout--header {
  padding: 25px 0;
}
@media (max-width: 1799.98px) {
  #checkout--header {
    padding: 1.3888888889vw 0;
  }
}
@media (max-width: 991.98px) {
  #checkout--header {
    padding: 1.7361111111vw 0;
  }
}
@media (max-width: 767.98px) {
  #checkout--header {
    padding: 3.2552083333vw 0;
  }
}
@media (max-width: 575.98px) {
  #checkout--header {
    padding: 6.6666666667vw 0;
  }
}
#checkout--header .core-logo {
  height: auto;
  width: 200px;
}
@media (max-width: 1799.98px) {
  #checkout--header .core-logo {
    width: 11.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  #checkout--header .core-logo {
    width: 13.8888888889vw;
  }
}
@media (max-width: 767.98px) {
  #checkout--header .core-logo {
    width: 26.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  #checkout--header .core-logo {
    width: 53.3333333333vw;
  }
}

.stepper {
  padding-top: 50px;
}
@media (max-width: 1799.98px) {
  .stepper {
    padding-top: 2.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .stepper {
    padding-top: 3.4722222222vw;
  }
}
@media (max-width: 767.98px) {
  .stepper {
    padding-top: 6.5104166667vw;
  }
}
@media (max-width: 575.98px) {
  .stepper {
    padding-top: 13.3333333333vw;
  }
}
.stepper nav {
  display: flex;
  justify-content: space-between;
}
.stepper nav a, .stepper nav span {
  position: relative;
}
.stepper nav a .check, .stepper nav span .check {
  position: absolute;
  background: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  width: 25px;
  height: 25px;
  top: calc(100% + 8px);
}
@media (max-width: 1799.98px) {
  .stepper nav a .check, .stepper nav span .check {
    width: 1.3888888889vw;
    height: 1.3888888889vw;
    top: calc(100% + 0.4444444444vw);
  }
}
@media (max-width: 991.98px) {
  .stepper nav a .check, .stepper nav span .check {
    width: 1.7361111111vw;
    height: 1.7361111111vw;
    top: calc(100% + 0.5555555556vw);
  }
}
@media (max-width: 767.98px) {
  .stepper nav a .check, .stepper nav span .check {
    width: 2.6041666667vw;
    height: 2.6041666667vw;
    top: calc(100% + 1.6927083333vw);
  }
}
@media (max-width: 575.98px) {
  .stepper nav a .check, .stepper nav span .check {
    width: 5.3333333333vw;
    height: 5.3333333333vw;
    top: calc(100% + 3.4666666667vw);
  }
}
.stepper nav a .check svg, .stepper nav span .check svg {
  height: auto;
  width: 15px;
}
@media (max-width: 1799.98px) {
  .stepper nav a .check svg, .stepper nav span .check svg {
    width: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .stepper nav a .check svg, .stepper nav span .check svg {
    width: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .stepper nav a .check svg, .stepper nav span .check svg {
    width: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .stepper nav a .check svg, .stepper nav span .check svg {
    width: 4vw;
  }
}
.stepper nav span:first-child a .check, .stepper nav span:first-child span .check {
  left: 0;
  transform: unset;
}
.stepper nav span:last-child a .check, .stepper nav span:last-child span .check {
  left: unset;
  right: -8px;
}
@media (max-width: 1799.98px) {
  .stepper nav span:last-child a .check, .stepper nav span:last-child span .check {
    right: -0.4444444444vw;
  }
}
@media (max-width: 991.98px) {
  .stepper nav span:last-child a .check, .stepper nav span:last-child span .check {
    right: -0.5555555556vw;
  }
}
@media (max-width: 767.98px) {
  .stepper nav span:last-child a .check, .stepper nav span:last-child span .check {
    right: -1.0416666667vw;
  }
}
@media (max-width: 575.98px) {
  .stepper nav span:last-child a .check, .stepper nav span:last-child span .check {
    right: -2.1333333333vw;
  }
}
.stepper nav a, .stepper nav span {
  font-weight: 600;
}
.stepper nav a.active, .stepper nav span.active {
  color: var(--secondary);
}
.stepper .progress-bar {
  position: relative;
  margin-top: 15px;
}
@media (max-width: 1799.98px) {
  .stepper .progress-bar {
    margin-top: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .stepper .progress-bar {
    margin-top: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .stepper .progress-bar {
    margin-top: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .stepper .progress-bar {
    margin-top: 4vw;
  }
}
.stepper .progress-bar .bar {
  width: 100%;
  border-radius: 10px;
  height: 10px;
}
@media (max-width: 1799.98px) {
  .stepper .progress-bar .bar {
    border-radius: 0.5555555556vw;
    height: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .stepper .progress-bar .bar {
    border-radius: 0.6944444444vw;
    height: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .stepper .progress-bar .bar {
    border-radius: 1.3020833333vw;
    height: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .stepper .progress-bar .bar {
    border-radius: 2.6666666667vw;
    height: 2.6666666667vw;
  }
}
.stepper .progress-bar .bar--static {
  background: #e5e5e4;
}
.stepper .progress-bar .bar--progress {
  background: var(--primary);
  position: absolute;
  top: 0;
  transition: all 0.8s;
  left: 0;
}
.stepper .progress-bar .bar--progress .car {
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  margin-top: -1px;
  right: -65px;
  width: 80px;
}
@media (max-width: 1799.98px) {
  .stepper .progress-bar .bar--progress .car {
    margin-top: -0.0555555556vw;
    right: -3.6111111111vw;
    width: 4.4444444444vw;
  }
}
@media (max-width: 991.98px) {
  .stepper .progress-bar .bar--progress .car {
    margin-top: -0.0694444444vw;
    right: -4.5138888889vw;
    width: 5.5555555556vw;
  }
}
@media (max-width: 767.98px) {
  .stepper .progress-bar .bar--progress .car {
    margin-top: -0.1302083333vw;
    right: -5.859375vw;
    width: 7.8125vw;
  }
}
@media (max-width: 575.98px) {
  .stepper .progress-bar .bar--progress .car {
    margin-top: -0.2666666667vw;
    right: -12vw;
    width: 16vw;
  }
}
.stepper .progress-bar .bar--progress .car path {
  fill: var(--secondary);
}

.option--box {
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}
.option--box label {
  border: 2px solid #f4f1ec;
  display: flex;
  height: 100%;
  margin: 0;
  transition: all 0.3s;
  box-shadow: 0 1px 2px 0 rgba(103, 122, 135, 0.16);
  border-radius: 10px;
  gap: 15px;
  padding: 15px;
}
@media (max-width: 1799.98px) {
  .option--box label {
    border-radius: 0.5555555556vw;
    gap: 0.8333333333vw;
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .option--box label {
    border-radius: 0.6944444444vw;
    gap: 1.0416666667vw;
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .option--box label {
    border-radius: 1.3020833333vw;
    gap: 1.953125vw;
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .option--box label {
    border-radius: 2.6666666667vw;
    gap: 4vw;
    padding: 4vw;
  }
}
.option--box label:hover {
  background: rgba(244, 241, 236, 0.1);
  border-color: var(--primary);
}
.option--box .info {
  font-size: 16px;
  width: calc(100% - 55px);
}
@media (max-width: 1799.98px) {
  .option--box .info {
    font-size: 0.8888888889vw;
    width: calc(100% - 3.0555555556vw);
  }
}
@media (max-width: 991.98px) {
  .option--box .info {
    font-size: 1.1111111111vw;
    width: calc(100% - 3.8194444444vw);
  }
}
@media (max-width: 767.98px) {
  .option--box .info {
    font-size: 2.0833333333vw;
    width: calc(100% - 7.1614583333vw);
  }
}
@media (max-width: 575.98px) {
  .option--box .info {
    font-size: 4.2666666667vw;
    width: calc(100% - 14.6666666667vw);
  }
}
.option--box .checkbox {
  align-items: center;
  background: #f4f1ec;
  border: 1px solid #e5e5e4;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}
@media (max-width: 1799.98px) {
  .option--box .checkbox {
    height: 1.3888888889vw;
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .option--box .checkbox {
    height: 1.7361111111vw;
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .option--box .checkbox {
    height: 3.2552083333vw;
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .option--box .checkbox {
    height: 6.6666666667vw;
    width: 6.6666666667vw;
  }
}
.option--box .checkbox svg {
  transition: all 0.3s;
  height: auto;
  width: 0;
}
.option--box .checkbox svg path {
  fill: #fff;
}
.option--box input {
  display: none;
}
.option--box input:checked ~ label {
  background: rgba(var(--primary), 0.1);
  border-color: var(--primary);
}
.option--box input:checked ~ label .title {
  font-weight: 700;
}
.option--box input:checked ~ label .checkbox {
  background: var(--primary);
  border-color: var(--primary);
}
.option--box input:checked ~ label .checkbox svg {
  width: 13px;
}
@media (max-width: 1799.98px) {
  .option--box input:checked ~ label .checkbox svg {
    width: 0.7222222222vw;
  }
}
@media (max-width: 991.98px) {
  .option--box input:checked ~ label .checkbox svg {
    width: 0.9027777778vw;
  }
}
@media (max-width: 767.98px) {
  .option--box input:checked ~ label .checkbox svg {
    width: 1.6927083333vw;
  }
}
@media (max-width: 575.98px) {
  .option--box input:checked ~ label .checkbox svg {
    width: 3.4666666667vw;
  }
}
@media only screen and (width < 600px) {
  .option--box select {
    max-width: 250px;
  }
}
.option--box .edit-address {
  color: var(--secondary);
  position: absolute;
  bottom: 10px;
  right: 20px;
}
@media (max-width: 1799.98px) {
  .option--box .edit-address {
    bottom: 0.5555555556vw;
    right: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .option--box .edit-address {
    bottom: 0.6944444444vw;
    right: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .option--box .edit-address {
    bottom: 1.3020833333vw;
    right: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .option--box .edit-address {
    bottom: 2.6666666667vw;
    right: 5.3333333333vw;
  }
}
.option--box.shipping label {
  padding: 15px;
}
@media (max-width: 1799.98px) {
  .option--box.shipping label {
    padding: 0.8333333333vw;
  }
}
@media (max-width: 991.98px) {
  .option--box.shipping label {
    padding: 1.0416666667vw;
  }
}
@media (max-width: 767.98px) {
  .option--box.shipping label {
    padding: 1.953125vw;
  }
}
@media (max-width: 575.98px) {
  .option--box.shipping label {
    padding: 4vw;
  }
}
.option--box.payment select {
  background-color: #fff;
  border: 1px solid #f4f1ec;
  background-image: url("/assets/images/icons/chevron-down.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center right 15px;
  border-radius: 10px;
  padding: 10px 15px;
  width: 300px;
  appearance: none;
}
@media (max-width: 1799.98px) {
  .option--box.payment select {
    background-size: 0.8333333333vw;
    background-position: center right 0.8333333333vw;
    border-radius: 0.5555555556vw;
    padding: 0.5555555556vw 0.8333333333vw;
    width: 16.6666666667vw;
    appearance: none;
  }
}
@media (max-width: 991.98px) {
  .option--box.payment select {
    background-size: 1.0416666667vw;
    background-position: center right 1.0416666667vw;
    border-radius: 0.6944444444vw;
    padding: 0.6944444444vw 1.0416666667vw;
    width: 20.8333333333vw;
    appearance: none;
  }
}
@media (max-width: 767.98px) {
  .option--box.payment select {
    background-size: 1.953125vw;
    background-position: center right 1.953125vw;
    border-radius: 1.3020833333vw;
    padding: 1.3020833333vw 1.953125vw;
    width: 39.0625vw;
    appearance: none;
  }
}
@media (max-width: 575.98px) {
  .option--box.payment select {
    background-size: 4vw;
    background-position: center right 4vw;
    border-radius: 2.6666666667vw;
    padding: 2.6666666667vw 4vw;
    width: 80vw;
    appearance: none;
  }
}
.option--box.payment .logo svg {
  height: auto;
  width: 30px;
}
@media (max-width: 1799.98px) {
  .option--box.payment .logo svg {
    width: 1.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  .option--box.payment .logo svg {
    width: 2.0833333333vw;
  }
}
@media (max-width: 767.98px) {
  .option--box.payment .logo svg {
    width: 3.90625vw;
  }
}
@media (max-width: 575.98px) {
  .option--box.payment .logo svg {
    width: 8vw;
  }
}

.addresses .addresses--option .option--box p {
  margin: 0;
}
.addresses .addresses--option a.option--box {
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  border: 2px solid #f4f1ec;
  transition: all 0.3s;
  border-radius: 10px;
  padding: 15px;
  font-size: 18px;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .addresses .addresses--option a.option--box {
    border-radius: 0.5555555556vw;
    padding: 0.8333333333vw;
    font-size: 1vw;
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .addresses .addresses--option a.option--box {
    border-radius: 0.6944444444vw;
    padding: 1.0416666667vw;
    font-size: 1.25vw;
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .addresses .addresses--option a.option--box {
    border-radius: 1.3020833333vw;
    padding: 1.953125vw;
    font-size: 2.34375vw;
    gap: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .addresses .addresses--option a.option--box {
    border-radius: 2.6666666667vw;
    padding: 4vw;
    font-size: 4.8vw;
    gap: 2.6666666667vw;
  }
}
.addresses .addresses--option a.option--box span {
  text-align: center;
}
.addresses .addresses--option a.option--box svg {
  height: auto;
  width: 20px;
}
@media (max-width: 1799.98px) {
  .addresses .addresses--option a.option--box svg {
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .addresses .addresses--option a.option--box svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .addresses .addresses--option a.option--box svg {
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .addresses .addresses--option a.option--box svg {
    width: 5.3333333333vw;
  }
}
.addresses .addresses--option a.option--box svg path {
  fill: var(--primary);
}
.addresses .addresses--option a.option--box:hover {
  border-color: var(--primary);
}

.checkboxes {
  margin-top: 25px;
}
@media (max-width: 1799.98px) {
  .checkboxes {
    margin-top: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .checkboxes {
    margin-top: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .checkboxes {
    margin-top: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .checkboxes {
    margin-top: 6.6666666667vw;
  }
}
.checkboxes .checkbox-box label {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1799.98px) {
  .checkboxes .checkbox-box label {
    gap: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .checkboxes .checkbox-box label {
    gap: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .checkboxes .checkbox-box label {
    gap: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .checkboxes .checkbox-box label {
    gap: 2.6666666667vw;
  }
}
.checkboxes .checkbox-box label .checkbox {
  align-items: center;
  background: #f4f1ec;
  border: 1px solid #e5e5e4;
  display: inline-flex;
  justify-content: center;
  border-radius: 5px;
  height: 20px;
  width: 20px;
}
@media (max-width: 1799.98px) {
  .checkboxes .checkbox-box label .checkbox {
    border-radius: 0.2777777778vw;
    height: 1.1111111111vw;
    width: 1.1111111111vw;
  }
}
@media (max-width: 991.98px) {
  .checkboxes .checkbox-box label .checkbox {
    border-radius: 0.3472222222vw;
    height: 1.3888888889vw;
    width: 1.3888888889vw;
  }
}
@media (max-width: 767.98px) {
  .checkboxes .checkbox-box label .checkbox {
    border-radius: 0.6510416667vw;
    height: 2.6041666667vw;
    width: 2.6041666667vw;
  }
}
@media (max-width: 575.98px) {
  .checkboxes .checkbox-box label .checkbox {
    border-radius: 1.3333333333vw;
    height: 5.3333333333vw;
    width: 5.3333333333vw;
  }
}
.checkboxes .checkbox-box label .checkbox svg {
  transition: all 0.3s;
  height: auto;
  width: 0;
}
.checkboxes .checkbox-box label .checkbox svg path {
  fill: #fff;
}
.checkboxes .checkbox-box input {
  display: none;
}
.checkboxes .checkbox-box input:checked ~ label .checkbox {
  background: var(--primary);
  border-color: var(--primary);
}
.checkboxes .checkbox-box input:checked ~ label .checkbox svg {
  width: 10px;
}
@media (max-width: 1799.98px) {
  .checkboxes .checkbox-box input:checked ~ label .checkbox svg {
    width: 0.5555555556vw;
  }
}
@media (max-width: 991.98px) {
  .checkboxes .checkbox-box input:checked ~ label .checkbox svg {
    width: 0.6944444444vw;
  }
}
@media (max-width: 767.98px) {
  .checkboxes .checkbox-box input:checked ~ label .checkbox svg {
    width: 1.3020833333vw;
  }
}
@media (max-width: 575.98px) {
  .checkboxes .checkbox-box input:checked ~ label .checkbox svg {
    width: 2.6666666667vw;
  }
}

.item-name {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}
.item-name .gift {
  background: var(--secondary);
  color: #fff;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  border-radius: 50px;
  gap: 5px;
  padding: 3px 10px;
  font-size: 14px;
}
@media (max-width: 1799.98px) {
  .item-name .gift {
    border-radius: 2.7777777778vw;
    gap: 0.2777777778vw;
    padding: 0.1666666667vw 0.5555555556vw;
    font-size: 0.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .item-name .gift {
    border-radius: 3.4722222222vw;
    gap: 0.3472222222vw;
    padding: 0.2083333333vw 0.6944444444vw;
    font-size: 0.9722222222vw;
  }
}
@media (max-width: 767.98px) {
  .item-name .gift {
    border-radius: 6.5104166667vw;
    gap: 0.6510416667vw;
    padding: 0.390625vw 1.3020833333vw;
    font-size: 1.8229166667vw;
  }
}
@media (max-width: 575.98px) {
  .item-name .gift {
    border-radius: 13.3333333333vw;
    gap: 1.3333333333vw;
    padding: 0.8vw 2.6666666667vw;
    font-size: 3.7333333333vw;
  }
}
.item-name .gift svg {
  height: auto;
  width: 18px;
}
@media (max-width: 1799.98px) {
  .item-name .gift svg {
    width: 1vw;
  }
}
@media (max-width: 991.98px) {
  .item-name .gift svg {
    width: 1.25vw;
  }
}
@media (max-width: 767.98px) {
  .item-name .gift svg {
    width: 2.34375vw;
  }
}
@media (max-width: 575.98px) {
  .item-name .gift svg {
    width: 4.8vw;
  }
}
.item-name .gift svg path {
  fill: #fff;
}

.sale-price {
  display: block;
  text-decoration: line-through;
  font-size: 14px;
  line-height: 18px;
}
@media (max-width: 1799.98px) {
  .sale-price {
    font-size: 0.7777777778vw;
    line-height: 1vw;
  }
}
@media (max-width: 991.98px) {
  .sale-price {
    font-size: 0.9722222222vw;
    line-height: 1.25vw;
  }
}
@media (max-width: 767.98px) {
  .sale-price {
    font-size: 1.8229166667vw;
    line-height: 2.34375vw;
  }
}
@media (max-width: 575.98px) {
  .sale-price {
    font-size: 3.7333333333vw;
    line-height: 4.8vw;
  }
}

form.coupon.remove {
  display: block;
}
form.coupon.remove button {
  background: none;
  border: 0;
  color: #ff4f4f;
  display: block;
  padding: 0;
  font-size: 12px;
}
@media (max-width: 1799.98px) {
  form.coupon.remove button {
    font-size: 0.6666666667vw;
  }
}
@media (max-width: 991.98px) {
  form.coupon.remove button {
    font-size: 0.8333333333vw;
  }
}
@media (max-width: 767.98px) {
  form.coupon.remove button {
    font-size: 1.5625vw;
  }
}
@media (max-width: 575.98px) {
  form.coupon.remove button {
    font-size: 3.2vw;
  }
}
form.coupon.remove button:hover {
  text-decoration: underline;
}

.discount-button {
  padding: 0;
  border: 0;
  background: none;
  color: var(--primary);
  font-weight: 700;
  outline: none;
  font-size: 14px;
}
@media (max-width: 1799.98px) {
  .discount-button {
    font-size: 0.7777777778vw;
  }
}
@media (max-width: 991.98px) {
  .discount-button {
    font-size: 0.9722222222vw;
  }
}
@media (max-width: 767.98px) {
  .discount-button {
    font-size: 1.8229166667vw;
  }
}
@media (max-width: 575.98px) {
  .discount-button {
    font-size: 3.7333333333vw;
  }
}
.discount-button:hover {
  text-decoration: underline;
}
.discount-button svg {
  height: auto;
  width: 25px;
}
@media (max-width: 1799.98px) {
  .discount-button svg {
    width: 1.3888888889vw;
  }
}
@media (max-width: 991.98px) {
  .discount-button svg {
    width: 1.7361111111vw;
  }
}
@media (max-width: 767.98px) {
  .discount-button svg {
    width: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .discount-button svg {
    width: 6.6666666667vw;
  }
}
.discount-button svg path {
  fill: var(--primary);
}

#paymentForm button[type=submit] {
  padding: 15px 45px;
}
@media (max-width: 1799.98px) {
  #paymentForm button[type=submit] {
    padding: 0.8333333333vw 2.5vw;
  }
}
@media (max-width: 991.98px) {
  #paymentForm button[type=submit] {
    padding: 1.0416666667vw 3.125vw;
  }
}
@media (max-width: 767.98px) {
  #paymentForm button[type=submit] {
    padding: 1.953125vw 5.859375vw;
  }
}
@media (max-width: 575.98px) {
  #paymentForm button[type=submit] {
    padding: 4vw 12vw;
  }
}

.error {
  padding: 150px 0;
}
@media (max-width: 1799.98px) {
  .error {
    padding: 8.3333333333vw 0;
  }
}
@media (max-width: 991.98px) {
  .error {
    padding: 10.4166666667vw 0;
  }
}
@media (max-width: 767.98px) {
  .error {
    padding: 13.0208333333vw 0;
  }
}
@media (max-width: 575.98px) {
  .error {
    padding: 26.6666666667vw 0;
  }
}
.error h1 {
  color: black;
  font-size: 56px;
  line-height: 64px;
  margin-top: -45px;
}
@media (max-width: 1799.98px) {
  .error h1 {
    font-size: 3.1111111111vw;
    line-height: 3.5555555556vw;
    margin-top: -2.5vw;
  }
}
@media (max-width: 991.98px) {
  .error h1 {
    font-size: 3.8888888889vw;
    line-height: 4.4444444444vw;
    margin-top: -3.125vw;
  }
}
@media (max-width: 767.98px) {
  .error h1 {
    font-size: 3.3854166667vw;
    line-height: 4.4270833333vw;
    margin-top: -3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .error h1 {
    font-size: 6.9333333333vw;
    line-height: 9.0666666667vw;
    margin-top: -6.6666666667vw;
  }
}
.error .buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 55px;
}
@media (max-width: 1799.98px) {
  .error .buttons {
    gap: 0.8333333333vw;
    margin-top: 3.0555555556vw;
  }
}
@media (max-width: 991.98px) {
  .error .buttons {
    gap: 1.0416666667vw;
    margin-top: 3.8194444444vw;
  }
}
@media (max-width: 767.98px) {
  .error .buttons {
    gap: 1.953125vw;
    margin-top: 3.2552083333vw;
  }
}
@media (max-width: 575.98px) {
  .error .buttons {
    gap: 4vw;
    margin-top: 6.6666666667vw;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.ts-control {
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 8px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.ts-wrapper.multi.has-items .ts-control {
  padding: 6px 8px 3px;
}

.full .ts-control {
  background-color: #fff;
}

.disabled .ts-control, .disabled .ts-control * {
  cursor: default !important;
}

.focus .ts-control {
  box-shadow: none;
}

.ts-control > * {
  display: inline-block;
  vertical-align: baseline;
}

.ts-wrapper.multi .ts-control > div {
  background: #f2f2f2;
  border: 0 solid #d0d0d0;
  color: #303030;
  cursor: pointer;
  margin: 0 3px 3px 0;
  padding: 2px 6px;
}

.ts-wrapper.multi .ts-control > div.active {
  background: #e8e8e8;
  border: 0 solid #cacaca;
  color: #303030;
}

.ts-wrapper.multi.disabled .ts-control > div, .ts-wrapper.multi.disabled .ts-control > div.active {
  background: #fff;
  border: 0 solid #fff;
  color: #7d7d7d;
}

.ts-control > input {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
  display: inline-block !important;
  flex: 1 1 auto;
  line-height: inherit !important;
  margin: 0 !important;
  max-height: none !important;
  max-width: 100% !important;
  min-height: 0 !important;
  min-width: 7rem;
  padding: 0 !important;
  text-indent: 0 !important;
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.ts-control > input::-ms-clear {
  display: none;
}

.ts-control > input:focus {
  outline: none !important;
}

.has-items .ts-control > input {
  margin: 0 4px !important;
}

.ts-control.rtl {
  text-align: right;
}

.ts-control.rtl.single .ts-control:after {
  left: 15px;
  right: auto;
}

.ts-control.rtl .ts-control > input {
  margin: 0 4px 0 -2px !important;
}

.disabled .ts-control {
  background-color: #fafafa;
  opacity: 0.5;
}

.input-hidden .ts-control > input {
  left: -10000px;
  opacity: 0;
  position: absolute;
}

.ts-dropdown {
  background: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 0 0 3px 3px;
  border-top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  left: 0;
  margin: 0.25rem 0 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
}

.ts-dropdown [data-selectable] {
  cursor: pointer;
  overflow: hidden;
}

.ts-dropdown [data-selectable] .highlight {
  background: rgba(125, 168, 208, 0.2);
  border-radius: 1px;
}

.ts-dropdown .create, .ts-dropdown .no-results, .ts-dropdown .optgroup-header, .ts-dropdown .option {
  padding: 5px 8px;
}

.ts-dropdown .option, .ts-dropdown [data-disabled], .ts-dropdown [data-disabled] [data-selectable].option {
  cursor: inherit;
  opacity: 0.5;
}

.ts-dropdown [data-selectable].option {
  cursor: pointer;
  opacity: 1;
}

.ts-dropdown .optgroup:first-child .optgroup-header {
  border-top: 0;
}

.ts-dropdown .optgroup-header {
  background: #fff;
  color: #303030;
  cursor: default;
}

.ts-dropdown .active {
  background-color: #f5fafd;
  color: #495c68;
}

.ts-dropdown .active.create {
  color: #495c68;
}

.ts-dropdown .create {
  color: rgba(48, 48, 48, 0.5);
}

.ts-dropdown .spinner {
  display: inline-block;
  height: 30px;
  margin: 5px 8px;
  width: 30px;
}

.ts-dropdown .spinner:after {
  animation: lds-dual-ring 1.2s linear infinite;
  border-color: #d0d0d0 transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  content: " ";
  display: block;
  height: 24px;
  margin: 3px;
  width: 24px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.ts-dropdown-content {
  max-height: 200px;
  overflow: hidden auto;
  scroll-behavior: smooth;
}

.ts-wrapper.plugin-drag_drop .ts-dragging {
  color: transparent !important;
}

.ts-wrapper.plugin-drag_drop .ts-dragging > * {
  visibility: hidden !important;
}

.plugin-checkbox_options:not(.rtl) .option input {
  margin-right: 0.5rem;
}

.plugin-checkbox_options.rtl .option input {
  margin-left: 0.5rem;
}

.plugin-clear_button {
  --ts-pr-clear-button:1em;
}

.plugin-clear_button .clear-button {
  background: transparent !important;
  cursor: pointer;
  margin-right: 0 !important;
  opacity: 0;
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s;
}

.plugin-clear_button.form-select .clear-button, .plugin-clear_button.single .clear-button {
  right: max(var(--ts-pr-caret), 8px);
}

.plugin-clear_button.focus.has-items .clear-button, .plugin-clear_button:not(.disabled):hover.has-items .clear-button {
  opacity: 1;
}

.ts-wrapper .dropdown-header {
  background: color-mix(#fff, #d0d0d0, 85%);
  border-bottom: 1px solid #d0d0d0;
  border-radius: 3px 3px 0 0;
  padding: 10px 8px;
  position: relative;
}

.ts-wrapper .dropdown-header-close {
  color: #303030;
  font-size: 20px !important;
  line-height: 20px;
  margin-top: -12px;
  opacity: 0.4;
  position: absolute;
  right: 8px;
  top: 50%;
}

.ts-wrapper .dropdown-header-close:hover {
  color: #000;
}

.plugin-dropdown_input.focus.dropdown-active .ts-control {
  border: 1px solid #d0d0d0;
  box-shadow: none;
}

.plugin-dropdown_input .dropdown-input {
  background: transparent;
  border: solid #d0d0d0;
  border-width: 0 0 1px;
  box-shadow: none;
  display: block;
  padding: 8px;
  width: 100%;
}

.plugin-dropdown_input .items-placeholder {
  border: 0 !important;
  box-shadow: none !important;
  width: 100%;
}

.plugin-dropdown_input.dropdown-active .items-placeholder, .plugin-dropdown_input.has-items .items-placeholder {
  display: none !important;
}

.ts-wrapper.plugin-input_autogrow.has-items .ts-control > input {
  min-width: 0;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input {
  flex: none;
  min-width: 4px;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::-ms-input-placeholder {
  color: transparent;
}

.ts-wrapper.plugin-input_autogrow.has-items.focus .ts-control > input::placeholder {
  color: transparent;
}

.ts-dropdown.plugin-optgroup_columns .ts-dropdown-content {
  display: flex;
}

.ts-dropdown.plugin-optgroup_columns .optgroup {
  border-right: 1px solid #f2f2f2;
  border-top: 0;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
}

.ts-dropdown.plugin-optgroup_columns .optgroup:last-child {
  border-right: 0;
}

.ts-dropdown.plugin-optgroup_columns .optgroup:before {
  display: none;
}

.ts-dropdown.plugin-optgroup_columns .optgroup-header {
  border-top: 0;
}

.ts-wrapper.plugin-remove_button .item {
  align-items: center;
  display: inline-flex;
}

.ts-wrapper.plugin-remove_button .item .remove {
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
  color: inherit;
  display: inline-block;
  padding: 0 6px;
  text-decoration: none;
  vertical-align: middle;
}

.ts-wrapper.plugin-remove_button .item .remove:hover {
  background: rgba(0, 0, 0, 0.05);
}

.ts-wrapper.plugin-remove_button.disabled .item .remove:hover {
  background: none;
}

.ts-wrapper.plugin-remove_button .remove-single {
  font-size: 23px;
  position: absolute;
  right: 0;
  top: 0;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item {
  padding-right: 0 !important;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item .remove {
  border-left: 1px solid #d0d0d0;
  margin-left: 6px;
}

.ts-wrapper.plugin-remove_button:not(.rtl) .item.active .remove {
  border-left-color: #cacaca;
}

.ts-wrapper.plugin-remove_button:not(.rtl).disabled .item .remove {
  border-left-color: #fff;
}

.ts-wrapper.plugin-remove_button.rtl .item {
  padding-left: 0 !important;
}

.ts-wrapper.plugin-remove_button.rtl .item .remove {
  border-right: 1px solid #d0d0d0;
  margin-right: 6px;
}

.ts-wrapper.plugin-remove_button.rtl .item.active .remove {
  border-right-color: #cacaca;
}

.ts-wrapper.plugin-remove_button.rtl.disabled .item .remove {
  border-right-color: #fff;
}

:root {
  --ts-pr-clear-button:0;
  --ts-pr-caret:0;
  --ts-pr-min:.75rem;
}

.ts-wrapper.single .ts-control, .ts-wrapper.single .ts-control input {
  cursor: pointer;
}

.ts-control:not(.rtl) {
  padding-right: max(var(--ts-pr-min), var(--ts-pr-clear-button) + var(--ts-pr-caret)) !important;
}

.ts-control.rtl {
  padding-left: max(var(--ts-pr-min), var(--ts-pr-clear-button) + var(--ts-pr-caret)) !important;
}

.ts-wrapper {
  position: relative;
}

.ts-control, .ts-control input, .ts-dropdown {
  color: #303030;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
}

.ts-control, .ts-wrapper.single.input-active .ts-control {
  background: #fff;
  cursor: text;
}

.ts-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}


p a {
  color: var(--primary);
  text-decoration: underline;
}