@use "sass:math";

@mixin ellipsis-multi-line($lines) {
  @supports (-webkit-line-clamp: 2) {
    display: block;
    display: -webkit-box;
    display: -moz-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: $lines;
    line-clamp: $lines;
    -o-ellipsis-lastline: true;
  }
}

$ratioGroups: (
        "xs": 375, // (max-width: 499px)
        "sm": 375, //500, // (min-width: 500px) and (max-width: 767px)
        "md": 768, //900, // (min-width: 500px) and (max-width: 767px)
        "base": 1440, //1534,//1440, //21, // 1920
        "lg": 1440, //1534,//1440, ///1618.88, // (min-width: 1921px) and (max-width: 2399px)
        "xl": 1800, // (min-width: 2400px)
        "xxl": 1440, // (min-width: 2400px)
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1800px,
        xxl: 1441px
);


$container-max-widths: (
        sm: 576px,
        md: 720px,
        lg: 960px,
        xl: 1240px,
        xxl: 1320px
);

@function ratio-vw($value, $group: 'lg') {
  $base: map_get($ratioGroups, 'base');
  $groupVal: map_get($ratioGroups, $group);

  @if ($value == 0) {
    @return 0;
  }

  @return math.div($value / $groupVal, 0.01px) * 1vw;
}

@function ratio-vw-new($value, $valueMobile: '', $group: '') {
  @if ($group == '') {
    $group: $theGroup;
  }

  $base: map_get($ratioGroups, 'base');
  $groupVal: map_get($ratioGroups, $group);

  @if ($group == 'xxl') {
    @return $value;
  }

  @if ($valueMobile != '' and $group != 'xl' and $group != 'xxl' and $group != 'lg') {
    $value: $valueMobile;
  }

  @if ($value == 0) {
    @return 0;
  }

  @if ($value == '100vw') {
    @return 100vw;
  }

  @if ($value == '100%') {
    @return 100%;
  }

  @if (type-of($value) == 'string') {
    @return $value;
  }

  @return math.div($value / $groupVal, 0.01px) * 1vw;
}


@function vw($value, $valueMobile: '', $group: '') {
  @if ($group == '') {
    $group: $theGroup;
  }

  $base: map_get($ratioGroups, 'base');
  $groupVal: map_get($ratioGroups, $group);

  @if ($group == 'xxl') {
    @return $value;
  }

  @if ($valueMobile != '' and $group != 'xl' and $group != 'xxl' and $group != 'lg' ) { //} and $group != 'md') {
    $value: $valueMobile;
  }

  @if ($value == 0) {
    @return 0;
  }

  @if (type-of($value) == 'string') {
    @return $value;
  }

  @if (unit($value) != 'px') {
    @return $value;
  }

  @return math.div($value / $groupVal, 0.01px) * 1vw;
}


@function ratio-vw-calc($calc, $value, $group: 'lg') {
  @return calc(#{$calc} #{ratio-vw($value, $group)});
}

@mixin media-breakpoint-lg() {
  @media (max-width: 1439px) and (min-width: 992px) { //1919px
    @content;
  }
}

@mixin media-breakpoint-lg-small() {
  @media (max-width: 1050px) and (min-width: 992px) {
    @content;
  }
}

$mediaGroups: (
        "xxl": 0,
        "xl": 0,
        "lg": 0,
        "md": 0,
        "sm": 0,
  //"xs": 0,
);

@mixin media-breakpoints {
  @each $group, $value in $mediaGroups {
    $theGroup: $group !global;

    @if ($group == 'xxl') {
      @content;
    }

    @if ($group != 'xxl') {
      @include media-breakpoint-down($group) {
        @content;
      }
    }
  }
}

$mediaGroupsDesktop: (
        "lg": 0,
        "xl": 0,
        "xxl": 0,
);

@mixin media-breakpoints-desktop {
  @each $group, $value in $mediaGroupsDesktop {
    $theGroup: $group !global;

    @include media-breakpoint-up($group) {
      @content;
    }
  }
}

$mediaGroupsMobile: (
        "md": 0,
        "sm": 0,
  //"xs": 0,
);

@mixin media-breakpoints-mobile {
  @each $group, $value in $mediaGroupsMobile {
    $theGroup: $group !global;

    @if ($group == 'xl' or $group == 'xxl') {
      @content;
    }

    @if ($group != 'xl' and $group != 'xxl') {
      @include media-breakpoint-down($group) {
        @content;
      }
    }
  }
}


@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .ratio-content, > iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
