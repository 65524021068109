form {
  .password-meter {
    input:not([type="checkbox"]) {
      background-image: unset;
    }

    .password {
      position: relative;

      span {
        background: $gray;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoints {
          font-size: vw(14px);
          width: vw(30px);
          height: vw(30px);
          right: vw(15px);
        }
      }
    }
  }
}

.criteria-list {

}

.input-container {
  margin-bottom: 10px;
}

.strength-container {
  background: $white;
  border: 1px solid $darkgray;
  border-radius: $br;

  @include media-breakpoints {
    padding: vw(15px) vw(25px);
  }
}

.meter {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0;

  > div {
    &.good {
      background-color: green;
    }

    &.almost {
      background-color: orange;
    }
  }
}

.meter div {
  height: 100%;
  transition: width 0.3s;
}

.weak {
  background-color: $red;
}

.moderate {

}

.strong {
  background-color: #8ab951;
}


.criteria-list {
  list-style-type: none;
  padding: 0;
  columns: 2;

  @media only screen and (width < 768px) {
    columns: 1;
  }

  li {
    color: $red;

    &.check {
      color: green;

    }

    @include media-breakpoints {
      font-size: vw(14px, 10px);
      line-height: vw(20px, 16px);
      margin-bottom: vw(5px);
    }
  }
}
