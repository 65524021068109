#cart {
  background: #f4f4f4;
  min-height: 47.5vh;

  @include media-breakpoints {
    padding: vw(50px) 0;
  }

  h1 {
    @include media-breakpoints {
      font-size: vw(30px);
      line-height: vw(38px);
    }
  }


  .cart-items {
    .cart-item {
      @include media-breakpoints {
        padding: vw(25px) 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $gray;
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }
}


.cart-item {
  display: flex;
  flex-wrap: wrap;

  @include media-breakpoints {
    gap: vw(20px);
  }

  .thumbnail {
    border: 1px solid $gray;
    object-fit: cover;

    @include media-breakpoints {
      border-radius: vw($br);
      height: vw(150px, 80px);
      width: vw(150px, 80px);
    }
  }

  h4 {
    @include media-breakpoints {
      font-size: vw(16px, 14px);
      line-height: vw(22px, 20px);
    }
  }

  .info {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  .actions {
    align-self: flex-end;
    display: flex;
    height: max-content;
    justify-content: flex-end;
    width: 100%;

    @include media-breakpoints {
      gap: vw(15px);
      margin-top: vw(0, 15px);
    }

    p {
      align-self: flex-start;
      justify-self: flex-start;
    }
  }

  .prices {
    @include media-breakpoints {
      width: vw(375px);
    }

    p, span {
      @include media-breakpoints {
        font-size: vw(14px);
        line-height: vw(20px);
      }

      &.line {
        color: #a8a8a4;
        text-decoration: line-through;
      }
    }
  }

  .remove-btn {
    align-items: center;
    background: none;
    border: 1px solid $gray;
    display: flex;
    justify-content: center;
    transition: all .3s;
    outline: none;

    @include media-breakpoints {
      border-radius: vw(8px);
      height: vw(48px, 38px);
      width: vw(48px, 38px);
    }

    &:hover {
      background: $red;
      border-color: $red;

      svg {
        path {
          fill: $white;
        }
      }
    }

    * {
      transition: all .3s;
    }

    svg {
      height: auto;

      @include media-breakpoints {
        width: vw(18px);
      }
    }
  }

  .quantity-box {
    align-items: center;
    border: 1px solid $gray;
    display: flex;
    overflow: hidden;
    width: max-content;

    @include media-breakpoints {
      border-radius: vw(8px);
    }


    label {
      margin: 0;
    }

    input {
      appearance: none;
      border: 0;
      font-weight: 600;
      outline: none;
      text-align: center;

      @include media-breakpoints {
        padding: vw(4px) vw(15px);
        width: vw(50px);
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    button {
      background: none;
      border: 0;
      font-weight: 600;
      outline: none;
      transition: all .3s;

      @include media-breakpoints {
        padding: vw(5px) vw(15px);
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoints {
      width: calc(100% - #{vw(170px, 105px)});
    }

    h4 {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
