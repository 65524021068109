.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $white;
  width: 100%;
  box-shadow: 0 1px 2px 0 $boxshadowcolor;
  border: 1px solid $gray;
  margin-top: 100px;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  z-index: 22;

  @include media-breakpoints {
    border-radius: vw($br);
    max-width: vw(450px, 95vw);
  }

  &.show {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }

  &--header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray;

    @include media-breakpoints {
      padding: vw(15px) vw(25px);
    }

    h3 {
      margin: 0;
    }

    button {
      background: none;
      border: 0;
      outline: none;
    }
  }

  &--body {
    @include media-breakpoints {
      padding: vw(25px);
    }

    form {

      label {
        font-weight: 700;
        display: block;

        @include media-breakpoints {
          margin-bottom: vw(10px);
        }
      }

      input {
        border: 1px solid $gray;
        width: 100%;

        @include media-breakpoints {
          padding: vw(15px) vw(10px);
          border-radius: vw($br);
        }
      }

      button {
        display: block;
        width: 100%;
        transition: all .3s;

        @include media-breakpoints {
          margin-top: vw(10px);
          padding: vw(15px);
        }

        &.added {
          background: $instock;
        }
      }

      .error {
        color: $red;
        margin: 2px 0 0 0;
      }
    }
  }
}
