@media only screen and (width > 600px) {
  .backdrop {
    background: rgba(0, 0, 0, .5);
    //filter: blur(4px);
    height: 100dvh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: all .3s;
    visibility: hidden;
    width: 100%;
    z-index: 20;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.cart-open {
  //> div:not(#mini-cart, .side-mini-cart, .backdrop), header, footer, main {
  //  filter: blur(4px);
  //  transition: all .3s;
  //}
}


.side-mini-cart {
  background: $white;
  height: 100%;
  max-height: 100dvh;
  //opacity: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(150%);
  transition: transform .5s cubic-bezier(.19, 1, .22, 1);
  //visibility: hidden;
  z-index: 24;
  display: flex;
  flex-direction: column;

  @include media-breakpoints {
    width: vw(650px, 100%);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid $gray;
      transition: all .3s;

      &.remove {
        transform: translateX(150%);
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &--header {
    align-items: center;
    align-self: flex-start;
    background: $white;
    border-bottom: 1px solid $gray;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 2;

    @include media-breakpoints {
      padding: vw(20px, 15px) vw(25px, 15px);
    }

    .title {
      margin: 0;

      @include media-breakpoints {
        font-size: vw(28px, 24px);
        line-height: vw(36px, 34px);
      }
    }

    button {
      background: none;
      border: 0;
      outline: none;
    }
  }

  &--content {
    position: relative;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;

    .gift {
      background: $secondary-color;
      color: $white;
      font-weight: 700;
      display: inline-flex;
      align-items: center;


      @include media-breakpoints {
        border-radius: vw(50px);
        gap: vw(8px);
        margin-top: vw(5px);
        padding: vw(8px) vw(15px);
      }

      svg {
        height: auto;
        @include media-breakpoints {
          width: vw(20px);
        }

        path {
          fill: $white;
        }
      }
    }
  }

  &--body {
    flex: 1 1 auto;
    position: relative;

    li {
      @include media-breakpoints {
        padding: vw(25px, 15px);

      }
    }
  }

  &--footer {
    align-self: flex-end;
    background: $white;
    border-top: 1px solid $gray;
    bottom: 0;
    flex: 0 0 auto;
    position: sticky;
    width: 100%;

    @include media-breakpoints {
      padding: vw(25px, 15px);
    }

    p {
      @include media-breakpoints {
        font-size: vw(24px, 20px);
        line-height: vw(34px, 30px);
      }

      &.small {
        margin-bottom: 5px;

        @include media-breakpoints {
          font-size: vw(18px, 14px);
          line-height: vw(28px, 24px);
        }

      }
    }
  }

  &--empty {
    @include media-breakpoints {
      padding: vw(25px);
    }

    svg {
      height: auto;

      @include media-breakpoints {
        width: vw(50px);
        margin-bottom: vw(5px);
      }

      path {
        fill: #a8a8a4;
      }
    }

    h2 {
      font-weight: 400;
      @include media-breakpoints {
        font-size: vw(28px);
        line-height: vw(36px);
        margin-bottom: vw(25px);
      }
    }

    &.fade-in {
      animation: fadeInAnimation ease .3s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}

#mini-cart {
  &.open {
    .side-mini-cart {

      transform: translateX(0);
    }
  }
}


@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
