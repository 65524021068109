.shop-filters {
  //position: sticky;
  //
  //@include media-breakpoints {
  //  top: vw(125px);
  //}

  &--header {
    position: sticky;
    top: 0;
    z-index: 10;
    align-items: center;
    background: $secondary-color;
    border-bottom: 1px solid $gray;
    display: flex;
    justify-content: space-between;

    @include media-breakpoints {
     padding: vw(15px);
    }

    @media only screen and (width > 768px) {
      display: none;
    }

    h2, button {
      color: $white;
    }

    h2 {
      margin: 0;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoints {
       font-size: vw(14px);
        gap: vw(5px);
      }

      svg {
        height: auto;

        @include media-breakpoints {
         width: vw(25px);
        }

        path {
          fill: $white;
        }
      }
    }
  }

  .car-box {
    background: $white;

    @media only screen and (width > 768px) {
      border: 1px solid $gray;
      box-shadow: $boxshadowcolor;
    }

    @include media-breakpoints {
      border-radius: vw($br, 0);
      margin-bottom: vw(15px, 0);
      padding: 0 vw(25px, 15px) vw(20px, 0);
    }

    .filter-box {
      border: 0;
      box-shadow: unset;
      margin-bottom: 0;
      padding: 0;

      @include media-breakpoints {
        margin-top: vw(20px, 0);
      }
    }
  }

  .filter-box {
    background: $white;
    border: 1px solid $gray;
    cursor: pointer;
    transition: all .3s;

    @include media-breakpoints {
      border-radius: vw($br, 0);
      margin-bottom: vw(15px, 0);
      padding: vw(20px, 0) vw(25px, 15px);
    }

    @media only screen and (width > 768px) {
      box-shadow: $boxshadowcolor;
    }

    @media only screen and (width < 768px) {
      border: 0;
    }

    &--head {
      align-items: center;
      display: flex;
      justify-content: space-between;

      @include media-breakpoints {
        padding: vw(10px) 0 vw(0, 10px) 0;
      }

      span {
        font-weight: 700;

        @include media-breakpoints {
          font-size: vw(18px);

        }
      }

      svg {
        transition: all .3s;
      }
    }

    &--body {
      border-top: 1px solid $gray;
      height: 100%;

      @include media-breakpoints {
        padding: vw(15px) 0 vw(0, 15px);
      }
    }



    .ts-wrapper.single.dropdown-active .ts-control {
      @include media-breakpoints {
        border-radius: vw(25px) vw(25px) 0 0;
      }
    }

    .ts-wrapper, .ts-control, .ts-dropdown {
      .item, .option {
        @include media-breakpoints {
         font-size: vw(16px);
        }
      }

    }

    .ts-control {
      padding: 0;
      background-color: $gray !important;
      border: 0;
      background-image: url('/assets/images/icons/chevron-down.svg')!important;
      background-repeat: no-repeat!important;

      @include media-breakpoints {
        border-radius: vw(50px);
        background-position: center right vw(15px)!important;
        background-size: vw(20px) !important;
        padding: vw(15px) vw(45px) vw(15px) vw(20px)!important;
      }

      input {
        color: $black;
        background: $gray !important;
      }
    }

    .ts-dropdown {
      margin: 0;
    }

    .select-box {
      position: relative;

      select, input {
        color: $black;

        @include media-breakpoints {
          font-size: vw(14px);
        }
      }

      select {
        appearance: none;
        background: $gray;
        border: 0;
        width: 100%;

        @include media-breakpoints {
          border-radius: vw(50px);
          padding: vw(15px) vw(30px) vw(15px) vw(20px);
        }
      }
    }

    .checkbox-body {


      .filter-box__toggle {
        transition: all .3s;
        button {
          border: 0;
          background: unset;
          padding: 0;
          color: $secondary-color;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: auto;
            transform: rotate(0deg);

            @include media-breakpoints {
             width: vw(15px);
            }

            path {
              fill: $secondary-color;
            }
          }
        }

        .less {
          display: none;
        }

        &.active {
          @include media-breakpoints {
           padding-bottom: vw(10px);
          }

          .more {
            display: none;
          }

          .less {
            display: flex;
          }
        }
      }
    }




    &.select {
      label {
        display: none;
      }
    }

    &.close {
      .filter-box--head {
        transition: all .3s ease-in-out .3s;

        @include media-breakpoints {
         padding-bottom: vw(0, 15px);
        }
      }


    }
  }

  .floating-bar {
    background: $white;
    bottom: 0;
    display: none;
    left: 0;
    position: fixed;
    text-align: center;
    width: 100%;

    @include media-breakpoints {
     padding: vw(15px);
    }

    button {
      justify-content: center;
      width: 100%;

      span {
        line-height: unset;
      }
    }
  }

  @media only screen and (width < 768px) {
    background: $white;
    height: 100%;
    left: 0;
    max-height: calc(100dvh);
    position: fixed;
    top: 0;
    transform: translate3d(0,100%,0);
    transition: transform .3s ease-in-out;
    width: 100vw;
    z-index: 24;

    &.show {
      transform: translateZ(0);
    }

    &--body {
      height: 100%;
      overflow: auto;

      @include media-breakpoints {
        max-height: calc(100dvh - #{vw(50px)} - #{vw(81px)});
      }
    }


    .floating-bar {
      display: block;
    }
  }

}

.active-filters {
  //border-bottom: 1px solid $gray;
  //border-top: 1px solid $gray;
  display: none;

  @include media-breakpoints {
    margin-bottom: vw(15px);
    padding-bottom: vw(15px);
  }

  &--box {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoints {
      gap: vw(10px);
    }
  }

  button {
    align-items: center;
    background: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;
    display: flex;
    justify-content: center;
    outline: none;

    @include media-breakpoints {
      border-radius: vw($br);
      font-size: vw(12px);
      gap: vw(10px);
      line-height: vw(18px);
      padding: vw(5px) vw(10px);
    }

    svg {
      height: auto;
      transform: rotate(0);

      @include media-breakpoints {
        width: vw(16px);
      }

      path {
        fill: $white;
      }
    }
  }

  #remove-all-filters {
    background: transparent;
    border: 0;
    color: $secondary-color;
    margin-top: 15px;
    padding: 0;

    &:hover {
      color: $outofstock;
    }
  }

  &.show {
    display: block;
  }
}

#loader {
  left: 0;
  position: absolute;
  top: 100%;
  transition: all .3s;
  width: 100%;
  z-index: 3;

  &.hide {
    opacity: 0;
  }

  .progress {
    background: $primary-color;
    position: relative;
    transition: all 1s;
    width: 0;

    @include media-breakpoints {
      height: vw(5px);
    }
  }

  svg {
    position: absolute;
    left: 0;
    height: auto;
    margin-bottom: 10px;
    transition: all 1s;

    @include media-breakpoints {
      bottom: vw(-12px);
      width: vw(100px);
    }
  }

  &.animate {
    .progress {
      width: 100%;
    }

    svg {
      left: 100%;
    }
  }
}
