.content.faq {
  @include media-breakpoints {
    padding-bottom: vw(75px);
  }

  .intro {
    p {
      margin: 0;
    }
  }
}

.accordion-list {
  list-style: none;
  padding: 0;

  @include media-breakpoints {
    margin: 0 0 vw(15px) 0;
  }
}

#search-input {
  background: $gray;
  border: 1px solid $darkgray;
  outline: none;
  width: 100%;

  @include media-breakpoints {
    border-radius: vw($br);
    font-size: vw(18px, 16px);
    padding: vw(15px, 10px) vw(15px);
  }
}

.accordion {
  background: $gray;
  cursor: pointer;

  &:hover {
    background: rgba($gray, .8);
  }

  @include media-breakpoints {
    border-radius: vw($br);
    margin-bottom: vw(10px);
    padding: vw(15px);
  }

  &__header {
    align-items: center;
    display: flex;
    font-weight: 600;
    justify-content: space-between;

    @include media-breakpoints {
      font-size: vw(20px, 16px);
      line-height: vw(28px, 24px);
    }

    svg {
      height: auto;
      transition: all .3s;

      @include media-breakpoints {
        width: vw(25px);
      }
    }
  }

  &__body {
    @include media-breakpoints {
      margin-top: vw(15px);
    }
  }

  &.active {
    .accordion__header {
      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.accordion-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include media-breakpoints {
    gap: vw(10px, 5px);
    margin: vw(25px, 15px) 0 vw(35px, 25px) 0;
  }

  button {
    background: transparent;
    border: 2px solid $secondary-color;
    color: $secondary-color;
    transition: all .3s;
    font-weight: 600;

    @include media-breakpoints {
      font-size: vw(18px, 14px);
      border-radius: vw($br);
      padding: vw(10px, 5px) vw(15px);
    }

    &.active, &:hover {
      background: $secondary-color;
      color: $white;
    }
  }
}
