.alert-bar {
  background: $primary-color;
  color: $white;
  position: relative;
  z-index: 4;

  @include media-breakpoints {
    padding: vw(10px) 0;
  }

  p {
    margin: 0;

    @include media-breakpoints {
      font-size: vw(15px, 10px);
      line-height: vw(24px, 19px);
    }
  }

  &--header {
    @media only screen and (width < 768px) {
      display: none;
    }
  }

  &--footer {
    background: $secondary-color;
    position: relative;
    z-index: 12;
  }
}
