.select-box {
  label {
    display: block;
    font-weight: 600;

    @include media-breakpoints {
      font-size: vw(20px);
      line-height: vw(28px);
    }
  }

  select {
    appearance: none;
    background-color: $gray;
    background-image: url('/assets/images/icons/chevron-down.svg');
    background-repeat: no-repeat;
    border: 1px solid $darkgray;
    width: 100%;


    @include media-breakpoints {
      background-position: center right vw(15px);
      background-size: vw(25px);
      border-radius: vw($br);
      font-size: vw(17px);
      line-height: vw(25px);
      padding: vw(10px) vw(35px) vw(10px) vw(15px);
    }

    &:disabled {
      background-image: url('/assets/images/icons/chevron-down-disabled.svg');
    }
  }
}

.variations {
  .select-box {
    @include media-breakpoints {
      margin-bottom: vw(15px);
    }
  }
}


form.account {
  label {


    small {
      color: #a8a8a8;
    }
  }

  input:not([type="checkbox"], [type="password"]), select {
    background: $white;
    border: 1px solid $darkgray;
    width: 100%;

    @include media-breakpoints {
      padding: vw(10px) vw(15px);
      border-radius: vw($br);
    }

    ~ label {
      display: block;
    }
  }

  input {
    &:disabled, &:read-only {
      background: $gray;
      color: rgba($black, .8);
      cursor: not-allowed;
      outline: none;
    }
  }

  select {
    appearance: none;
    background-image: url('/assets/images/icons/chevron-down.svg');
    background-repeat: no-repeat;

    @include media-breakpoints {
      background-position: center right vw(15px);
      background-size: vw(25px);
    }
  }

  .option--box {
    label {
      display: flex !important;
    }
  }


  .address--box {
    background: $secondary-color;
    color: $white;
    font-weight: 700;

    @include media-breakpoints {
      padding: vw(15px);
      border-radius: vw($br);
      margin: vw(10px) 0 vw(15px) 0;
    }

    &.error {
      background: $gray;
      color: $black;
    }
  }
}

.required {
  color: $red;
}


.search-address-field--box {
  position: relative;

  input {
    outline: none;
  }

  .autocomplete-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2;
    box-shadow: 0 .5em .75em rgba(0, 0, 0, .15);
    background-color: #fff;
    display: none;
    overflow: hidden;
    height: auto;

    @include media-breakpoints {
      max-height: vw(500px);
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .autocomplete-item {
      padding: .4em 1.2em .4em .6em;
      margin: 0;
      line-height: 1.1;
      list-style: none;
      cursor: pointer;

      mark {
        background: transparent;
        color: $black;
        font-weight: 700;
      }

      &__label {
        display: inline-block;
        margin-right: .4em;
      }

      &--more {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0ibTUgMTQgNi02LTYtNiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNzc3IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgo8L3N2Zz4K);
        background-position: center right .25em;
        background-repeat: no-repeat;
        background-size: .8em;
      }

      &--no-results {
        cursor: default;
      }

      &--focus, &:hover {
        background-color: #f0f0f0;
      }
    }
  }

  &.active {
    .autocomplete-results {
      display: block;
    }

    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.checkbox-box {
  input {
    display: none;

    &:checked {
      + label {
        .checkbox {
          background: $secondary-color;
          border-color: $secondary-color;

          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
  }

  label {
    width: 100%;
    position: relative;
    margin-top: 0;
    display: flex;
    align-items: center;

    @include media-breakpoints {
      gap: vw(8px);

    }

    .checkbox {
      background: $gray;
      border: 2px solid #e8e8e8;
      transition: all .3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoints {
        border-radius: vw(5px);
        height: vw(20px);
        width: vw(20px);
        margin-top: vw(-2px);
      }

      svg {
        height: auto;

        @include media-breakpoints {
          width: vw(10px);
        }

        path {
          fill: $gray;
        }
      }
    }
  }
}

.password-toggle {
  position: relative;

  input {
    background: $white;
    border: 1px solid $darkgray;
    width: 100%;

    @include media-breakpoints {
      border-radius: vw($br);
      padding: vw(10px) vw(45px) vw(10px) vw(15px);
    }

    ~ label {
      display: block;
    }
  }

  button {
    all: unset;
    align-items: center;
    background: $gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include media-breakpoints {
      height: vw(30px);
      width: vw(30px);
      right: vw(10px);
    }

    svg {
      @include media-breakpoints {
        width: vw(20px);
      }
    }
  }
}

.form {
  label, input, select {
    font-family: $primary-font;
  }

  input, textarea {
    @include media-breakpoints {
     border-radius: vw($br);
      padding-top: vw(10px);
      padding-bottom: vw(10px);
    }
  }

  .fui-submit {
    background: $primary-color;
    border-color: $primary-color;

    @include media-breakpoints {
      border-radius: vw($br);
      font-size: vw(16px, 14px);
      line-height: vw(22px, 20px);
      padding: vw(10px) vw(35px);

    }
  }

  .fui-checkbox {
    input {
      &:checked + label::before {
        background-color: $primary-color;
        border-color: $primary-color;
      }
    }
  }

  .two-columns {
    .fui-layout-wrap {
      display: flex;
      flex-wrap: wrap;

      .fui-checkbox {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
  }
}
