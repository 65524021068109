.error {
  @include media-breakpoints {
    padding: vw(150px, 100px) 0;
  }

  h1 {
    color: black;
    @include media-breakpoints {
      font-size: vw(56px, 26px);
      line-height: vw(64px, 34px);
      margin-top: vw(-45px, -25px);
    }
  }

  .buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;


    @include media-breakpoints {
      gap: vw(15px);
      margin-top: vw(55px, 25px);
    }
  }
}
