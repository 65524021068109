@import './typography/families';

body {
  font-family: $primary-font;

  @include media-breakpoints {
    font-size: vw(16px, 14px);
    line-height: vw(24px, 22px);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

p, li, a, span {
  @include media-breakpoints {
    font-size: vw(16px, 14px);
    line-height: vw(24px);
  }


  &.subtitle {
    font-weight: 300;

    @include media-breakpoints {
      font-size: vw(26px);
      line-height: vw(39px);
    }
  }
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
}

h1, .h1 {
  @include media-breakpoints {
    font-size: vw(42px, 22px);
    line-height: vw(48px, 28px);
  }
}

h2, .h2, h2 p {
  @include media-breakpoints {
    font-size: vw(36px, 20px);
    line-height: vw(42px, 26px);
  }
}

h3, .h3, h3 p {
  @include media-breakpoints {
    font-size: vw(22px, 20px);
    line-height: vw(30px, 28px);
  }
}

h4, .h4, h4 p {
  @include media-breakpoints {
    font-size: vw(22px, 18px);
    line-height: vw(28px, 24px);
  }
}
