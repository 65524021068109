.blog {
  $height: 225px;
  $width: 300px;

  &--item {
    .content {
      background: $white;
      border: 1px solid $gray;
      box-shadow: $boxshadowcolor;
      display: flex;

      @include media-breakpoints {
        border-radius: vw($br);
        gap: vw(25px);
        padding: vw(10px) vw(10px) vw(10px, 35px) vw(10px);
      }

      @media only screen and (width < 768px) {
        display: block;
      }
    }

    .image-box {
      @include media-breakpoints {
        height: vw($height);
        width: vw($width, 100%);
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;

        @include media-breakpoints {
         border-radius: vw($br);
        }
      }
    }

    .information {
      @include media-breakpoints {
       padding: vw(25px) vw(35px, 15px) vw(15px) vw(0, 15px);
      }

      p {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .btn {
        float: right;
      }
    }
  }
}
