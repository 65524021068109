.header-slider {
  .slide {
    position: relative;

    @include media-breakpoints {
      border-radius: vw(25px, 0);
      height: vw(400px, 275px);
      overflow: hidden;
    }



    &::before {
      background: linear-gradient(180deg, rgba($real-black, 0) 0%, rgba($real-black, .20) 100%);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    img {
      object-fit: cover;
      transition: all .3s;
      width: 100%;

      @include media-breakpoints {
        height: vw(400px, 275px);
      }
    }

    .block {
      align-items: center;
      background: rgba($real-black, .34);
      bottom: 0;
      color: $white;
      display: flex;
      justify-content: space-between;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 3;

      @include media-breakpoints {
        gap: vw(35px, 25px);
        padding: vw(25px, 15px);
      }
    }

    .title p {
      @include media-breakpoints {
        font-size: vw(28px, 18px);
        line-height: vw(36px, 26px);
        margin-bottom: vw(10px, 5px);
      }
    }

    .subtitle {
      font-weight: 300;
      margin: 0;

      @include media-breakpoints {
        font-size: vw(20px, 14px);
        line-height: vw(24px, 20px);
      }
    }

    .icon {
      @include media-breakpoints {
        height: vw(48px, 38px);
        width: vw(48px, 38px);
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.logo-slider {
  position: relative;

  @include media-breakpoints {
    padding-bottom: vw(25px);
    padding-top: vw(25px);
  }

  .slick-slide {
    a {
      display: flex;
      justify-content: center;
    }

    img {
      filter: grayscale(100%);
      object-fit: contain;
      opacity: .6;
      transition: all .2s ease-in-out;

      @include media-breakpoints {
        height: vw(160px, 80px);
        width: vw(200px, 100px);
      }

      &:hover {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }

  .slick-arrow {
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    display: flex !important;
    justify-content: center;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s ease-in-out;
    z-index: 2;

    @include media-breakpoints {
      height: vw(40px);
      width: vw(40px);
    }

    svg {
      height: auto;
      @include media-breakpoints {
        width: vw(25px);
      }
    }

    &.prev {
      @include media-breakpoints {
        left: vw(-15px, 0);
      }
    }

    &.next {
      @include media-breakpoints {
        right: vw(-15px, 0);
      }
    }

    &:hover {
      background: $secondary-color;

      svg {
        path {
          fill: $white;
        }
      }
    }
  }

  svg {
    path {
      fill: $black;
      transition: all .3s;
    }
  }
}


.product-slider {
  position: relative;

  .slick-list {
    width: 100%;

    @media only screen and (width < 768px) {
      padding: 0 35% !important;


      @include media-breakpoints {
        margin: 0 vw(-5px);
      }
    }

    @media only screen and (width < 600px) {
      padding: 0 25% !important;
    }

    .slick-slide {
      @media only screen and (width < 768px) {
        margin: 0;
        opacity: .5;

        &.slick-active {
          opacity: 1;

          > div {
            &::before {
              display: none;
            }
          }
        }
      }

      .col-12 {
        @include media-breakpoints {
          padding: 0 vw(10px);
        }
      }

      @media only screen and (width < 768px) {
        > div {
          position: relative;

          &::before {
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
          }
        }
      }
    }
  }

  .slick-arrow {
    align-items: center;
    background: $white;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    filter: drop-shadow(0 2px 3px rgba(103, 122, 135, .16));
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s;
    z-index: 2;

    @include media-breakpoints {
      height: vw(48px, 38px);
      width: vw(48px, 38px);
    }

    &:hover {
      background: $secondary-color;

      svg {
        path {
          fill: $white;
        }
      }
    }

    svg {
      height: auto;

      @include media-breakpoints {
        width: vw(24px, 18px);
      }
    }

    &.prev {
      @include media-breakpoints {
        left: vw(-65px, -10px);
      }
    }

    &.next {
      @include media-breakpoints {
        right: vw(-65px, -10px);
      }
    }
  }
}
