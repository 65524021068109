.alert-box {
  &--body {
    display: flex;

    @include media-breakpoints {
      border-radius: vw($br);
      gap: vw(25px);
      padding: vw(20px);
      margin-top: vw(15px);
    }
  }

  &.error {

    .alert-box--body {
      background: $red;
      color: $white;
    }


    svg {
      path {
        fill: $white;
      }
    }
  }
}
